import React from "react";
import "./style.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import howWorkmobile from "./../../../../assets/images/howWorkmobile.png";
import PlaceHolderImage from "./../../../../assets/images/howWorkmobile-1.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function WorkingiHunt() {
    return (
        <section className="working-iHunt-section">
            <div className="inner-container">
                <div className="row">
                    <div className="col-12">
                        <h3 className="d-flex justify-content-center pb-2 text-white">How Does iHunt Work?</h3>
                    </div>
                    <div className="col-xxl-8 col-lg-11 col-md-12 mx-auto">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-8 col-10 pb-md-0 pb-4 mx-auto">
                                <div className="left">
                                    <div className="left-inner">
                                        <LazyLoadImage
                                            src={howWorkmobile}
                                            alt=""
                                            className="lazy-load-image"
                                            placeholderSrc={PlaceHolderImage}
                                            visibleByDefault={howWorkmobile}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="right">
                                    <div className="right-inner">
                                        <p>
                                            One of iHunt’s many key features is the ability for landowners to draw the boundary where the hunter is allowed to hunt. Just because you own the property, does not give the hunter the right to all of it. You set the property access boundary they are allowed to hunt, and hunters can see their location on your property, live, at all times. No confusion or misunderstandings, just simple property access!
                                        </p>
                                        <p>
                                            During our step-by-step property setup, the landowner will use the tracking feature in our app to set up a route for the hunter. Drive right to a parking spot you designate for the hunter. No more hunters parking at random spots on your farm. Just clear, precise parking instructions. No phone calls needed to interrupt your day!
                                        </p>
                                        <Link className="btn btn-primary text-white text-uppercase" to="/contact">contact us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
