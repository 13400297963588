import React from "react";
import { Modal } from "react-bootstrap";


export function CancelationPolicyModal({
    show,
    onHide,
    title = "Cancelation Policy",
    cancelationPolicy
}) {
    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                backdrop={cancelationPolicy && cancelationPolicy === "Your account is temporarily disabled by iHunt!" ? "static" : true}
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pd-4" style={{
                    textAlign: "justify"
                }}>
                    <span>{cancelationPolicy}</span>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={() => onHide(false)}
                        className="btn btn-primary text-white"
                    >
                        CLOSE
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
