import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { clearFundNotifications } from "modules/dashboard/_redux/reservations/reservationActions";


export default function PaidStatusModal({ show, setShow }) {
    const dispatch = useDispatch();

    const {
        fundNotification,
        user
    } = useSelector(
        (state) => ({
            fundNotification: state.reservation.fundNotification,
            user: state.auth.user
        }),
        shallowEqual
    );

    const calculateReceivedAmount = () => {
        return (fundNotification?.requested_amount - fundNotification?.transaction_fee_percentage / 100 * fundNotification.requested_amount)?.toFixed(1)
    }

    const details = {
        title: "",
        amount: null,
        fundsRequested: null,
        transactionFees: null,
        receivedAmount: null
    }

    if (user && user?.id == fundNotification?.user?.id) {
        if (fundNotification?.requested_by == "hunter") {
            details.title = `${fundNotification?.reservation?.stand_detail?.title} Have Paid`
        } else {
            details.title = `${fundNotification?.reservation?.user?.first_name} ${fundNotification?.reservation?.user?.last_name} Have Paid`
        }
        details.amount = fundNotification?.requested_amount?.toFixed(1)
        details.fundsRequested = fundNotification?.requested_amount?.toFixed(1)
        details.transactionFees = fundNotification?.transaction_fee_percentage
        details.receivedAmount = calculateReceivedAmount()
    } else {
        details.title = "You Have Paid"
        details.amount = fundNotification?.requested_amount?.toFixed(1)
        details.fundsRequested = fundNotification?.requested_amount?.toFixed(1)
        details.transactionFees = 0
    }

    const handleClose = () => {
        setShow(false)
        dispatch(clearFundNotifications())
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="transfer-model "
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center" >
                        <h4 className="mb-0">{details.title}</h4>
                        <h2 className="my-3">${details?.amount}</h2>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="mb-2">Funds Requested  </p>
                        <p className="mb-2 fw-bold">${details?.fundsRequested}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="mb-2">Transaction Fee </p>
                        <p className="mb-2 fw-bold">{details?.transactionFees}%</p>
                    </div>
                    {details?.receivedAmount ? <div className="d-flex justify-content-between">
                        <p className="mb-2">You'll Receive </p>
                        <p className="mb-2 fw-bold">${details?.receivedAmount}</p>
                    </div> : null}
                    <div className="d-flex justify-content-center">
                        <button
                            className="btn btn-primary text-white px-3 mr-0 w-100 mt-3"
                            onClick={handleClose}
                        >
                            OKAY
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
