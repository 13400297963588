import toastHandler from "components/UI/toastHandler/toastHandler";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Spinner from "components/UI/Spinner.js";
import * as actions from "../../../_redux/dashboard/dashboardActions";
import { useDashboardUIContext } from "../../DashboardUIContext";
import { useHistory } from "react-router-dom";
import { setAuthStatus } from "modules/landingPage/_redux/landingPageActions";


export default function TransferAmountModal({
  show,
  onHide,
  setShowPaymentInformationModal,
  amountInfoMail,
}) {
  const [error, setError] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [transferAmountValue, setTransferAmountValue] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const toastId = history.location.pathname;

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.transactionsListQueryParams,
    };
  }, [dashboardPageUIContext]);

  let pattern = new RegExp('^(([0-9]{1,7})(\\.[0-9]{1})?)$');

  const validationSchema = Yup.object({
    transfer_amount: Yup.number().moreThan(0, "Amount should be greater than zero").test('value', 'Invalid amount', val => pattern.test(val)),
  });

  const onTransferAmountSuccess = () => {
    setIsloading(false);
    toastHandler("Amount transferred successfully", "success", toastId);
    dispatch(actions.getAmountInfo());
    dispatch(actions.getTransactions({...dashboardPageUIProps.queryParams, all_dates: 1}));
    onHide();
    dispatch(setAuthStatus(false));
  };

  const onError = (message) => {
    setIsloading(false);
    setError(message);
  };

  const formik = useFormik({
    initialValues: { transfer_amount: "0.0" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError("");
      setIsloading(true);
      setTransferAmountValue(values.transfer_amount);
      const data = new FormData();
      data.append("amount", values.transfer_amount);
      await dispatch(actions.transferAmount(data, onTransferAmountSuccess, onError));
      setIsloading(false);
    },
  });

  const { currentUser, authStatus } = useSelector(
    (state) => ({
      currentUser: state?.auth?.user,
      authStatus: state?.landingPage?.authStatus
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!show) {
      formik.resetForm();
      setError(null);
    }
    if (authStatus) {
      formik.setFieldValue('transfer_amount', transferAmountValue);
      formik.submitForm();
    }
  }, [show, authStatus]);

  const onFocusHandler = () => {
    if (formik.values.transfer_amount === '0.0') {
        formik.setFieldValue('transfer_amount', '');
    }
  };

  const onBlurHandler = () => {
      if (formik.values.transfer_amount === '') {
          formik.setFieldValue('transfer_amount', '0.0');
      }
  };

  const onClickHandler = () => {
      if (formik.values.transfer_amount === '0.0') {
          formik.setFieldValue('transfer_amount', '');
      }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="transfer-model transfer-amount-model"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h3>Transfer Amount</h3>
        <p>Please connect your stripe account for money transfer</p>
        <div className="stripe-box mt-4">
          <div>
            <p className="title">Stripe</p>
            <p>Stripe Email</p>
            <div className="emailBox">
              <p>{currentUser?.payment_info?.email || amountInfoMail}</p>
              <p
                className="change cursor-pointer"
                onClick={() => {
                  onHide();
                  setShowPaymentInformationModal(true);
                }}
              >
                Change
              </p>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <p>Enter Amount you want to transfer</p>
          <div className="form-floating mb-3">
            {
              <span style={{
                  position: "absolute",
                  left: 14,
                  top: 20,
                  color: "#000",
                  fontSize: 14,
                  zIndex: 2
              }}>$</span>
            }
            <input
              type="number"
              className="form-control"
              placeholder="Amount"
              name="transfer_amount"
              value={formik.values.transfer_amount}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
              onClick={onClickHandler}
              onChange={formik.handleChange}
              style={{ paddingLeft: 24 }}
            />
            <label for="mobilenumber">Amount</label>
            {formik?.touched?.transfer_amount &&
              formik?.errors?.transfer_amount && (
                <div style={{ color: "red" }}>
                  {formik?.errors?.transfer_amount}
                </div>
              )}
          </div>
          {formik.values.transfer_amount && formik.values.transfer_amount > 0 ? (
            <button type="submit" className="btn btn-primary text-white">
              {isLoading ? (
                <Spinner />
              ) : (
                `TRANSFER $${formik.values.transfer_amount}`
              )}
            </button>
          ) : null}
          {error && (
            <Form.Control.Feedback type="d-block invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}
