import { initializeApp } from "firebase/app";
import axios from "./services/axiosInstance";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import config from "config";

const firebaseConfig = {
  apiKey: config.FIREBASE.apiKey,
  authDomain: config.FIREBASE.authDomain,
  projectId: config.FIREBASE.projectId,
  storageBucket: config.FIREBASE.storageBucket,
  messagingSenderId: config.FIREBASE.messagingSenderId,
  appId: config.FIREBASE.appId,
  measurementId: config.FIREBASE.measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

// Get Token And update

export const getDeviceToken = () => {
  const userAgent = navigator.userAgent;
  let osInfo = 'Unknown';

  if (userAgent.match(/android|Android/i)) {
    osInfo = 'Android';
  } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
    osInfo = 'iOS';
  } else if (userAgent.match(/Win/i)) {
    osInfo = 'Windows';
  } else if (userAgent.match(/Mac/i)) {
    osInfo = 'MacOS';
  } else if (userAgent.match(/Linux/i)) {
    osInfo = 'Linux';
  }
  
  getToken(messaging, {
    vapidKey:
      "BJBnSUNCr8BLUmw69nYL2Sb-6rOHBjASCILkoRx730DszUr_5WBlnxi0G_gicwUgsvu_ChksDRChtvAEsaR-1EQ",
  })
    .then((currentToken) => {
      if (currentToken) {
        if (localStorage.getItem("token"))
          axios
            .post("/device-key", { device_key: currentToken, platform: "web", device_model: osInfo })
            .then((res) => console.log("Token Updated"))
            .catch((err) => console.log(err));
      }
    })
    .catch((err) => {
      console.log("Token", err);
    });
};

// getDeviceToken();
