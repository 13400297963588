import React, { useRef, useState, useEffect } from "react";
import contactPerson from "assets/images/contactPerson.png";
import EmailIcon from "assets/icons/email-icon.png";
import MessageIcon from "assets/icons/message-icon.png";
import CallIcon from "assets/icons/call-icon.png";
import "./floatingButton.scss";

export default function FloatingActionButton() {
  const [showList, setShowList] = useState(false);
  const floatingDivRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        floatingDivRef.current &&
        !floatingDivRef.current.contains(event.target)
      ) {
        setShowList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="floating-button" ref={floatingDivRef}>
        <ul className={`floating-button-list ${showList ? "active" : null}`}>
          <li className="floating-button-list-item">
            <div>
              <img className="floating-button-img" src={MessageIcon} alt="message icon" />
              <span className="floating-button-list-text">Message</span>
              <a href="sms:8774486848" className="floating-button-list-innertext">877-IHunt4U</a>
            </div>
          </li>
          <li className="floating-button-list-item">
            <div>
              <img className="floating-button-img" src={CallIcon} alt="call icon" />
              <span className="floating-button-list-text">Call</span>
              <a href="tel:8774486848" className="floating-button-list-innertext">877-448-6848</a>
            </div>
          </li>
          <li
            className="floating-button-list-item"
            onClick={() =>
              (window.location = "mailto:nevinw@ihunt.com?subject=&body=")
            }
          >
            <img className="floating-button-img" src={EmailIcon} alt="email icon" />
            <span className="floating-button-list-text">Email</span>
            <a href="mailto:nevinw@ihunt.com" className="floating-button-list-innertext">nevinw@ihunt.com</a>
          </li>
        </ul>
        <img
          src={contactPerson}
          onClick={() => setShowList(!showList)}
          alt="contact person"
          className="floating-profile-img"
        />
      </div>
    </>
  );
}
