import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { clearFundNotifications } from "modules/dashboard/_redux/reservations/reservationActions";


export default function CancelStatusModal({ show, setShow }) {

    const dispatch = useDispatch()

    const {
        fundNotification,
    } = useSelector(
        (state) => ({
            fundNotification: state.reservation.fundNotification,
        }),
        shallowEqual
    );

    const details = {
        title: "",
        amount: null,
        descriptionTitle: null,
        description: null
    }

    if (fundNotification?.requested_by == "hunter") {
        details.title = `${fundNotification?.reservation?.user?.first_name} ${fundNotification?.reservation?.user?.last_name} canceled the request`
        details.amount = fundNotification?.requested_amount?.toFixed(1)
        details.descriptionTitle = "Hunter Notes"
        details.description = fundNotification?.description
    } else {
        details.title = `${fundNotification?.reservation?.stand_detail?.title} canceled the request`
        details.amount = fundNotification?.requested_amount?.toFixed(1)
        details.descriptionTitle = "Landowner Notes"
        details.description = fundNotification?.description
    }

    const handleClose = () => {
        setShow(false)
        dispatch(clearFundNotifications())
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="transfer-model "
                centered
            > <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center flex-column text-center">
                        <h4 className="mb-0">{details?.title}</h4>
                        <h2 className="my-3">${details.amount}</h2>
                        <strong>{details?.descriptionTitle}</strong>
                        <p>{details?.description}</p>
                        <div className="d-flex justify-content-between">
                            <button
                                className="btn btn-primary text-white px-3 mr-5 w-auto flex-grow-1 me-0"
                                onClick={handleClose}
                            >
                                OKAY
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
