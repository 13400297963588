import axios from "../../../../services/axiosInstance";

export function transferAmount(data) {
  return axios.post("transfer-amount", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getTransactions(queryParams) {
  return axios.get("transactions", { params: queryParams });
}

export function getAmountInfo() {
  return axios.get("amount-info");
}

export function fetchAmountStatsGraphData(filter) {
  return axios.get("graph-data", { params: { filter } });
}

export function fetchTrophiesRoomList(queryParams) {
  return axios.get("trophy-room", { params: queryParams });
}

export function getDocument(queryParams) {
  return axios.get("documents", { params: queryParams });
}

export function searchUserStand(text) {
  return axios.get(`/user-payments-stands?title=${text}`);
}