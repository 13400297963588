import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { modifyValuesWithDialCode } from "utils/helpers";
import config from "config";
import "./style.scss";
import Spinner from "components/UI/Spinner.js";

export default function PaymentInformationModal({
  show,
  onHide,
}) {

  const [isLoading, setIsLoading] = useState(false);

  const paymentInformationFormInitValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  };

  const paymentInfoValidationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    phone: Yup.string().required("Phone Number is required"),
    country_code: Yup.string().required(),
    country_dial_code: Yup.string(),
    email: Yup.string().required("Stripe Email is required"),
  });

  useEffect(() => {
    if (!show) {
      formik.setErrors({});
    }
  }, [show]);

  const formik = useFormik({
    initialValues: paymentInformationFormInitValues,
    validationSchema: paymentInfoValidationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const updatedValues = modifyValuesWithDialCode(values, true);
      localStorage.setItem("testObject", JSON.stringify(updatedValues));
      const stripeUrl = `https://connect.stripe.com/express/oauth/authorize?client_id=${config.STRIPE_URL}&stripe_user%5Bfirst_name%5D=${updatedValues.first_name}&stripe_user%5Blast_name%5D=${updatedValues.last_name}&stripe_user%5Bemail%5D=${updatedValues.email}&stripe_user%5Bphone_number%5D=${updatedValues.phone}&stripe_user%5Bbusiness_type%5D=individual&capabilities[card_payments][requested]=true&capabilities[transfers][requested]=true&stripe_user%5Bbusiness_name%5D=#/`;
      window.location.href = stripeUrl;
    },
  });
  return (
    <Modal show={show} onHide={onHide} className="transfer-model" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h3>Transfer Amount</h3>
        <p>Please connect your stripe account for money transfer</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="John"
              name="first_name"
              onChange={formik.handleChange}
            />
            <label for="floatingInput">First Name</label>
            {formik?.touched?.first_name && formik?.errors?.first_name && (
              <div style={{ color: "red" }}>{formik?.errors?.first_name}</div>
            )}
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Doe"
              name="last_name"
              onChange={formik.handleChange}
            />
            <label for="floatingInput">Last Name</label>
            {formik?.touched?.last_name && formik?.errors?.last_name && (
              <div style={{ color: "red" }}>{formik?.errors?.last_name}</div>
            )}
          </div>
          <PhoneInput
            copyNumbersOnly={false}
            value={formik.values.phone}
            onChange={(value, country) => {
              formik.setFieldValue("phone", value);
              formik.setFieldValue("country_code", country.countryCode);
              formik.setFieldValue("country_dial_code", country.dialCode);
            }}
            containerClass={`phone__container date__picker`}
            inputClass={`input__container date__picker`}
          />
          {formik?.touched?.phone && formik?.errors?.phone && (
            <div style={{ color: "red", marginBottom: "4%" }}>
              {formik?.errors?.phone}
            </div>
          )}
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="abc@example.com"
              name="email"
              onChange={formik.handleChange}
            />
            <label for="floatingInput">Stripe Email</label>
            {formik?.touched?.email && formik?.errors?.email ? (
              <div style={{ color: "red" }}>{formik?.errors?.email}</div>
            ) : null}
          </div>

          <button type="submit" className="btn blue-btn">
            {isLoading ? (
              <Spinner />
            ) : (
              "CONNECT WITH STRIPE"
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
