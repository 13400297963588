import CustomSpinner from "components/layout/Loader/Spinner";
import axios from "axios";
import StandItemSkeleton from "components/layout/skeletons/stand-item-skeleton";
import { List } from "immutable";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import * as actions from "../../_redux/landingPageActions";
import Filter from "../filter";
import LandBookingItems from "../landBookingItems";
import { useLandingPageUIContext } from "../LandingPageUIContext";
import Search from "../search";
import "./style.scss";
import ClusteringMap from "./Cluster";
import NoRecordFound from "utils/noRecordFound";
import StandBlogNew from "../StandBlogPage";


export default function Stand() {
  const source = axios.CancelToken.source();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [featured, setFeatured] = useState(0);
  const [reset, setReset] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [bound, setBound] = useState([])
  const standsContainerRef = useRef(null);

  const location = useLocation();
  const handleFeatured = (value) => {
    setFeatured(value);
  };

  const landingPageUIContext = useLandingPageUIContext();
  const landingPageUIProps = useMemo(() => {
    return {
      queryParams: landingPageUIContext.queryParams,
      setQueryParams: landingPageUIContext.setQueryParams,
      showMap: landingPageUIContext.showMap,
      setShowMap: landingPageUIContext.setShowMap,
      currentScreen: landingPageUIContext.currentScreen,
      setCurrentScreen: landingPageUIContext.setCurrentScreen,
      mapState: landingPageUIContext.mapState,
      setMapState: landingPageUIContext.setMapState,
      openStandDetailPage: landingPageUIContext.openStandDetailPage,
    };
  }, [landingPageUIContext]);

  const { standsList, totalRecords, fetchingStand } = useSelector(
    (state) => ({
      standsList: state.landingPage.standsList,
      totalRecords: state.landingPage.totalStands,
      fetchingStand: state.landingPage.fetchingStand
    }),
    shallowEqual
  );

  const zoom = 10;
  const perPage = 9;

  const fetchMoreStands = async () => {
    const nextPage = page + 1;
    let newPointOfInterest = {}
    let newSiteType = {}
    let newTerrain = {}
    setPage(page + 1);
    let param = landingPageUIProps.queryParams;
    if (param?.points_of_interest?.length > 0) {
      newPointOfInterest = await param.points_of_interest?.map((item, index) => ({
        [`stand_point_of_interest[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newPointOfInterest }
    }
    if (param?.site_type?.length > 0) {
      newSiteType = await param.site_type?.map((item, index) => ({
        [`site_type[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newSiteType }
    }
    if (param?.terrain?.length > 0) {
      newTerrain = await param.terrain?.map((item, index) => ({
        [`terrain[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newTerrain }
    }
    if (landingPageUIProps?.showMap) {
      param = {
        ...landingPageUIProps?.queryParams,
        sw_lat: bound?.sw_lat,
        sw_long: bound?.sw_long,
        ne_lat: bound?.ne_lat,
        ne_long: bound?.ne_long,
        latitude: bound?.latitude,
        longitude: bound?.longitude,
        zoom: bound?.zoom
      }
    }
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        dispatch(actions.fetchStands({
          ...param,
          page: nextPage,
          per_page: landingPageUIProps?.showMap ? 1000 : perPage,
          stand_longitude: location.coords.longitude,
          stand_latitude: location.coords.latitude
        }, source, landingPageUIProps?.showMap, false));

      }, (error) => {
        dispatch(actions.fetchStands({ ...param, page: nextPage, per_page: perPage }, source, landingPageUIProps?.showMap, false));
      });
    } else dispatch(actions.fetchStands({ ...param, page: nextPage, per_page: perPage }, source, landingPageUIProps?.showMap.false, false));
  };

  useEffect(() => {
    if (page > 1) {
      if (standsContainerRef.current) {
        standsContainerRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
      }
    }
  }, [page])

  useEffect(() => {
    let requiredPAge = Math.round((totalRecords / 9) + 1);
    if (!standsList?.length || standsList?.length == totalRecords) {
      return setHasMore(false);
    }
    if (page > requiredPAge) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [standsList, totalRecords]);

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      // if user all to share current location
      (position) => {
        landingPageUIProps?.setMapState({
          ...landingPageUIProps?.mapState,
          center: new List([
            position.coords.latitude,
            position.coords.longitude,
          ]),
        });
      },
      // if user don't allow to share current location
      () => {
        landingPageUIProps?.setMapState({
          ...landingPageUIProps?.mapState,
          center: new List([0, 0]),
          zoom: zoom,
        });
      }
    );
  };

  useEffect(() => {
    setPage(1);
    if (!landingPageUIProps?.showMap) {
      let param = landingPageUIProps.queryParams;
      let newPointOfInterest = {};
      let newSiteType = {};
      let newTerrain = {};
      if (param?.points_of_interest?.length > 0) {
        newPointOfInterest = param.points_of_interest?.map((item, index) => ({
          [`stand_point_of_interest[${index}]`]: item?.value
        })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
        param = { ...param, ...newPointOfInterest }
      }
      if (param?.site_type?.length > 0) {
        newSiteType = param.site_type?.map((item, index) => ({
          [`site_type[${index}]`]: item?.value
        })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
        param = { ...param, ...newSiteType }
      }
      if (param?.terrain?.length > 0) {
        newTerrain = param.terrain?.map((item, index) => ({
          [`terrain[${index}]`]: item?.value
        })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
        param = { ...param, ...newTerrain }
      }
    }
  }, [landingPageUIProps?.showMap]);

  const handlePlaceSelect = (place) => {
    if (place !== null) {
      landingPageUIProps?.setMapState({
        ...landingPageUIProps?.mapState,
        center: new List([Number(place.lat), Number(place.lng)]),
        zoom: place.zoom,
      });
    } else {
      getCurrentPosition();
    }
  };

  const handleMapChange = (bounds, center, zoom) => {
    setBound({
      sw_lat: bounds?.sw?.lat,
      sw_long: bounds?.sw?.lng,
      ne_lat: bounds?.ne?.lat,
      ne_long: bounds?.ne?.lng,
      latitude: center?.lat,
      longitude: center?.lng,
      zoom
    })
    let param = landingPageUIProps.queryParams;
    let newPointOfInterest = {};
    let newSiteType = {};
    let newTerrain = {};
    if (param?.points_of_interest?.length > 0) {
      newPointOfInterest = param.points_of_interest?.map((item, index) => ({
        [`stand_point_of_interest[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newPointOfInterest }
    }
    if (param?.site_type?.length > 0) {
      newSiteType = param.site_type?.map((item, index) => ({
        [`site_type[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newSiteType }
    }
    if (param?.terrain?.length > 0) {
      newTerrain = param.terrain?.map((item, index) => ({
        [`terrain[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newTerrain }
    }
    navigator.geolocation.getCurrentPosition((location) => {
      dispatch(actions.fetchStands({
        ...param,
        per_page: landingPageUIProps?.showMap ? 1000 : perPage,
        sw_lat: bounds?.sw?.lat,
        sw_long: bounds?.sw?.lng,
        ne_lat: bounds?.ne?.lat,
        ne_long: bounds?.ne?.lng,
        latitude: center?.lat,
        longitude: center?.lng,
        zoom,
        stand_longitude: location.coords.longitude,
        stand_latitude: location.coords.latitude
      }, source, landingPageUIProps?.showMap, true));
    },
    () => {
      dispatch(actions.fetchStands({
        ...param, 
        per_page: landingPageUIProps?.showMap ? 1000 : perPage,
        sw_lat: bounds?.sw?.lat,
        sw_long: bounds?.sw?.lng,
        ne_lat: bounds?.ne?.lat,
        ne_long: bounds?.ne?.lng,
        latitude: center?.lat,
        longitude: center?.lng,
        zoom,
      }, source, landingPageUIProps?.showMap, true))
    }
    );
  }

  useEffect(() => {
    if (landingPageUIProps?.queryParams?.title == "") {
      getCurrentPosition();
    }
  }, [landingPageUIProps?.queryParams?.title])

  function setPageTitle(pageName) {
    document.title = pageName + ' | iHunt';
  }
  
  if (landingPageUIProps?.currentScreen == "list") {
    setPageTitle('List View');
  } else if (landingPageUIProps?.currentScreen == "map") {
    setPageTitle('Map View');
  }

  useEffect(() => {
    if (landingPageUIProps?.currentScreen === "list" && document.title === "List View | iHunt") {
      if (landingPageUIProps?.currentScreen != "blog") {
        if (localStorage.getItem("fromStandDetails")) {
          localStorage.removeItem("fromStandDetails");
          if (landingPageUIProps?.showMap) {
            return;
          }
        }
        setPage(1);
        let param = landingPageUIProps.queryParams;
        let newPointOfInterest = {};
        let newSiteType = {};
        let newTerrain = {};
        if (param?.points_of_interest?.length > 0) {
          newPointOfInterest = param.points_of_interest?.map((item, index) => ({
            [`stand_point_of_interest[${index}]`]: item?.value
          })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
          param = { ...param, ...newPointOfInterest }
        }
        if (param?.site_type?.length > 0) {
          newSiteType = param.site_type?.map((item, index) => ({
            [`site_type[${index}]`]: item?.value
          })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
          param = { ...param, ...newSiteType }
        }
        if (param?.terrain?.length > 0) {
          newTerrain = param.terrain?.map((item, index) => ({
            [`terrain[${index}]`]: item?.value
          })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
          param = { ...param, ...newTerrain }
        }
        if (landingPageUIProps?.showMap) {
          param = {
            ...param,
            sw_lat: bound?.sw_lat,
            sw_long: bound?.sw_long,
            ne_lat: bound?.ne_lat,
            ne_long: bound?.ne_long,
            latitude: bound?.latitude,
            longitude: bound?.longitude,
            zoom: bound?.zoom
          }
        }
        if (navigator?.geolocation) {
          navigator.geolocation.getCurrentPosition((location) => {
            dispatch(actions.fetchStands({
              ...param,
              per_page: landingPageUIProps?.showMap ? 1000 : perPage,
              stand_longitude: location.coords.longitude,
              stand_latitude: location.coords.latitude
            }, source, landingPageUIProps?.showMap, true));
          }, (error) => {
            dispatch(actions.fetchStands(param, source, landingPageUIProps?.showMap, true));
          });
        } else {
          dispatch(actions.fetchStands(param, source, landingPageUIProps?.showMap, true))
        }
      }
    }
  }, [landingPageUIProps.queryParams, landingPageUIProps.currentScreen, document.title]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="stands">
      <h1 className="text-center">Listing</h1>
      <Search
        onPlaceSelect={handlePlaceSelect}
        featured={featured}
        landingPageUIProps={landingPageUIProps}
        setFeatured={setFeatured}
        setReset={setReset}
        reset={reset}
        showMap={landingPageUIProps?.showMap}
      />
      <Filter
        onChangeToggle={(e) => {
          if (!landingPageUIProps?.queryParams?.title) {
            getCurrentPosition();
          }
          landingPageUIProps?.setShowMap(e == 'map');
          landingPageUIProps.setCurrentScreen(e)
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.has("coordinate")) queryParams.delete("coordinate");
          if (queryParams.has("zoom")) queryParams.delete("zoom");
          if (queryParams.has("map")) queryParams.delete("map");
          dispatch(actions.clearStandListing());
        }}
        value={landingPageUIProps?.showMap}
        setFeatured={handleFeatured}
        featured={featured}
        landingPageUIProps={landingPageUIProps}
        reset={reset}
        setReset={setReset}
      />
      <div
        className={
          landingPageUIProps?.showMap ? "stand-listig-with-map mh-120" : "stand-listig-without-map mh-120"
        }
      >
        <div className="inner-container pb-3">
          {
            landingPageUIProps?.currentScreen == "list" || landingPageUIProps?.currentScreen == "map" ?
              <div className="row">
                <div className={landingPageUIProps?.showMap ? "col-lg-6" : "col-lg-12"}>
                  {fetchingStand ?
                    <CustomSpinner /> : standsList?.length === 0 && !fetchingStand ?
                      <NoRecordFound message="No listing found" /> :
                      <div className="stands-list-container" ref={standsContainerRef}>
                      <InfiniteScroll
                        dataLength={standsList?.length}
                        next={fetchMoreStands}
                        hasMore={hasMore}
                        loader={
                          <div className="row">
                            <StandItemSkeleton showMap={landingPageUIProps?.showMap} />
                          </div>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>{standsList?.length === 0 ? null : ""}</b>
                          </p>
                        }
                      >
                        <LandBookingItems isMap={landingPageUIProps?.showMap} lands={standsList} /> 
                      </InfiniteScroll>
                      </div>
                  }
                </div>
                {landingPageUIProps?.showMap && (
                  <div className={`col-lg-6 order-first order-lg-2 mb-4`}>
                    <div className="map-wrapper">
                      <ClusteringMap
                        center={landingPageUIProps?.mapState.center.toJS()}
                        markers={standsList}
                        handleMapChange={handleMapChange}
                      />
                    </div>
                  </div>
                )}
              </div> :
              <div className="row">
                <StandBlogNew loadingPageUIProps={landingPageUIProps} />
              </div>
          }

        </div>
      </div>
    </div>
  );
}
