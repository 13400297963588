import UserCard from "components/UI/contactButton";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CustomSpinner from "components/layout/Loader/Spinner";
import { clearFundNotifications } from "modules/dashboard/_redux/reservations/reservationActions";


export default function DeclinedStatusModal({ show, setShow, reason }) {

    const dispatch = useDispatch()

    const details = {
        title: "",
        titleDescription: "",
        reasonTitle: "",
        reasonDescription: "",
        user: {}
    }

    const {
        listLoading,
        fundNotification,
        user
    } = useSelector(
        (state) => ({
            listLoading: state.reservation.listLoading,
            fundNotification: state.reservation.fundNotification,
            user: state.auth.user,
        }),
        shallowEqual
    );

    const handleClose = () => {
        setShow(false)
        dispatch(clearFundNotifications())
    }

    if (user && user?.id == fundNotification?.user?.id) {
        if (user?.id == fundNotification?.reservation?.stand_detail?.user?.id) {
            details.title = fundNotification?.reservation?.user?.first_name + " " +
                fundNotification?.reservation?.user?.last_name
            details.titleDescription = "Has Declined Your Request"
            details.reasonDescription = "Hunter Notes:"
            details.user = { ...fundNotification.reservation?.user }
        } else {
            details.title = fundNotification?.reservation?.stand_detail?.title
            details.titleDescription = "Has Declined Your Request"
            details.reasonDescription = "Landowner Notes:"
            details.user = { ...fundNotification?.reservation?.stand_detail?.user }
        }
    }
    else {
        details.title = "You"
        details.titleDescription = "Have Declined This Request"
        details.reasonDescription = "Your Notes:"
        details.user = { ...fundNotification?.user }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="transfer-model "
            centered
        >
            {
                listLoading ? <CustomSpinner /> :
                    <>
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex flex-column align-items-center" >
                                <p className="mb-1 fw-bold">{details?.title}</p>
                                <p className="mb-2 fw-bold">{details?.titleDescription}</p>
                                <p className="mb-1 mt-2 fw-bold">{details?.reasonDescription}</p>
                            </div>
                            <div className="d-flex justify-content-center text-center">
                                <p className="mb-3 pb-2">{fundNotification?.decline_reason || reason}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                {
                                    user && user?.id == fundNotification?.user?.id ?
                                        <UserCard
                                            user={details?.user}
                                            subject="Reply On Funds Request Decline"
                                            reservationId={fundNotification?.reservation?.id}
                                            showInput={true}
                                            showChat={true} /> :
                                        <button
                                            className="btn btn-primary text-white px-3 mr-5 w-auto"
                                            onClick={handleClose}
                                        >
                                            OKAY
                                        </button>
                                }
                            </div>
                        </Modal.Body>
                    </>
            }
        </Modal>
    );
}
