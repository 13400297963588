import Pagination from "components/UI/pagination/Pagination";
import React, { useEffect, useMemo, useState } from "react";
import CustomSpinner from "../../../../../../components/layout/Loader/Spinner";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import * as actions from "../../../../_redux/stand/standActions";
import { useDashboardUIContext } from "../../../DashboardUIContext";
import Dropdown from 'react-bootstrap/Dropdown';
import StandItem from "../standItem/StandItem";
import NoFavStand from "assets/images/NoFavStands.png"
import "./../standItem/style.scss";
import moment from "moment";

export default function FollowedStands() {

    const [value, setValue] = useState(0);
    const search = useLocation().search;
    const page = new URLSearchParams(search).get("page") || 1;
    const history = useHistory();

    const dispatch = useDispatch();
    const dashboardPageUIContext = useDashboardUIContext();
    const dashboardPageUIProps = useMemo(() => {
        return {
            queryParams: dashboardPageUIContext.standQueryParams,
            setQueryParams: dashboardPageUIContext.setStandQueryParams,
        };
    }, [dashboardPageUIContext]);

    const { listLoading, standsList, totalRecords } = useSelector(
        (state) => ({
            listLoading: state.stand.listLoading,
            standsList: state.stand.standsList,
            totalRecords: state.stand.totalStands,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition((location) => {
                dispatch(
                    actions.getFollowedStands({
                        ...dashboardPageUIProps.queryParams,
                        current_date: moment().format("MMM DD YYYY HH:MM"),
                        stand_longitude: location.coords.longitude,
                        stand_latitude: location.coords.latitude,
                        per_page: 10,
                        page: parseInt(page),
                    }));

            }, (error) => {
                dispatch(
                    actions.getFollowedStands({
                        ...dashboardPageUIProps.queryParams,
                        current_date: moment().format("MMM DD YYYY HH:MM"),
                        per_page: 10,
                        page: parseInt(page),
                    }));
            });
        } else {
            dispatch(
                actions.getFollowedStands({
                    ...dashboardPageUIProps.queryParams,
                    current_date: moment().format("MMM DD YYYY HH:MM"),
                    per_page: 10,
                    page: parseInt(page),
                }));
        }
    }, [dispatch, page, dashboardPageUIProps.queryParams, value]);

    const handleSortOrder = (value) => {
        dashboardPageUIProps.setQueryParams({
            ...dashboardPageUIProps.queryParams,
            sort_order: value
        })
    }

    return (
        <>
            <div className="pb-4 d-flex justify-content-between">
                <h6 className="d-content-inner-title font-roboto mb-0">My Followed Listing</h6>
                <div className="new-filter-btn">
                    <Dropdown align="end" style={{
                        cursor: "pointer"
                    }}>
                        <Dropdown.Toggle drop="down-centered">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.628" height="20.003" viewBox="0 0 15.628 20.003">
                                <path id="Path_1249" data-name="Path 1249" d="M146.877,15.942a.935.935,0,0,0-1.633-.625l0,0-1.178,1.309V.94a.938.938,0,0,0-1.875,0V16.625l-1.178-1.309,0,0a.935.935,0,1,0-1.39,1.25l0,0,2.813,3.126,0,0a.929.929,0,0,0,1.39,0l0,0,2.813-3.126,0,0a.933.933,0,0,0,.243-.625Zm-8.367-12.5L135.7.313l0,0a.927.927,0,0,0-1.389,0l0,0L131.49,3.438l0,0a.934.934,0,1,0,1.389,1.25l0,0,1.178-1.309V19.068a.938.938,0,0,0,1.875,0V3.384l1.178,1.309,0,0a.934.934,0,1,0,1.389-1.25Z" transform="translate(-131.249 -0.003)" />
                            </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                className={dashboardPageUIProps?.queryParams?.sort_order == "recent" ? "active" : null}
                                onClick={() => handleSortOrder('recent')}>Sort By Recent</Dropdown.Item>
                            <Dropdown.Item
                                className={dashboardPageUIProps?.queryParams?.sort_order == "closest" ? "active" : null}
                                onClick={() => handleSortOrder('closest')}>Sort By Closest</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {
                listLoading ? <CustomSpinner /> :
                    <div className={standsList?.length == 0 ? 'row h-100' : 'row'}>
                        {
                            standsList && standsList?.length > 0 ? (
                                <>
                                    {standsList?.map((stand, index) =>
                                        stand ? (
                                            <StandItem
                                                currentTab="followed"
                                                stand={stand}
                                                key={index}
                                                value={value}
                                                setValue={setValue}
                                            />
                                        ) : (
                                            <CustomSpinner />
                                        )
                                    )}
                                    <div className="inner-container">
                                        <Pagination
                                            totalRecords={totalRecords}
                                            uiProps={dashboardPageUIProps}
                                            caller={"followedStands"}
                                            activeTab={"followed"}
                                            history={history}
                                        />
                                    </div>
                                </>
                            ) : <div className="no-fvrts " >
                                <img src={NoFavStand} alt="image" />
                                <p className="no-stand-found-heading">
                                    You haven't followed any listing yet!
                                </p>
                                <p className="sub-heading">Explore more and shortlist some listing</p>
                            </div>}
                    </div>
            }
        </>
    );
}
