import React, { useState } from "react";
import LongContractImg from "./../../../../assets/images/long-contact.png"
import PlaceHolderImage from "./../../../../assets/images/long-contact-1.jpg"
import LandingPageWavierModal from "./wavierModal";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function LongContract() {
    const [openModal, setOpenModal] = useState(false)

    return (
        <>
            <section className="about-iHunt-section">
                <div className="inner-container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="d-flex justify-content-center pb-2">What Does It Cost?</h3>
                        </div>
                        <div className="col-lg-6 mb-lg-0 mb-4">
                            <div className="left">
                                <div className="left-inner">
                                    <p>
                                        Getting started is free and easy! As a landowner, you have full control of pricing, and you keep 92% of the price! That’s right. iHunt only collects 8% of the revenue from the landowner and that is the only time we collect a fee from you! All leasing agreements are digitally signed and stored right in our app when you setup each property. No mailing paperwork or trying to make your own contracts, our detailed agreements will have you covered!
                                    </p>
                                    <button className="btn btn-primary text-white text-uppercase" onClick={() => setOpenModal(true)}>LANDOWNER AGREEMENT</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="right">
                                <div className="img">
                                    <LazyLoadImage
                                        src={LongContractImg}
                                        alt=""
                                        className="lazy-load-image"
                                        placeholderSrc={PlaceHolderImage}
                                        visibleByDefault={LongContractImg}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LandingPageWavierModal show={openModal} setShow={setOpenModal} type="landowner" />
        </>
    );
}
