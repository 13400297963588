import React from "react";
import DownloadAppImg from "./../../../../assets/images/download-app.png";
import PlaceHolderImage from "./../../../../assets/images/download-app-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function EnjoyDownloadHunting() {
    return (
        <section className="account-detail-guide bg-5">
            <div className="inner-container">
                <div className="row justify-content-between">
                    <div className="col-lg-5 col-md-6">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Enjoy Hunting!</h5>
                                <p>
                                By following these steps, you can effectively use the iHunt app to book hunting sites, plan your hunting days, and navigate your hunting locations while enjoying a seamless experience. Remember to abide by hunting laws, respect nature, and prioritize safety throughout your hunting journey.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={DownloadAppImg}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={DownloadAppImg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
