import defaultImage from "assets/images/default.png";
import Pagination from "components/UI/pagination/Pagination";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Play from "../../../../assets/icons/play.svg";
import * as actions from "../../_redux/landingPageActions";
import { useLandingPageUIContext } from "../LandingPageUIContext";
import NoRecordFound from "utils/noRecordFound";
import "./style.scss";

export default function Tutorials() {
  const dispatch = useDispatch();

  const landingPageUIContext = useLandingPageUIContext();
  const landingPageUIProps = useMemo(() => {
    return {
      queryParams: landingPageUIContext.tutorialsQueryParams,
      setQueryParams: landingPageUIContext.setTutorialsQueryParams,
    };
  }, [landingPageUIContext]);

  const { totalRecords, tutorialsList } = useSelector(
    (state) => ({
      totalRecords: state.landingPage.totalTutorialsRecords,
      tutorialsList: state.landingPage.tutorialsList,
    }),
    shallowEqual
  );

  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(
      Math.ceil(totalRecords / landingPageUIProps.queryParams.per_page)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecords]);

  useEffect(() => {
    dispatch(actions.fetchTutorialsList(landingPageUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageUIProps.queryParams]);

  const [selectedTutorialVideoUrl, setSelectedTutorialVideoUrl] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setSelectedTutorialVideoUrl("");
  };

  useEffect(() => {document.title = 'Tutorials | iHunt'}, []);

  return (
    <>
      <div className="blog blog-tutorial">
        {tutorialsList && tutorialsList?.length > 0 ?
          <div className="inner-container">
            {tutorialsList && tutorialsList?.length > 0 && (
              <>
                <div className="blog-grid mt-5">
                  <div className="blog-item">
                    <img
                      src={tutorialsList[0]?.image || defaultImage}
                      onError={(e) => (e.target.src = defaultImage)}
                      alt=""
                    />
                    <div
                      className="layer cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setSelectedTutorialVideoUrl(tutorialsList[0]?.video_url);
                      }}
                    >
                      <img src={Play} alt="" />
                    </div>
                    <div className="blog-bottom">
                      <h4 className="heading">{tutorialsList[0]?.title}</h4>
                    </div>
                    <div className="blog-bottom-date">
                      <p className="heading">{tutorialsList[1]?.title}</p>
                    </div>
                  </div>
                  <div>
                    {tutorialsList?.length > 1 && (
                      <div className="blog-item blog-right">
                        <img
                          src={tutorialsList[1]?.image || defaultImage}
                          onError={(e) => (e.target.src = defaultImage)}
                          alt=""
                        />
                        <div
                          className="layer cursor-pointer"
                          onClick={() => {
                            setShowModal(true);
                            setSelectedTutorialVideoUrl(
                              tutorialsList[1].video_url
                            );
                          }}
                        >
                          <img src={Play} alt="" />
                        </div>
                        <div className="blog-bottom">
                          <h4 className="heading">{tutorialsList[1]?.title}</h4>
                        </div>
                        <div className="blog-bottom-date">
                          <p className="heading">{tutorialsList[1]?.title}</p>
                        </div>
                      </div>
                    )}
                    {tutorialsList?.length > 2 && (
                      <div className="blog-item blog-right">
                        <img
                          src={tutorialsList[2]?.image || defaultImage}
                          onError={(e) => (e.target.src = defaultImage)}
                          alt=""
                        />
                        <div
                          className="layer cursor-pointer"
                          onClick={() => {
                            setShowModal(true);
                            setSelectedTutorialVideoUrl(
                              tutorialsList[2]?.video_url
                            );
                          }}
                        ></div>
                        <div className="blog-bottom">
                          <h4 className="heading">{tutorialsList[2]?.title}</h4>
                        </div>
                        <div className="blog-bottom-date">
                          <p className="heading">{tutorialsList[1]?.title}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="blog-grid mt-5">
                  <div>
                    {tutorialsList?.length > 3 && (
                      <div className="blog-item blog-right">
                        <img
                          src={tutorialsList[3]?.image || defaultImage}
                          onError={(e) => (e.target.src = defaultImage)}
                          alt=""
                        />
                        <div
                          className="layer cursor-pointer"
                          onClick={() => {
                            setShowModal(true);
                            setSelectedTutorialVideoUrl(
                              tutorialsList[3]?.video_url
                            );
                          }}
                        >
                          <img src={Play} alt="" />
                        </div>
                        <div className="blog-bottom">
                          <h4 className="heading">{tutorialsList[3]?.title}</h4>
                        </div>
                      </div>
                    )}
                    {tutorialsList?.length > 4 && (
                      <div className="blog-item blog-right">
                        <img
                          src={tutorialsList[4]?.image || defaultImage}
                          onError={(e) => (e.target.src = defaultImage)}
                          alt=""
                        />
                        <div
                          className="layer cursor-pointer"
                          onClick={() => {
                            setShowModal(true);
                            setSelectedTutorialVideoUrl(
                              tutorialsList[4]?.video_url
                            );
                          }}
                        >
                          <img src={Play} alt="" />
                        </div>
                        <div className="blog-bottom">
                          <h4 className="heading">{tutorialsList[4]?.title}</h4>
                        </div>
                        <div className="blog-bottom-date">
                          <p className="heading">{tutorialsList[1]?.title}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  {tutorialsList?.length > 5 && (
                    <div className="blog-item">
                      <img
                        src={tutorialsList[5]?.image || defaultImage}
                        onError={(e) => (e.target.src = defaultImage)}
                        alt=""
                      />
                      <div
                        className="layer cursor-pointer"
                        onClick={() => {
                          setShowModal(true);
                          setSelectedTutorialVideoUrl(tutorialsList[5]?.video_url);
                        }}
                      >
                        <img src={Play} alt="" />
                      </div>
                      <div className="blog-bottom">
                        <h4 className="heading">{tutorialsList[5]?.title}</h4>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="blog-btm-grid my-5">
              {tutorialsList?.length > 6 && (
                <div className="blog-item">
                  <img
                    src={tutorialsList[6]?.image || defaultImage}
                    onError={(e) => (e.target.src = defaultImage)}
                    alt=""
                  />
                  <div className="blog-bottom">
                    <h4 className="heading">{tutorialsList[6]?.title}</h4>
                  </div>
                </div>
              )}
              {tutorialsList?.length > 7 && (
                <div className="blog-item">
                  <img
                    src={tutorialsList[7]?.image || defaultImage}
                    onError={(e) => (e.target.src = defaultImage)}
                    alt=""
                  />{" "}
                  <div className="blog-bottom">
                    <h4 className="heading">{tutorialsList[7]?.title}</h4>
                  </div>
                </div>
              )}
              {tutorialsList?.length > 8 && (
                <div className="blog-item">
                  <img
                    src={tutorialsList[8]?.image || defaultImage}
                    onError={(e) => (e.target.src = defaultImage)}
                    alt=""
                  />{" "}
                  <div className="blog-bottom">
                    <h4 className="heading">{tutorialsList[8]?.title}</h4>
                  </div>
                </div>
              )}
            </div>
          </div> : <NoRecordFound />}
        {totalRecords > 9 && (
          <div className="inner-container">
            <Pagination
              totalRecords={totalRecords}
              uiProps={landingPageUIProps}
            />
          </div>
        )}
      </div>

      <Modal className="react-player-video" show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Body className="p-0 mt-auto">
          {selectedTutorialVideoUrl && (
            <ReactPlayer url={selectedTutorialVideoUrl} controls={true} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
