export const initialFilter = {
  per_page: 9,
  page: 1,
  person_capacity: "",
  walk_distance: "",
  start_price: "",
  end_price: "",
  cellular_coverage: null,
  start_date: "",
  end_date: "",
  stand_distance: "",
  trophies_count_from: "",
  trophies_count_to: "",
  specie: "",
  stand_type: "",
  handicap_accessible: null,
  site_type: [],
  feature: 0,
  stand_id: null,
  is_marked_trail: null,
  search: "",
  title: "",
  sort_order: "recent",
  points_of_interest: [],
  terrain: []
};

export const sizePerPageList = [
  { text: "10", value: 9 },
  { text: "25", value: 25 },
  { text: "50", value: 50 }, // Size per page
];

export const initialBlogsFilter = {
  per_page: 9,
  page: 1,
};

export const inititalReviewsFilter = {
  per_page: 10,
  page: 1,
};
export const initialTutorialsFilter = {
  per_page: 9,
  page: 1,
};

export const initialTrophiesFilter = {
  per_page: 10,
  page: 1,
};

export const initialStandBlogsFilter = {
  per_page: 5,
  page: 1,
};

// Stand DropDownOptions
export const standDropDownOptions = [
  {
    value: 1,
    label: "All Listing",
  },
  {
    value: 2,
    label: "Featured",
  },
];

export const initialNotificationsFilter = {
  per_page: 10,
  page: 1,
};

export const mapConstants = {
  K_MARGIN_TOP: 30,
  K_MARGIN_RIGHT: 30,
  K_MARGIN_BOTTOM: 30,
  K_MARGIN_LEFT: 30,
  K_HOVER_DISTANCE: 30,
};
