import Pagination from "components/UI/pagination/Pagination";
import { fetchTrophiesRoomList } from "modules/dashboard/_redux/dashboard/dashboardActions";
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import gallaryImage1 from "../../../../assets/images/default.png";
import filterIcon from "../../../../assets/icons/equalizer-line 1.svg";
import { useDashboardUIContext } from "../DashboardUIContext";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Filter from "./filter/filter";
import "lightgallery.js/dist/css/lightgallery.css";
import "./style.scss";
import CustomSpinner from "components/layout/Loader/Spinner";
import NoRecordFound from "utils/noRecordFound";

export default function TrophyRoom() {
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useDispatch();

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.trophyRoomQueryParams,
      setQueryParams: dashboardPageUIContext.setTrophyRoomQueryParams,
    };
  }, [dashboardPageUIContext]);

  const { trophiesList, totalRecords, isLoading } = useSelector(
    (state) => ({
      trophiesList: state.dashboard.trophiesRoomList,
      totalRecords: state.dashboard.trophiesRoomTotalRecords,
      isLoading: state.dashboard.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchTrophiesRoomList(dashboardPageUIProps.queryParams));
  }, [dashboardPageUIProps.queryParams]);

  return (
    <div className="trophy-room-wrapper">
      <div className="row">
        <div className="col-12 mb-3">
          <div className="top-title">
            <h6 className="d-content-inner-title font-roboto mb-0">
              Trophy Room
            </h6>
            <button
              className="filter-btn"
              onClick={() => setShowFilters(!showFilters)}
            >
              <img src={filterIcon} alt="" />
              Filter
            </button>
          </div>
        </div>
        {showFilters ? (
          <div className="col-12">
            <Filter />
          </div>
        ) : null}
        <LightgalleryProvider>
          {trophiesList.length && !isLoading ? (
            trophiesList.map((trophy, index) => (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-12 fade-in item"
                key={index}
              >
                <LightgalleryItem
                  group={"group"}
                  src={trophy.image || gallaryImage1}
                >
                  <div className="gallery-image">
                    <img
                      src={trophy.image || gallaryImage1}
                      alt=""
                      onError={(e) => (e.target.src = gallaryImage1)}
                    />
                  </div>
                </LightgalleryItem>
              </div>
            ))
          ) : !isLoading ? (
            <NoRecordFound message="No trophy found" />
          ) : (
            <CustomSpinner />
          )}
        </LightgalleryProvider>
        {!isLoading && totalRecords > 12 ? (
          <div className="inner-container">
            <Pagination
              totalRecords={totalRecords}
              uiProps={dashboardPageUIProps}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
