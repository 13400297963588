import { reviewSlice, callTypes } from "./reviewSlice";
import * as requestFromServer from "./reviewCRUD";

const { actions } = reviewSlice;

export const getReviews = (queryParams, keyName) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getReviews(queryParams, keyName);
    if (response.status) {
      dispatch(
        actions.reviewsFetched({
          entities: response.data.all_reviews,
          totalRecords: response.data.total_records || 0,
          averageRating: response.data.average_ratings || 0,
          reviewsCounter: response.data.review_counter || 0,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};
export const clearEntities = () => async (dispatch) => {
  dispatch(actions.clearEntities());
};
export const getUserReviews = (id, queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getUserReviews(id, queryParams);
    if (response.status) {
      dispatch(
        actions.userReviewsFetched({
          entities: response.data,
          totalRecords: response.total_records || 0,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearUserReviews());
    }
    return 0;
  }
};

export const getReviewQuestions = (type, queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getReviewQuestions(type);
    if (response.status) {
      dispatch(actions.ReviewQuestionsFetched({ entities: response.data }));
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearReviewQuestions());
    }
    return 0;
  }
};

export const getHunterQuestions = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getReviewQuestions("hunter");
    if (response.status) {
      dispatch(actions.hunterQuestionsFetched({ entities: response.data }));
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearReviewQuestions());
    }
    return 0;
  }
};

export const getLandownerQuestions = (type) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getReviewQuestions("landowner");
    if (response.status) {
      dispatch(actions.landownerQuestionsFetched({ entities: response.data }));
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearReviewQuestions());
    }
    return 0;
  }
};

export const updateReview =
  (data, reviewId, requestType, onSuccess, onError) => async (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    try {
      let response;
      if (requestType == "post") {
        response = await requestFromServer.postHunterReview(data, reviewId);
      } else {
        response = await requestFromServer.updateReview(data, reviewId);
      }
      if (response.status) {
        dispatch(actions.updatedReview());
        onSuccess(response.message);
      }
      setTimeout(() => {
        dispatch(actions.clearUpdatedReview());
      }, 1000);
    } catch (error) {
      dispatch(actions.clearUpdatedReview());
      onError(error?.response?.data?.message || "Something wrong");
      return 0;
    }
  };
