import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import EyeOffIcon from "assets/icons/feather-icon - eye-off.svg";
import EyeOnIcon from "assets/icons/feather-eye.svg";
import "./AuthenticateModal.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import toastHandler from "components/UI/toastHandler/toastHandler";
import Spinner from "components/UI/Spinner.js";
import * as actions from "../../../../modules/auth/_redux/authActions";
import { setAuthStatus } from "modules/landingPage/_redux/landingPageActions";
import { resetLoader } from "modules/dashboard/_redux/reservations/reservationActions";
import { openFourOThreeModal } from "modules/landingPage/_redux/landingPageActions";


const AuthenticateModal = ({ show, onHide }) => {
const [showPassword, setShowPassword] = useState(false);
const [error, setError] = useState("");

  const dispatch = useDispatch();

  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.auth.actionsLoading,
    }),
    shallowEqual
  );

  const handleClose = () => {
    setError(false);
    setShowPassword(false);
    formik.resetForm();
    onHide();
    dispatch(resetLoader());
  };

  const formInitialValues = {
    password: "",
  };

  const formValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be 6 characters long."),
  });

  const handleSubmit = (values) => {
    dispatch(actions.authenticateUser(values, onError, onSuccess));
  };

  const onError = (message) => {
    dispatch(openFourOThreeModal(false, null));
    setError(message);
  };

  const onSuccess = (message) => {
    dispatch(setAuthStatus(true));
    toastHandler(message, "success");
    handleClose();
  };

  const formik = useFormik({
    validationSchema: formValidationSchema,
    initialValues: formInitialValues,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      className="authentication-modal"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <p>
          To perform this action, please enter the password of your account.{" "}
        </p>
        <Modal.Title id="example-modal-sizes-title-lg">
          Authentication Required
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-box">
          <Form onSubmit={formik.handleSubmit}>
            <FloatingLabel controlId="floatingInputGrid" label="Password">
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik?.values?.password}
              />
              <div
                className="input-icon"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <img src={showPassword ? EyeOnIcon : EyeOffIcon} alt="" />
              </div>
            </FloatingLabel>
            {error && (
              <Form.Control.Feedback type="d-block invalid">
                {error}
              </Form.Control.Feedback>
            )}
            {formik?.touched?.password &&
              formik?.errors?.password && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              )}
            <button className="btn btn-primary w-100 text-white mt-3" type="submit">
              {isLoading ? (
                <Spinner/>
              ) : (
                "PROCEED"
              )}
            </button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AuthenticateModal;
