import BlogImg from "assets/images/blog-img.png";
import User from "assets/images/user.png";
import parse from "html-react-parser";
import moment from "moment";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/landingPageActions";
import TopBar from "../topBar.js/index.js";
import "./style.scss";

export default function BlogDetail({ id }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(actions.fetchBlogDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { blogDetails } = useSelector(
    (state) => ({
      blogDetails: state.landingPage.blogDetails,
    }),
    shallowEqual
  );

  return (
    <>
      <TopBar />
      <div className="inner-container">
        <div className="blog-section mb-5">
          <div className="blog-heading">
            <h1 className="mt-3">{blogDetails?.title}</h1>
            <p className="mt-3">
              Posted on {moment(blogDetails?.created_at).format("MMM D, YYYY")}
            </p>
            <img
              className="blog-cover mt-3"
              src={blogDetails?.image || BlogImg}
              onError={(e) => (e.target.src = BlogImg)}
              alt=""
            />
          </div>
          <div className="blog-detail">
            <div className="blog-card my-5">
              <div className="blog-user">
                <img
                  src={blogDetails?.user?.image || User}
                  onError={(e) => (e.target.src = User)}
                  alt=""
                />
                <div className="ms-3">
                  <h5 className="mb-0">{`${blogDetails?.user?.first_name} ${blogDetails?.user?.last_name}`}</h5>
                </div>
              </div>
            </div>
            {parse(blogDetails?.body || "")}
          </div>
        </div>
      </div>
    </>
  );
}
