import { useFormik } from "formik";
import React from 'react';
import Modal from "react-bootstrap/Modal";
import "./style.scss"
import { FloatingLabel, Form } from 'react-bootstrap';
import Spinner from "components/UI/Spinner.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as actions from "../../../../modules/dashboard/_redux/reservations/reservationActions";


const ReferralModal = ({ show, handleClose, formik, setDiscount, setReferralCode }) => {
  const dispatch = useDispatch();

  const { verifyingReferral } = useSelector(
    (state) => ({
      verifyingReferral: state.reservation.verifyingReferral,
    }),
    shallowEqual
  );

  const referralFormik = useFormik({
    initialValues: {
      referral_code: "",
    },
    validationSchema: Yup.object({
      referral_code: Yup.string().required("Referral code is required"),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("referral_code", values.referral_code);
      data.append("avail_for", "reservation");
      dispatch(actions.verifyReferral(data, onSuccess, onError));
    },
  });

  const onSuccess = (data) => {
    formik.setFieldValue("discount", data.discount);
    formik.setFieldValue("referral_code", data.referral_code);
    setDiscount(data.discount)
    setReferralCode(data.referral_code)
    onHide();
  };

  const onError = (message) => {
    referralFormik.setFieldError("referral_code", message);
  };

  const onHide = () => {
    referralFormik.resetForm();
    handleClose();
  };

  return (
    <Modal className="referral-modal" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Referral
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='referral-form' onSubmit={referralFormik.handleSubmit}>
          <FloatingLabel controlId="floatingInputGrid" label="Enter Referral Code">
            <Form.Control
              type="text"
              name="referral_code"
              id="referral_code"
              value={referralFormik.values.referral_code}
              onChange={referralFormik.handleChange}
              className={`form-control fs-14`}
            />
          </FloatingLabel>
          {referralFormik?.touched?.referral_code && referralFormik?.errors?.referral_code && (
            <Form.Control.Feedback type="d-block invalid">
              {referralFormik.errors.referral_code}
            </Form.Control.Feedback>
          )}

          <div className='modal-action-btns'>
            <button className='modal-action-btn outlined' type='button' onClick={onHide}>CANCEL</button>
            <button className='modal-action-btn filled' type='submit'>
            {verifyingReferral ? (
              <Spinner />
            ) : (
              "APPLY"
            )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ReferralModal;
