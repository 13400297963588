import React, { useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pinIcon from "assets/icons/pindark.svg";
import resImg from "assets/images/reservation-img.png";
import { useDashboardUIContext } from "../../DashboardUIContext";
import { ConfirmationModal } from "components/UI/modals/confirmation-modal/confirmationModal";
import toastHandler from "components/UI/toastHandler/toastHandler";
import "./reservationItem.scss";
import { DeletedStand } from "components/UI/modals/deleted-stand/DeletedStand";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import * as actions from "modules/dashboard/_redux/reservations/reservationActions";
import axios from "axios";

export default function ReservationItem({ reservation, activeTab }) {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelErrorModal, setShowCancelErrorModal] = useState(false);

  const dispatch = useDispatch();
  const source = axios.CancelToken.source();
  const [showDeleteStandModal, setShowDeletedStandModal] = useState(false);

  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.reservation.actionsLoading,
    }),
    shallowEqual
  );

  const handleConfirmModalSubmit = (confirmed) => {
    if (confirmed) {
      dispatch(
        actions.cancelReservation(
          reservation?.id,
          onReservationCancelSuccess,
          onReservationCancelError,
          false
        )
      );
    } else {
      setShowCancelModal(false);
    }
  };

  const onReservationCancelSuccess = () => {
    setShowCancelModal(false);
    toastHandler("Reservation canceled successfully!", "success");
    history.push({
      pathname: "/my-profile/my-reservations",
      search: "?" + new URLSearchParams({ activeTab: "history" }).toString(),
    });
    dispatch(actions.getReservations({ ...dashboardPageUIProps.queryParams, type: "history" }, source));
  };

  const onReservationCancelError = () => {
    setShowCancelModal(false);
  };

  const history = useHistory();

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.reservationQueryParams,
    };
  }, [dashboardPageUIContext]);

  const navigateToStand = (id, reservationId, currentUser) => {
    if (!id) return;
    history.push(`/stand/${id}`, {
      reservationId,
      id,
      currentUser
    });
  };

  const getImage = (images) => {
    if (images?.length > 0) {
      return images[0]?.picture
    } else return resImg
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <i
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }} className="bi bi-three-dots-vertical three-dots" />
  ));

  const redirectToReservationDetails = (reservationId) => {
    const queryParams = {
      page: dashboardPageUIProps.queryParams.page,
      activeTab,
    };
  
    history.push(`/my-profile/reservation-details/${reservationId}`, { state: queryParams });
  };

  return (
    <div className="col-xxl-4 col-xl-6 py-2 fade-in ">
      <div className={reservation?.current_user_type == "main_hunter" ?
        "my-res-box main-hunter" : "my-res-box"}>
        <div className="mr-topDetails">
          <img
            src={getImage(reservation?.stand_detail?.pictures)}
            onError={(e) => (e.target.src = resImg)}
            alt=""
          />
          <div className="mr-land-info w-100">
            <div className="d-flex align-items-start justify-content-between">
              <h5 className="oneline-text">{reservation?.stand_detail?.title}</h5>
              <Dropdown className="initiate-transfer" as={ButtonGroup}>
                <Dropdown.Toggle as={CustomToggle} drop="down-centered" />
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => redirectToReservationDetails(reservation?.id)}>
                      Reservation Detail
                  </Dropdown.Item>
                  {
                    activeTab === "current" && reservation?.can_cancel && (
                      <Dropdown.Item onClick={() => setShowCancelModal(true)} className="cancel-reservation-txt">Cancel Reservation</Dropdown.Item>
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="mr-land-info cursor-pointer"
              onClick={() => {
                reservation?.stand_detail?.is_deleted
                  ? setShowDeletedStandModal(true)
                  : navigateToStand(
                    reservation?.stand_detail?.id,
                    reservation?.id,
                    reservation?.current_user_type
                  );
              }}
            >
              <div className="location">
                <img className="mt-1" src={pinIcon} alt="" />
                <p className="details-top bg-transparent">
                  {reservation?.stand_detail?.address}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="ms-listing-box-content">
          <div className="d-flex justify-content-between">
            <span className="txt-seventh">
              <strong>
                {reservation?.start_date} - {reservation?.end_date}
              </strong>
            </span>
          </div>

          <div className={`d-inline-flex w-100 mt-4 ${reservation?.current_user_type == "main_hunter" ? "justify-content-between" : "justify-content-end"}`}>
            {reservation?.current_user_type == "main_hunter" ? (
              <span className="amount">
                <strong>
                  ${reservation?.amount}
                </strong>
              </span>) : null
            }
            <div className={`text-${reservation?.status === "canceled" || reservation?.status === "rejected"
              ? "danger"
              : reservation?.status === "paid" || reservation?.status === "completed"
                ? "completed"
                : reservation?.status === "approved"
                ? "primary"
                : "warning"
              } d-flex align-items-center fs-14`}
            >
              <span className={`dot-${reservation?.status === "canceled" || reservation?.status === "rejected"
                ? "danger"
                : reservation?.status === "paid" || reservation?.status === "completed"
                  ? "completed"
                  : reservation?.status === "approved"
                  ? "primary"
                  : "warning"
                }`}
              ></span>{" "}
              {reservation?.status?.charAt(0).toUpperCase()
                + reservation?.status?.slice(1)}
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        show={showCancelModal}
        onHide={handleConfirmModalSubmit}
        title={"Confirmation"}
        message={reservation?.cancellation_policy}
        isLoading={actionsLoading}
        yesString={"OK, PROCEED"}
        noString={"NOT NOW"}
        onlyYes={false}
      />

      <ConfirmationModal
        show={showCancelErrorModal}
        onHide={() => {
          setShowCancelErrorModal(false);
        }}
        title={"Error"}
        message={"Reservation cannot be canceled"}
        yesString={"OK"}
        onlyYes={true}
      />

      <DeletedStand
        onHide={() => {
          setShowDeletedStandModal(false);
        }}
        show={showDeleteStandModal}
      />
    </div >
  );
}
