import React, { useEffect, useState } from "react";
import ThumbnailCarusal from "../thumbnailCarusal/index.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAllStandBlogs } from "modules/landingPage/_redux/landingPageActions";
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecordFound from "utils/noRecordFound";
import Avatar from "assets/images/avatar.png"
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import "./style.scss";


export default function StandBlogNew({ loadingPageUIProps }) {
  const source = axios.CancelToken.source();
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [showFullContent, setShowFullContent] = useState([]);
  const dispatch = useDispatch();

  const { standsBlogList, totalStandBlogs } = useSelector(
    (state) => ({
      standsBlogList: state.landingPage.standsBlogList,
      totalStandBlogs: state.landingPage.totalStandBlogs
    }), shallowEqual);

  const fetchMoreStandBlogs = async () => {
    const nextPage = page + 1;
    let newPointOfInterest = {}
    let newSiteType = {}
    let newTerrain = {}
    setPage(() => page + 1);
    let param = loadingPageUIProps.queryParams;
    if (param?.points_of_interest?.length > 0) {
      newPointOfInterest = await param.points_of_interest?.map((item, index) => ({
        [`stand_point_of_interest[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newPointOfInterest }
    }
    if (param?.site_type?.length > 0) {
      newSiteType = await param.site_type?.map((item, index) => ({
        [`site_type[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newSiteType }
    }
    if (param?.terrain?.length > 0) {
      newTerrain = await param.terrain?.map((item, index) => ({
        [`terrain[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newTerrain }
    }
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        dispatch(fetchAllStandBlogs({
          ...param,
          stand_longitude: location.coords.longitude,
          stand_latitude: location.coords.latitude,
          page: nextPage,
          per_page: 5
        }, source, false));
      }, (error) => {
        dispatch(fetchAllStandBlogs({ ...param, page: nextPage, per_page: 5 }, source, false));
      });
    } else dispatch(fetchAllStandBlogs({ ...param, page: nextPage, per_page: 5 }, source, false));
  }

  useEffect(() => {
    setPage(1);
    let param = loadingPageUIProps.queryParams;
    let newPointOfInterest = {};
    let newSiteType = {};
    let newTerrain = {};
    if (param?.points_of_interest?.length > 0) {
      newPointOfInterest = param.points_of_interest?.map((item, index) => ({
        [`stand_point_of_interest[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newPointOfInterest }
    }
    if (param?.site_type?.length > 0) {
      newSiteType = param.site_type?.map((item, index) => ({
        [`site_type[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newSiteType }
    }
    if (param?.terrain?.length > 0) {
      newTerrain = param.terrain?.map((item, index) => ({
        [`terrain[${index}]`]: item?.value
      })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      param = { ...param, ...newTerrain }
    }
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        dispatch(fetchAllStandBlogs({
          ...param,
          per_page: 8,
          stand_longitude: location.coords.longitude,
          stand_latitude: location.coords.latitude
        }, source, true));
      }, (error) => {
        dispatch(fetchAllStandBlogs(param, source, true));
      });
    } else {
      dispatch(fetchAllStandBlogs(param, source, true));
    }
  }, [dispatch, loadingPageUIProps.queryParams]);

  useEffect(() => {
    if (!standsBlogList?.length) {
      return setHasMore(false);
    }
    if (standsBlogList?.length >= totalStandBlogs) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [standsBlogList, totalStandBlogs]);

  useEffect(() => { document.title = 'Blog View | iHunt' }, []);

  const toggleContent = (id) => {
    const isIdPresent = showFullContent.includes(id);
    const updatedState = isIdPresent
      ? showFullContent.filter(item => item !== id)
      : [...showFullContent, id];
    setShowFullContent(updatedState);
  };

  const formatText = (text) => {
    const urlPattern = /((https?|ftp):\/\/[^\s<]+)/g;
    const formattedText = text
    ?.replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`)
    ?.replace(/\r\n/g, "<br/>")
    ?.replace(/\n/g, "<br/>");
    return { __html: formattedText };
  };

  return (
    <>
      <div className="blog-page mt-5">
        <div className="inner-container">
          <div className="mb-5">
            <div className="text-center">
              <h1 className="font-50">Latest Updates on Listing</h1>
              <p className="mx-w-960">
                A place where you can see updates on listing by landowners. Keep exploring hunting locations!
              </p>
            </div>
          </div>
        </div>
        <div className="inner-container mb-20">
          <InfiniteScroll
            dataLength={standsBlogList?.length}
            hasMore={hasMore}
            next={fetchMoreStandBlogs}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>{standsBlogList?.length === 0 ? null : ""}</b>
              </p>
            }
          >
            {standsBlogList && standsBlogList.map((blog) => {
              return <div><div className="main-blog" key={blog?.id}>
                <div className="d-flex align-items-center justify-content-between main-blog-topbar">
                  <div className="flex-column flex-md-row py-3">
                    <h3 className="blog-title">
                    <span className="date">
                      <label>{moment(blog?.updated_at).format("DD")}</label>
                      {moment(blog?.updated_at).format('MMM YYYY')}
                    </span>
                      <div>
                        {blog?.title}
                        <div className="blogSub-details">
                          <div className="user-info">
                            <div className="user-info__img">
                              <img src={blog?.user?.image || Avatar} alt=""></img>
                            </div>
                            <div className="user-info__desc">
                              <h5>Listing Owner</h5>
                              <h6>{blog?.user?.first_name + " " + blog?.user?.last_name}</h6>
                            </div>
                          </div>
                          <div className="user-info">
                            <div className="user-info__img">
                              <img src={blog?.stand?.image || Avatar} alt="" />
                            </div>
                            <div className="user-info__desc">
                              <h5>{blog?.stand?.title}</h5>
                              <h6>{blog?.stand?.address}</h6>
                            </div>
                          </div>
                          <div>
                            <Link to={`/stand/${blog?.stand?.id}`} className="btn btn-primary text-nowrap text-white">LISTNIG DETAIL</Link>
                          </div>
                        </div>
                      </div>
                    </h3>
                  </div>
                </div>
                {
                  blog?.media?.length > 0 &&
                  <div className="stand-blog-wrapper">
                    <ThumbnailCarusal standId={blog?.id} from="standBlog" media={blog?.media} />
                  </div>
                }
                <div className="desc">
                  {blog?.description?.length > 500 ? showFullContent.includes(blog?.id) ? (
                    <>
                    <span dangerouslySetInnerHTML={formatText(blog?.description)} />
                      <span onClick={() => toggleContent(blog?.id)} className="read-more">
                        Show Less
                      </span>
                    </>
                  ) : (
                    <>
                    <span dangerouslySetInnerHTML={formatText(blog?.description?.slice(0, 500))} />
                      <span onClick={() => toggleContent(blog?.id)} className="read-more">
                        Show More
                      </span>
                    </>
                  ) : <span dangerouslySetInnerHTML={formatText(blog?.description)} />}
                </div>
              </div>
                <hr className="my-5" /></div>
            })}
          </InfiniteScroll>
          {
            standsBlogList?.length == 0 ?
              <NoRecordFound
                message="No listing blog found"
                className="no-record"
              /> : null
          }
        </div>
      </div>
    </>
  );
}

