import defaultAvatar from "assets/images/default-avatar.png";
import Horns from "assets/images/horns.svg";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SwiperCore, { Pagination, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "../../../../../node_modules/swiper/components/pagination/pagination.scss";
import * as actions from "../../_redux/landingPageActions";
import "./style.scss";

SwiperCore.use([Pagination, Keyboard]);

function Feedback() {
  const dispatch = useDispatch();
  const { feedbacks } = useSelector(
    (state) => ({
      feedbacks: state.landingPage.feedbacks,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchFeedbacks());
  }, []);

  return feedbacks?.length ? (
    <div className="inner-container">
      <div className="feedback">
        <h2>Hunters Feedback</h2>
        <div className="feedback-slider">        
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={true}
            navigation={feedbacks.length > 1 ? true : false}
          >
            {feedbacks.map((feedback, index) => (
              <SwiperSlide key={index}>
                <div className="item">
                  <div className="feedback-img">
                    <img
                      className="person"
                      src={feedback?.user?.image || defaultAvatar}
                      alt=""
                    />
                    <img className="horns" src={Horns} alt="" />
                  </div>
                  <div className="feedback-detail">
                    <div className="d-flex mt-5">
                      <p className="quote">"</p>
                      <div>
                        <p>{feedback.feedback}</p>

                        <h4>
                          {feedback.user
                            ? `${feedback.user.first_name} ${feedback.user.last_name}`
                            : "Anonymous"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  ) : null;
}

export default Feedback;
