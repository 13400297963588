import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import Equalizer from "../../../../assets/icons/equalizer-line 1.svg";
import FilterButton from "../filter/filter";
import "./style.scss";
import { useFormik } from "formik";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

export default function Search({
  onPlaceSelect,
  setReset,
  landingPageUIProps,
  reset,
  showMap
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [placeData, setPlaceData] = useState(null);
  const [inputValue, setInputValue] = useState(landingPageUIProps?.queryParams?.title ?
    landingPageUIProps?.queryParams?.title : "");

  const apiCallerWithSearchParams = (event) => {
    setInputValue(event.target.value)
    const timeout = setTimeout(() => {
      landingPageUIProps?.setQueryParams({
        ...landingPageUIProps?.queryParams,
        placeSelected: true,
        title: event.target.value,
      });
    }, [800])
    return () => clearTimeout(timeout)
  };
  useEffect(() => {
    if (placeData !== null) {
      onPlaceSelect(placeData);
    }
  }, [placeData]);

  const formInititalValues = {
    title: "",
  };

  const searchFieldSubmittion = () => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: formInititalValues,
    onSubmit: searchFieldSubmittion,
  });

  /**
   * The function is called when a user selects a place from the dropdown list. It sets the input value
   * to the selected place's formatted address, and then sets the longitude and latitude of the
   * selected place to the longLatObjectOnEachSelection state
   * @param place - The place object returned by the Google Maps API.
   */
  const onPlaceSelected = (place, value) => {
    setInputValue(value?.value);
    landingPageUIProps.setQueryParams({
      ...landingPageUIProps.queryParams,
      sw_lat: place?.geometry?.location?.lat() - 0.01,
      sw_long: place?.geometry?.location?.lng() - 0.01,
      ne_lat: place?.geometry?.location?.lat() + 0.01,
      ne_long: place?.geometry?.location?.lng() + 0.01,
      title: value?.value
    })

    // emit eevent to set map state if in map view
    // this is because react-google-autocomplete is saving the initial state of the function we got as a prop so this is a work around
    setPlaceData({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
      zoom: 15,
    });
  };

  const handleEnterOnAddressfield = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  const handleSortOrder = (value) => {
    landingPageUIProps.setQueryParams({
      ...landingPageUIProps.queryParams,
      sort_order: value
    })
  }

  return (
    <div className="search-field-holder">
      <div className="inner-container">
        {showMap ? (
          <>
            <div className="stand-main-form ">
              <Form className="search-form">
                <Autocomplete
                  name="Hello"
                  id="google-autocomplete"
                  className="form-control shadow-none"
                  onKeyPress={handleEnterOnAddressfield}
                  onPlaceSelected={(place, value) => onPlaceSelected(place, value)}
                  componentRestrictions={{ country: "za" }}
                  value={inputValue}
                  options={{
                    fields: ["address_components", "geometry", "place_id"],
                    types: [],
                  }}
                  placeholder="Enter a Location..."
                  onChange={(e) => {
                    const value = e.target.value;
                    landingPageUIProps.setQueryParams({
                      ...landingPageUIProps.queryParams,
                      title: value
                    })
                    setInputValue(value);
                    if (!value) {
                      landingPageUIProps.setQueryParams({
                        ...landingPageUIProps.queryParams,
                        placeSelected: false,
                        sw_lat: "",
                        sw_long: "",
                        ne_lat: "",
                        ne_long: "",
                      });
                      onPlaceSelect(null);
                    }
                  }}
                />
                <div className="new-filter-btn">
                  <Dropdown align="end" as={ButtonGroup} style={{
                    cursor: "pointer"
                  }}>
                    <Dropdown.Toggle drop="down-centered">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.628" height="20.003" viewBox="0 0 15.628 20.003">
                        <path id="Path_1249" data-name="Path 1249" d="M146.877,15.942a.935.935,0,0,0-1.633-.625l0,0-1.178,1.309V.94a.938.938,0,0,0-1.875,0V16.625l-1.178-1.309,0,0a.935.935,0,1,0-1.39,1.25l0,0,2.813,3.126,0,0a.929.929,0,0,0,1.39,0l0,0,2.813-3.126,0,0a.933.933,0,0,0,.243-.625Zm-8.367-12.5L135.7.313l0,0a.927.927,0,0,0-1.389,0l0,0L131.49,3.438l0,0a.934.934,0,1,0,1.389,1.25l0,0,1.178-1.309V19.068a.938.938,0,0,0,1.875,0V3.384l1.178,1.309,0,0a.934.934,0,1,0,1.389-1.25Z" transform="translate(-131.249 -0.003)" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleSortOrder('recent')}
                      >Sort By Recent</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleSortOrder('closest')}
                      >Sort By Closest</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form>
              <div className="filter-btn">
                <button
                  type="button p-0"
                  className="btn btn-sm fw-bold"
                  onClick={() => setShowOptions(!showOptions)}
                >
                  <img src={Equalizer} alt="" />
                </button>
              </div>
            </div>
            <FilterButton
              show={showOptions}
              setInputValue={setInputValue}
              setReset={setReset}
              reset={reset}
            />
          </>
        ) : (
          <>
            <div className="stand-main-form">
              <Form className="search-form" onSubmit={formik.handleSubmit}>
                <input
                  type="text"
                  name="title"
                  className="form-control shadow-none"
                  placeholder={landingPageUIProps.currentScreen == "list" ? "Search Listing..." : "Search blog..."}
                  onChange={apiCallerWithSearchParams}
                  value={inputValue}
                />
                {formik?.errors?.title && (
                  <Form.Control.Feedback type="d-block invalid">
                    {formik.errors.title}
                  </Form.Control.Feedback>
                )}
                <div className="new-filter-btn">
                  <Dropdown align="end" as={ButtonGroup} style={{
                    cursor: "pointer"
                  }}>
                    <Dropdown.Toggle drop="down-centered">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.628" height="20.003" viewBox="0 0 15.628 20.003">
                        <path id="Path_1249" data-name="Path 1249" d="M146.877,15.942a.935.935,0,0,0-1.633-.625l0,0-1.178,1.309V.94a.938.938,0,0,0-1.875,0V16.625l-1.178-1.309,0,0a.935.935,0,1,0-1.39,1.25l0,0,2.813,3.126,0,0a.929.929,0,0,0,1.39,0l0,0,2.813-3.126,0,0a.933.933,0,0,0,.243-.625Zm-8.367-12.5L135.7.313l0,0a.927.927,0,0,0-1.389,0l0,0L131.49,3.438l0,0a.934.934,0,1,0,1.389,1.25l0,0,1.178-1.309V19.068a.938.938,0,0,0,1.875,0V3.384l1.178,1.309,0,0a.934.934,0,1,0,1.389-1.25Z" transform="translate(-131.249 -0.003)" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={landingPageUIProps?.queryParams?.sort_order == "recent" ? "active" : null}
                        onClick={() => handleSortOrder('recent')}>Sort By Recent</Dropdown.Item>
                      <Dropdown.Item
                        className={landingPageUIProps?.queryParams?.sort_order == "closest" ? "active" : null}
                        onClick={() => handleSortOrder('closest')}>Sort By Closest</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form>
              <div className="filter-btn">
                <button
                  type="button"
                  className="btn btn-sm fw-bold p-0 ms-3"
                  onClick={() => setShowOptions(!showOptions)}
                >
                  <img src={Equalizer} alt="" />
                </button>
              </div>
            </div>
            <FilterButton
              show={showOptions}
              setInputValue={setInputValue}
              setReset={setReset}
            />
          </>
        )}
      </div>
    </div>
  );
}
