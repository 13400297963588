import User from "assets/images/default-avatar.png";
import Timer from "assets/images/comment-timer.svg";
import React from "react";


export default function ReviewItem({
  review,
}) {
  return (
    <>
      <div className="reviews mt-4 fade-in">
        <div className="review-item">
          <div className="item-main-img">
            <img
              src={review?.user?.image || User}
              onError={(e) => (e.target.src = User)}
              alt=""
            />
          </div>
          <div className="review-detail">
            <div className="d-flex align-items-center justify-content-between">
              <p className="fw-bold m-0">
                {review?.user?.first_name} {review?.user?.last_name}
              </p>
              <p className="fw-bold m-0 font-18">
                <i className="bi bi-star-fill me-2 text-yellow"></i>
                {review?.rating}
              </p>
            </div>
            <p className="text-secondary m-0">{review?.review}</p>
            <span className="review-date ">
              <img src={Timer} className="timer-icon" alt=""></img>{" "}
              <p className="text-tertiary m-0">{review?.created_at}</p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
