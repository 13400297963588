import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { addContactUsQuery } from "../../_redux/landingPageActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import Spinner from "components/UI/Spinner.js";
import { EMAIL_REGEX } from "utils/helpers";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import "./style.scss";

export default function Contact() {
  const [loading, setLoading] = useState(false)
  const constactUsFormInitValues = {
    name: "",
    email: "",
    message: "",
  };

  const dispatch = useDispatch();
  const history = useHistory()

  const contactUsFormValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is a required Field").min(3).max(50),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is a required field")
      .matches(
        EMAIL_REGEX,
        "Email should be alphnumeric with leading alphabets"
      ),
    message: Yup.string().required("Message is a required field"),
  });

  const onSuccess = () => {
    setTimeout(() => {
      setLoading(false)
      toastHandler(
        "Your Query is recieved, Someone from iHunt will reach you soon!",
        "success"
      );
      contactUsFormik.resetForm();
      history.push('/')
    }, [1000])
  };

  const onError = () => {
    setLoading(false)
    toastHandler("Something Went Wrong", "error");
  };

  const contactUsFormik = useFormik({
    initialValues: constactUsFormInitValues,
    validationSchema: contactUsFormValidationSchema,
    onSubmit: (values) => {
      setLoading(true)
      dispatch(addContactUsQuery(values, onSuccess, onError));
    },
  });

  useEffect(() => { document.title = 'Contact Us | iHunt' }, []);

  return (
    <div>
      <div className="contact-page my-5 pt-4">
        <div className="inner-container">
          <div className="booking-text mb-5">
            <h1>Contact Us</h1>
            <p>
              If you have any questions, comments or concerns feel free to
              contact us
            </p>
          </div>
        </div>
        <div className="inner-container mt-5 py-5">
          <div className="row align-items-center">
            <div className="pe-md-5 col-md-6">
              <div className="contact-box">
                <form onSubmit={contactUsFormik.handleSubmit}>
                  <FormikProvider formik={contactUsFormik}>
                    <div className="formGroup">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        onChange={contactUsFormik.handleChange}
                        className="contact-formfeild"
                        placeholder="Name"
                        value={contactUsFormik.values.name}
                      />
                      {contactUsFormik?.errors?.name &&
                        contactUsFormik.touched.name && (
                          <p style={{ color: "red" }}>
                            {contactUsFormik.errors.name}
                          </p>
                        )}
                    </div>
                    <div className="formGroup">
                      <label>Email</label>
                      <input
                        name="email"
                        onChange={contactUsFormik.handleChange}
                        className="contact-formfeild"
                        placeholder="Email"
                        value={contactUsFormik.values.email}
                      />
                      {contactUsFormik?.errors?.email &&
                        contactUsFormik.touched.email && (
                          <p style={{ color: "red" }}>
                            {contactUsFormik.errors.email}
                          </p>
                        )}
                    </div>
                    <div className="formGroup">
                      <label>Message</label>
                      <textarea
                        type="text"
                        name="message"
                        onChange={contactUsFormik.handleChange}
                        className="contact-formfeild"
                        placeholder="Your message here"
                        value={contactUsFormik.values.message}
                      ></textarea>
                      {contactUsFormik?.errors?.message &&
                        contactUsFormik.touched.message && (
                          <p style={{ color: "red" }}>
                            {contactUsFormik.errors.message}
                          </p>
                        )}
                    </div>
                    <button
                      className="btn btn-primary full-w text-white"
                      type="submit"
                    > {
                        loading ? <Spinner /> : "SUBMIT"
                      }
                    </button>
                  </FormikProvider>
                </form>
              </div>
            </div>
            <div className="col-md-6 ps-md-5 contact-right-content">
              <h3 className="mb-5">Get In Touch</h3>
              <div className="d-flex text-secondary">
                <div className="me-3">
                  <i className="bi bi-telephone-fill fs-22"></i>
                </div>
                <div>
                  <strong>Contact</strong>
                  <p className="mb-1">877-IHunt4U</p>
                  <p>877-448-6848</p>
                </div>
              </div>
              <div className="d-flex text-secondary">
                <div className="me-3">
                  <i className="bi bi-envelope-fill fs-22"></i>
                </div>
                <div>
                  <strong>Email</strong>
                  <p>info@ihunt.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
