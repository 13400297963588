import React, { useEffect, useState } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as actions from "../../_redux/landingPageActions";
import moment from "moment";
import Spinner from "components/UI/Spinner.js";
import User from "assets/images/default-avatar.png";
import logo from "assets/icons/logo-black.png";
import {
  getNotificationsCount,
  markNotificationsRead,
  markAllNotificationsRead,
  fetchNotifications
} from "modules/landingPage/_redux/landingPageActions";
import { getFundsNotification, getAdditionalHunterInvitationDetails, handleNotificationClick, changeAdditionalHunterTab, getReservationDetail, showRejectionReason, showRejectionReasonModal } from "modules/dashboard/_redux/reservations/reservationActions";
import { EditStandModal } from "modules/dashboard/pages/ownerDashboard/mystands/standItem/editModal/editModal";
import { getUser } from "../../_redux/landingPageCrud";
import { getTicketDetails } from "modules/dashboard/_redux/customer-service-portal/customerServicePortalActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import "./style.scss";


export default function Notifications() {

  const history = useHistory();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(10);

  const [hasMore, setHasMore] = useState(false);

  const [open, setOpen] = useState(false);

  const [showEditModal, setEditModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleToggleNotifications = (show) => {
    setOpen(show)
    if (!show) {
      setPage(1);
    } else {
      dispatch(actions.toggleNewNotificationFlag(false));
      dispatch(actions.fetchNotifications({ page, per_page: perPage }, true));
    }
  }

  const fetchMoreNotifications = () => {
    dispatch(
      actions.fetchNotifications({ page: page + 1, per_page: perPage }, false)
    );
    setPage(page + 1);
  };

  const { notifications, totalRecords, notificationFlag, notificationsCount, clickedOnNotification } = useSelector(
    (state) => ({
      notifications: state?.landingPage?.notifications,
      totalRecords: state?.landingPage?.totalNotificationRecords,
      notificationFlag: state?.landingPage?.newNotificationAvailable,
      notificationsCount: state?.landingPage?.notificationsCount,
      clickedOnNotification: state?.reservation?.clickedOnNotification,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getNotificationsCount())
  }, [])

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (open) {
      bodyElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "auto";
    }
    return () => {
      bodyElement.style.overflow = "auto";
    };
  }, [open]);

  useEffect(() => {
    dispatch(getNotificationsCount())
  }, [notificationFlag, notifications, totalRecords, open])

  useEffect(() => {
    if (notificationFlag) {
      dispatch(actions.toggleNewNotificationFlag(false));
      dispatch(actions.fetchNotifications({ page: 1, per_page: 10 }, true));
    }
  }, [notificationFlag, totalRecords, notifications])

  useEffect(() => {
    if (!notifications?.length) return;
    if (notifications?.length >= totalRecords) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [notifications, totalRecords, notificationFlag]);

  const handleNotificationRedirection = (notification) => {
    setOpen(false);
    if (!notification) return;
    dispatch(markNotificationsRead(notification?.id, { is_read: 1 }));
    let navigationURL = "";
    let shouldReload = false;
    if (notification?.version === 1) {
      switch (notification?.type) {
        case "chat":
          getUser(notification?.data?.user_id).then((response) => {
            const userData = response?.data;
            const userId = userData?.id;
            const firstName = userData?.first_name;
            const lastName = userData?.last_name;
            const fullName = `${firstName} ${lastName}`;
            navigationURL = `/my-profile/chat/${notification?.data?.reservation_id}/${encodeURIComponent(fullName)}/${userId}`;
            history?.push(navigationURL, { user: userData });
          }).catch((error) => {
            toastHandler(error, "error");
          });
          break;
        case "stand_approved":
        case "stand_rejected":
          dispatch(actions.fetchStandDetail(notification?.data?.stand_id));
          navigationURL = `/stand/${notification?.data?.stand_id}`;
          break;
        case "stand_reservation":
        case "stand_reservation_cancel":
          dispatch(getReservationDetail(notification?.data?.reservation_id))
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}`;
          break;
        case "reservation_start":
          setEditModal(true);
          break;
        case "amount_transfer":
          navigationURL = "/my-profile/payments";
          break;
        case "ticket_updated":
          dispatch(getTicketDetails(notification?.data?.ticket_id));
          navigationURL = `/my-profile/customer-portal/ticket/${notification?.data?.ticket_id}`;
          break;
        case "new_comment":
          dispatch(getTicketDetails(notification?.data?.ticket_id));
          navigationURL = `/my-profile/customer-portal/ticket/${notification?.data?.ticket_id}?from=new-comment`;
          break;
        case "reservation_complete":
        case "reservation_review":
        case "review_updated":
        case "review_challenged":
        case "review_stand":
        case "review_hunter":
          navigationURL = `/review-reconsider/${notification?.data?.reservation_id}`;
          break;
        case "stand_followed":
          dispatch(actions.clearStandDetailsFectched());
          dispatch(actions.fetchStandDetail(notification?.data?.stand_id));
          dispatch(actions.fetchStandFollower(notification?.data?.stand_id));
          navigationURL = `/stand/${notification?.data?.stand_id}?from=notification-followers`;
          break;
        case "stand_new_post":
          dispatch(actions.clearStandDetailsFectched());
          dispatch(actions.fetchStandDetail(notification?.data?.stand_id));
          dispatch(actions.fetchStandPosts(notification?.data?.stand_id, { page: 1, per_page: 4 }, true));
          navigationURL = `/stand/${notification?.data?.stand_id}?from=notification-blog`;
          break;
        case "blog_post_request":
          dispatch(actions.clearStandDetailsFectched());
          dispatch(actions.clearPostDetailsFectched());
          dispatch(actions.fetchStandDetail(notification?.data?.stand_id));
          dispatch(actions.fetchStandPosts(notification?.data?.stand_id, { page: 1, per_page: 4 }, true));
          dispatch(actions.getStandPostById(notification?.data?.blog_post_id));
          navigationURL = `/stand/${notification?.data?.stand_id}?from=notification-blog-confirmation`;
          break;
        case "fund_transfer_request":
        case "fund_transfer_handle":
          dispatch(getFundsNotification(notification?.data?.fund_transfer_id));
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}`;
          break;
        case "revoke_hunter_access":
          dispatch(getAdditionalHunterInvitationDetails(notification?.data?.invitation_id));
          navigationURL = `/my-profile/my-reservations?activeTab=current`;
          break;
        case "accepted_hunter_invitation":
          dispatch(getAdditionalHunterInvitationDetails(notification?.data?.invitation_id));
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}/additional-hunter`;
          dispatch(changeAdditionalHunterTab("Joined"));
          break;
        case "rejected_hunter_invitation":
          dispatch(getAdditionalHunterInvitationDetails(notification?.data?.invitation_id));
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}/additional-hunter`;
          dispatch(changeAdditionalHunterTab("Invitations"));
          break;
        case "additional_hunter_invitation":
          navigationURL = `/my-profile/my-reservations?invitation_code=${notification?.data?.invitation_code}`;
          break;
        case "refund_on_cancellation":
        case "refund_by_admin":
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}`
          break;
        case "new_reservation_request":
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}`;
          dispatch(getReservationDetail(notification?.data?.reservation_id))
          break;
        case "reservation_rejected":
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}`;
          dispatch(getReservationDetail(notification?.data?.reservation_id));
          dispatch(showRejectionReasonModal(true));
          dispatch(showRejectionReason(true));
          break;
        case "booking_unavailability_reminder_minutes":
        case "booking_unavailability_reminder_days":
          dispatch(actions.fetchStandDetail(notification?.data?.stand_id));
          navigationURL = `/stand/${notification?.data?.stand_id}?from=notification-unavailability`;
          break;
        case "stand_review":
          navigationURL = `/my-profile/reviews`;
          break;
        default:
          navigationURL = "";
          shouldReload = true;
      }
    } else {
      switch (notification?.type) {
        case "chat":
          navigationURL = `/my-profile/chat/${notification?.data?.stand_reservation_id}/${encodeURIComponent(notification?.data?.user?.first_name + ' ' + notification?.data?.user?.last_name)}/${notification?.data?.user?.id}`;
          break;
        case "stand_approved":
        case "stand_rejected":
          dispatch(actions.fetchStandDetail(notification?.data?.id));
          navigationURL = `/stand/${notification?.data?.id}`;
          break;
        case "stand_reservation":
        case "stand_reservation_cancel":
          dispatch(getReservationDetail(notification?.data?.id))
          navigationURL = `/my-profile/reservation-details/${notification?.data?.id}`;
          break;
        case "reservation_start":
          setEditModal(true);
          break;
        case "amount_transfer":
          navigationURL = "/my-profile/payments";
          break;
        case "ticket_updated":
          dispatch(getTicketDetails(notification?.data?.id));
          navigationURL = `/my-profile/customer-portal/ticket/${notification?.data?.id}`;
          break;
        case "new_comment":
          dispatch(getTicketDetails(notification?.data?.id));
          navigationURL = `/my-profile/customer-portal/ticket/${notification?.data?.id}?from=new-comment`;
          break;
        case "reservation_complete":
        case "review_stand":
        case "review_hunter":
          navigationURL = `/review-reconsider/${notification?.data?.id}`;
          break;
        case "reservation_review":
        case "review_updated":
        case "review_challenged":
          navigationURL = `/review-reconsider/${notification?.data?.reservation?.id}`;
          break;
        case "stand_followed":
          dispatch(actions.clearStandDetailsFectched());
          dispatch(actions.fetchStandDetail(notification?.data?.stand?.id));
          dispatch(actions.fetchStandFollower(notification?.data?.stand?.id));
          navigationURL = `/stand/${notification?.data?.stand?.id}?from=notification-followers`;
          break;
        case "stand_new_post":
          dispatch(actions.clearStandDetailsFectched());
          dispatch(actions.fetchStandDetail(notification?.data?.stand?.id));
          dispatch(actions.fetchStandPosts(notification?.data?.stand?.id, { page: 1, per_page: 4 }, true));
          navigationURL = `/stand/${notification?.data?.stand?.id}?from=notification-blog`;
          break;
        case "fund_transfer_request":
        case "fund_transfer_handle":
          dispatch(getFundsNotification(notification?.data?.id));
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation_id}`;
          break;
        case "revoke_hunter_access":
          dispatch(getAdditionalHunterInvitationDetails(notification?.data?.id));
          navigationURL = `/my-profile/my-reservations?activeTab=current`;
          break;
        case "accepted_hunter_invitation":
          dispatch(getAdditionalHunterInvitationDetails(notification?.data?.id));
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation?.id}/additional-hunter`;
          dispatch(changeAdditionalHunterTab("Joined"));
          break;
        case "rejected_hunter_invitation":
          dispatch(getAdditionalHunterInvitationDetails(notification?.data?.id));
          navigationURL = `/my-profile/reservation-details/${notification?.data?.reservation?.id}/additional-hunter`;
          dispatch(changeAdditionalHunterTab("Invitations"));
          break;
        case "refund_on_cancellation":
        case "refund_by_admin":
          navigationURL = `/my-profile/reservation-details/${notification?.data?.id}`;
          break;
        case "new_reservation_request":
          navigationURL = `/my-profile/reservation-details/${notification?.data?.id}`;
          dispatch(getReservationDetail(notification?.data?.id));
          break;
        case "reservation_rejected":
          navigationURL = `/my-profile/reservation-details/${notification?.data?.id}`;
          dispatch(getReservationDetail(notification?.data?.id));
          dispatch(showRejectionReasonModal(true));
          dispatch(showRejectionReason(true));
          break;
        case "booking_unavailability_reminder_minutes":
        case "booking_unavailability_reminder_days":
          dispatch(actions.fetchStandDetail(notification?.data?.id));
          navigationURL = `/stand/${notification?.data?.id}?from=notification-unavailability`;
          break;
        case "stand_review":
          navigationURL = `/my-profile/reviews`;
          break;
        default:
          navigationURL = "";
          shouldReload = true;
      }
    }
    document.body.click();
    if (navigationURL) {
      if (notification?.type?.includes("fund_transfer_")) {
        dispatch(handleNotificationClick(!clickedOnNotification));
        history?.push(navigationURL);
      }
      if (notification?.type === "chat" && notification?.version === 0) {
        history?.push(navigationURL, { user: notification?.data?.user });
      } else {
        history?.push(navigationURL);
      }
    } else if (shouldReload) {
      window.location.reload();
    }
  };

  const getImage = (images) => {
    if (images?.length > 0) {
      return images[0]?.picture;
    } else {
      return logo;
    }
  }

  const onSuccess = () => {
    setLoading(false);
    setOpen(false); // Close the overlay
    dispatch(actions.fetchNotifications({ page, per_page: perPage }, true));
  }

  const handleMarkAllRead = () => {
    setLoading(true);
    dispatch(markAllNotificationsRead(onSuccess));
  };

  const getNotificationImage = (notification) => {
    if (!notification) return;
    const data = notification?.data;
    if (notification?.version === 1) {
      if (notification?.type === "amount_transfer") {
        return logo;
      } else {
        return data?.data_image || User;
      }
    } else {
      switch (notification?.type) {
        case "chat":
          return data?.user?.image || User;
        case "stand_rejected":
        case "stand_approved":
          return data?.image || User;
        case "stand_reservation":
          return data?.user?.image || User;
        case "stand_reservation_cancel":
          return data.user?.image || User;
        case "amount_transfer":
          return logo || User;
        case "reservation_start":
        case "reservation_complete":
          return data?.stand_detail?.image || User;
        case "general":
          return logo || User;
        case "stand_review":
        case "review_updated":
        case "review_challenged":
        case "reservation_review":
          return getImage(data?.reservation?.stand_detail?.pictures);
        case "stand_followed":
          return data?.user?.image || User;
        case "stand_new_post":
          return data?.stand?.image || User;
        case "fund_transfer_request":
          return data?.user?.image || User;
        case "fund_transfer_handle":
          return data?.request_from?.image || User;
        case "revoke_hunter_access":
          return data?.reservation?.user?.image || User;
        case "rejected_hunter_invitation":
        case "accepted_hunter_invitation":
          return data?.user?.image || User;
        case "ticket_updated":
        case "new_comment":
          return data?.user?.image || User
        case "refund_on_cancellation":
        case "refund_by_admin":
          return data?.stand_detail?.image
        case "review_stand":
        case "review_hunter":
          return data?.stand_detail?.image || User;
        case "booking_unavailability_reminder_minutes":
        case "booking_unavailability_reminder_days":
          return data?.image || User;
        case "new_reservation_request":
          return data?.stand_detail?.image || User;
        case "reservation_rejected":
          return data?.user?.image || User;
        default:
          return User;
      }
    }
  };
  return (
    <>
      <OverlayTrigger
        trigger="click"
        key={"bottom-end"}
        placement={"bottom-end"}
        onToggle={handleToggleNotifications}
        rootClose
        show = {open}
        overlay={
          <Popover
            className="notification-popover"
            id={`popover-positioned-bottom-end`}
          >
            <Popover.Body className="p-0">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <Card.Title as="h5">Notifications</Card.Title>
                  { notificationsCount > 0 && (
                      <button className="btn btn-primary text-white" onClick={handleMarkAllRead}>
                        {loading ? <Spinner/> : "Mark all as read"}
                      </button>
                  )}
                </div>
              </Card.Body>
              <InfiniteScroll
                dataLength={notifications?.length}
                next={fetchMoreNotifications}
                hasMore={hasMore}
                loader={
                  <div className="d-flex justify-content-center">
                    <Spinner />
                  </div>
                }
                height={500}
                className="scroller"
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>
                      {notifications?.length === 0
                        ? "No notifications yet"
                        : ""}
                    </b>
                  </p>
                }
              >
                <ListGroup className="list-group-flush">
                  {notifications.length
                    ? notifications.map((notification, index) => (
                      <ListGroupItem
                        key={index}
                        className={`d-flex align-items-center justify-content-between cursor-pointer ${notification?.is_read ? "not-readed" : "readed"}`}
                        onClick={() =>
                          handleNotificationRedirection(notification)
                        }
                      >
                        <div className="d-flex align-items-center notification-txt">
                          <img
                            className="me-3 notification-image"
                            src={getNotificationImage(notification)}
                            onError={(e) => (e.target.src = User)}
                            alt=""
                          />{" "}
                          <div className="twoline-text">
                            {notification?.message}
                          </div>
                        </div>
                        <div className="fs-12 notification-time text-tertiary">
                          {moment(notification?.date)
                            .add(moment().utcOffset(), "minutes")
                            .fromNow()}
                        </div>
                      </ListGroupItem>
                    ))
                    : null}
                </ListGroup>
              </InfiniteScroll>
            </Popover.Body>
          </Popover>
        }
      >
        <div style={{
          display: "flex",
          position: "relative"
        }}>
          <i className={`bi text-fifth fs-22 me-4 bi-bell-fill cursor-pointer`} />
          {
            notificationsCount > 0 &&
            <span className={notificationsCount <= 5 ? "badge-5" :
              notificationsCount <= 10 ? "badge-10" : notificationsCount <= 100 ?
                "badge-100" : "badge-1000"}
            >
              {notificationsCount}
            </span>
          }
        </div>
      </OverlayTrigger>
      <EditStandModal
        show={showEditModal}
        onHide={() => {
          setEditModal(false);
        }}
      />
    </>
  );
}