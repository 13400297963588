import React from "react";
import Cloudy from "../../../../assets/icons/cloudy.svg";
import Humidity from "../../../../assets/icons/humidity.svg";
import Moon from "../../../../assets/icons/moon.svg";
import Sun from "../../../../assets/icons/sun.svg";
import Wind from "../../../../assets/icons/wind.svg";
import firstQuarterMoonPhase from "../../../../assets/icons/first-quarter-moon-phase.png";
import lastQuarterMoonPhase from "../../../../assets/icons/last-quarter-moon-phase.png";
import fullMoonPhase from "../../../../assets/icons/full-moon-phase.png";
import NewMoonPhase from "../../../../assets/icons/new-moon-phase.png";
import "./style.scss";
import moment from "moment";

export default function CurrentWeather({ weatherReport }) {

  const getMoonPhaseIcon = () => {
    const moonPhase = weatherReport.daily[0].moon_phase;
    let iconToSet = "";
    
    if (moonPhase == 0) {
        iconToSet = NewMoonPhase;
    } else if (moonPhase > 0 && moonPhase <= 0.25) {
        iconToSet = firstQuarterMoonPhase;
    } else if (moonPhase > 0.25 && moonPhase <= 0.5) {
        iconToSet = fullMoonPhase;
    } else if (moonPhase > 0.5 && moonPhase <= 0.75) {
        iconToSet = lastQuarterMoonPhase;
    } else if (moonPhase > 0.75 && moonPhase <= 1) {
        iconToSet = NewMoonPhase;
    } else {
        iconToSet = "";
    }
    
    return iconToSet;
  };

  return weatherReport && weatherReport?.current ? (
    <div className="current-weather">
      <p className="text-seventh fs-18 mb-2">Today weather</p>
      <div className="current-temp">
        <div>
          <p className="deg mb-1">
            {weatherReport.current.temp && Math.round(weatherReport.current.temp)}
            <sup>o</sup>
          </p>
          <p className="mb-0 text-secondary text-left">Temp</p>
        </div>
        <div>
          <img src={Sun} alt="" />
          <div className="d-flex">
            <div className="me-3">
              <p className="mb-0 fw-normal">
                <i className="bi bi-arrow-bar-up me-2 "></i>{" "}
                {moment.unix(weatherReport.current.sunrise).format("hh:mm A")}
              </p>
            </div>
            <div>
              <p className="mb-0 fw-normal">
                <i className="bi bi-arrow-bar-down me-2"></i>
                {moment.unix(weatherReport.current.sunset).format("hh:mm A")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="stand-list mt-4">
        <div className="border-btm pt-md-3 pt-0">
          <p className="">
            <img src={Cloudy} className="me-2" alt="" />
            Feels like
          </p>
          <p className="fw-bold mb-0">
            {weatherReport.current.feels_like && Math.round(weatherReport.current.feels_like)}
            <sup>o</sup>
          </p>
        </div>
        <div className="border-btm pt-md-3 pt-0">
          <p>
            <img src={Wind} className="me-2" alt="" />
            Wind
          </p>
          <p className="fw-bold">
            {Math.round(weatherReport.current.wind_speed)} mph
          </p>
        </div>
        <div className="border-btm pt-md-3 pt-0">
          <p>
            <img src={Humidity} className="me-2" alt="" />
            Humidity
          </p>
          <p className="fw-bold">{weatherReport.current.humidity}%</p>
        </div>
        <div className="border-btm pt-md-3 pt-0 moon-phase">
          <p className="">
            <img src={Moon} className="me-2" alt="" />
            Moon phase
          </p>
          <img src={getMoonPhaseIcon()} className="me-2" alt="Moon phase" />
        </div>
      </div>
    </div>
  ) : (
    "N/A"
  );
}
