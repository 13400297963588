import React from "react";
import Tutorials from "../tutorials";
import "../blogList/list/style.scss";
import "./style.scss";


export default function BlogList() {
  return (
    <div className="blog-page mt-5">
      <div className="inner-container">
        <Tutorials />
      </div>
    </div>
  );
}
