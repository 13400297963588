import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  error: null,
  actionsLoading: false,
  profilePictureUploading: false,
  listLoading: false,
  user: null,
  token: localStorage.token,
  isAuthenticated: false,
  callBackUrl: null,
  isExpired: false,
  expiryMessage: "",
  verificationUrl: null,
  userToken: null,
  fetchingVerificationUrl: false,
  codeError: null
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.searched = false;
      } else {
        state.actionsLoading = true;
        state.searched = false;
      }
    },
    startUploadProfilePictureCall: (state, action) => {
      state.profilePictureUploading = true;
    },
    userSignedUp: (state, action) => {
      state.error = null;
      state.isAuthenticated = false;
      state.actionsLoading = false;
      state.listLoading = false;
    },

    userLoggedIn: (state, action) => {
      const { token, user } = action.payload;
      localStorage.setItem("token", token);
      state.error = null;
      state.actionsLoading = false;
      state.token = token;
      state.user = user;
      state.isAuthenticated = true;
    },

    userLoaded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.data;
    },

    varifyEmail: (state) => {
      state.actionsLoading = false;
    },

    turnOfLoading: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    userLoggedOut: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    },
    userProfileUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.data;
    },
    userProfileImageUploaded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.profilePictureUploading = false;
      state.isAuthenticated = true;
      state.user.image = action.payload.data;
    },
    userProfileImageDeleted: (state) => {
      state.error = null;
      state.actionsLoading = false;
      state.isAuthenticated = true;
      state.user.image = "";
    },
    userPasswordChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    callBackUrlSet: (state, action) => {
      state.callBackUrl = action.payload.url;
    },
    linkExpiry: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.isExpired = !action.payload.status;
      state.expiryMessage = action.payload.message;
    },
    requestUserVerificationToken: (state, action) => {
      state.verificationUrl = null;
      state.userToken = null;
      state.fetchingVerificationUrl = true;
      state.codeError = null;
    },
    userVerificationTokenFetched: (state, action) => {
      state.verificationUrl = action.payload.verifyUrl;
      state.userToken = action.payload.userToken
      state.fetchingVerificationUrl = false;
      state.codeError = null;
    },
    userVerificationTokenError: (state, action) => {
      state.verificationUrl = null;
      state.userToken = null;
      state.fetchingVerificationUrl = false;
      state.codeError = action.payload;
    },
    clearVerificationToken: (state, action) => {
      state.verificationUrl = null;
      state.userToken = null;
      state.fetchingVerificationUrl = false;
      state.codeError = null;
    },
    userAuthenticated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
