import React, { useRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { useFormik } from "formik";
import parse from "html-react-parser";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import crossImg from "../../../assets/icons/cross-black.png";
import "./landownerAgreementModal.scss";
import toastHandler from "../toastHandler/toastHandler";
import { dataURLtoFile } from "utils/helpers";
import Spinner from "components/UI/Spinner.js";
import { getDocument } from "modules/dashboard/_redux/dashboard/dashboardActions";
import { standInvitationAction } from "modules/dashboard/_redux/stand/standActions";
import { useHistory } from "react-router-dom";

export function LandownerAgreementModal({
    show,
    setShow,
    setShowInvitationModal,
    stand,
    type,
    reload,
    invitationCode
}) {
    const [loading, setLoading] = useState(false);
    const signatureRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const { user, document } = useSelector(
        (state) => ({
            user: state.auth.user,
            document: state.dashboard.document,
        }), shallowEqual);

    useEffect(() => {
        dispatch(getDocument({ type: "lease document" }))
    }, [])

    const validationSchema = Yup.object({
        signature: Yup.string().required("Please sign the document"),
        checkedTerms: Yup.boolean().oneOf([true], "Please accept terms & conditions")
    });
    const initialValues = {
        signature: "",
        checkedTerms: false
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true)
            const formData = new FormData();
            formData.append("invitation_code", invitationCode);
            formData.append("type", type);
            var file = dataURLtoFile(
                signatureRef.current.getTrimmedCanvas().toDataURL("image/png"),
                "image.png"
            );
            formData.append("signature", file)
            dispatch(standInvitationAction(formData, onSuccess, onError))
        },
    });

    const onSuccess = (message) => {
        setLoading(false)
        setShow(false)
        toastHandler(message, "success")
        setShowInvitationModal(false)
        history.replace("/my-profile/my-stands?activeTab=listing");
        reload()
    }
    const onError = () => {
        setLoading(false)
    }

    const handleSignatureClear = () => {
        signatureRef.current.clear();
        formik.setFieldValue("signature", "");
    };

    const today = new Date();

    return (
        <div>
            <Modal
                className="agreement-modal"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                size="xl"
            >
                <span className="close-btn" onClick={() => setShow(false)}>
                    <img src={crossImg} alt="" />
                </span>
                <Modal.Body>
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-12">
                                {parse(document?.body.replace(/:date/g, today.toLocaleDateString())
                                    .replace(/:state/g, stand?.state?.name || "N/A")
                                    .replace(/:county/g, stand?.county || "N/A")
                                    .replace(/:property_identification_name/g, stand?.title || "N/A")
                                    .replace(/:acres/g, stand?.acres || "N/A")
                                    .replace(/_:signup_date/g, new Date(user?.created_at).toLocaleDateString() || "N/A")
                                    .replace(/:first_name/g, user?.first_name || "N/A")
                                    .replace(/:last_name/g, user?.last_name || "N/A")
                                    || "")}
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={formik?.values?.checkedTerms}
                                    id="flexCheckDefault"
                                    onChange={(e) => formik.setFieldValue("checkedTerms", e?.target?.checked)} />
                                <label className="form-check-label" for="flexCheckDefault">
                                    I have read and accept the <a href="/terms" target="_blank">terms & conditions</a>
                                </label>
                                <Form.Control.Feedback type="d-block invalid" className="display-inline-block">
                                    {formik?.touched?.checkedTerms && formik?.errors?.checkedTerms ? formik.errors.checkedTerms : ""}
                                </Form.Control.Feedback>
                            </div>
                            <div className="section signature-sec">
                                <span className="d-flex signature-label mb-3">
                                    Please put your digital Signature Below.
                                </span>
                                <div className="signature-main">
                                    <SignatureCanvas
                                        penColor="black"
                                        canvasProps={{
                                            width: 500,
                                            height: 120,
                                            className: "sigCanvas",
                                        }}
                                        ref={signatureRef}
                                        onEnd={() => {
                                            formik.setFieldValue(
                                                "signature",
                                                signatureRef.current
                                                    .getTrimmedCanvas()
                                                    .toDataURL("image/png")
                                            )
                                        }}
                                    />
                                </div>
                                <Form.Control.Feedback type="d-block invalid" className="sign_error">
                                    {formik?.touched?.signature && formik?.errors?.signature ? formik.errors.signature : ""}
                                </Form.Control.Feedback>
                                <div className="display-flex">
                                    <button className="btn btn-outline-secondary me-sm-3 cancel"
                                        onClick={handleSignatureClear}
                                        type="button"
                                    >
                                        CLEAR SIGNATURE
                                    </button>
                                    <button className="btn btn-primary text-white" type="submit" disabled={loading}>
                                        {loading ? <Spinner /> : "proceed"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
