import React, { useEffect, useRef, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as actions from "../../_redux/landingPageActions";
import Description from "../description/index.js";
import LandBookingItems from "../landBookingItems/index.js";
import LandInfo from "../landinfo/index.js";
import Locations from "../locations/index.js";
import MapLocation from "../map/index.js";
import Reviews from "../reviews/index.js";
import Schedule from "../schedule/index.js";
import ThumbnailCarusal from "../thumbnailCarusal/index.js";
import TopBar from "../topBar.js/index.js";
import Trophies from "../trophies/index.js";
import VoucherCard from "../TabVoucherCard/index.js";
import CustomSpinner from "components/layout/Loader/Spinner";
import StandBlog from "../StandBlog";
import Followers from "../followers";
import config from "config";
import "./style.scss";
import { Modal } from "react-bootstrap";
import { Swiper,SwiperSlide } from "swiper/react";
import { BlogConfirmationModal } from "components/UI/modals/blog-confirmation-modal/BlogConfirmationModal";
import moment from "moment";
import playIcon from "assets/icons/play-icon.svg";
import trashIcon from "assets/images/trash-stand.svg";
import ReactPlayer from "react-player";
import BlogSpinner from "components/UI/blogSpinner";

export default function StandDetail({ id }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const {
    standDetails,
    weatherReport,
    relatedLands,
    user,
    actionsLoading,
    postDetails
  } = useSelector(
    (state) => ({
      standDetails: state.landingPage.standDetails,
      weatherReport: state.landingPage.weatherReport,
      relatedLands: state.landingPage.relatedLands,
      actionsLoading: state.landingPage.actionsLoading,
      postDetails: state.landingPage.postDetails,
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [key, setKey] = useState("");
  const [value, setValue] = useState(0);
  const [show, setShow] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showBlogRequestModal, setShowBlogRequestModal] = useState(false);
  const [blogRequestMessage, setBlogRequestMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const onHideHandler = () => {
    setShow(false);
  };

  const videoRefs = useRef({});

  // To pause the video playing on the previous slide whenever user navigates to the next slide
  const onSlideChange = () => {
    Object.values(videoRefs.current).forEach(videoRef => {
      if (videoRef && videoRef.getInternalPlayer) {
        const player = videoRef.getInternalPlayer();
        if (player && typeof player.pause === 'function') {
          player.pause();
        }
      }
    });
  };
  
  useEffect(() => {
    if (key === "reviews" || key === "followers" || key === "blog" || key === "trophies") {
      window.scroll({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [key, id]);

  useEffect(() => {
    if (!id) return;
    dispatch(actions.fetchStandDetail(id));
    return async () => {
      await dispatch(actions.clearStandDetailsFectched());
    };
  }, [dispatch, id]);

  const getKey = () => {
    if (query.get("from") === "stand-to-review") return "reviews"
    if (query.get("from") === "notification-followers") return "followers";
    if (query.get("from") === "notification-blog" || query.get("from") === "notification-blog-confirmation") return "blog";
    if (user?.id == standDetails?.user?.id) {
      return "schedule";
    } else return "description";
  };

  const formatText = (text) => {
    const urlPattern = /((https?|ftp):\/\/[^\s<]+)/g;
    const formattedText = text
    ?.replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`)
    ?.replace(/\r\n/g, "<br/>")
    ?.replace(/\n/g, "<br/>");
    return { __html: formattedText };
  };

  useEffect(() => {
    setKey(getKey());
    if (
      !standDetails ||
      !standDetails?.property_address_longitude ||
      !standDetails?.property_address_latitude
    )
      return;
    let data = {
      appid: config.WEAHTER_APP_ID,
      units: "imperial",
      lat: standDetails?.property_address_latitude,
      lon: standDetails?.property_address_longitude,
    };
    dispatch(actions.fetchWeatherReport(data));
  }, [standDetails]);


  useEffect(() => {
    if (location?.state?.fromReservation === true) setKey("location");
  }, [location]);

  // setting the state to true if in URL there is "notification-blog-confirmation"
  useEffect(() => {
    if (location?.search?.includes("notification-blog-confirmation")) {
      setIsLoading(true);
      setShow(true);
      setTimeout(() => {
        history?.replace(`/stand/${id}`);
        setIsLoading(false);
      }, 1000);
    }
  }, [location]);

  return (
    <>
      {standDetails?.status === "rejected" ? (
        <TopBar land={standDetails} />
      ) : (
        <TopBar />
      )}

      <div className="inner-container">
        {actionsLoading  ? (
          <CustomSpinner />
        ) : (
          <div className="row">
            <div className="col-lg-8 pe-lg-5">
              <ThumbnailCarusal pictures={standDetails?.pictures} />
            </div>

            <div className="col-lg-4">
              <LandInfo land={standDetails} setKey={setKey} />
            </div>
          </div>
        )}

        <div className="row font-roboto mt-5 pt-4 mb-5">
          {standDetails ? <div className="">
            <div className="tab">
              <Tabs
                transition
                defaultActiveKey={getKey()}
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                }}
              >
                {standDetails?.user?.id === (user && user?.id) ? (
                  <Tab eventKey="schedule" title="Schedule">
                    <Schedule standId={id} value={value} setValue={setValue} />
                  </Tab>
                ) : null}
                <Tab eventKey="description" title="Description">
                  <Description
                    key={standDetails?.id}
                    land={standDetails}
                    weatherReport={weatherReport}
                  />
                </Tab>
                {location?.state?.fromReservation === true ? (
                  <Tab eventKey="location" title="Go Hunt!">
                    <Locations land={standDetails} />
                  </Tab>
                ) : null}
                <Tab eventKey="trophies" title="Trophies">
                  <Trophies standId={id} />
                </Tab>
                <Tab eventKey="reviews" title="Reviews">
                  <Reviews standId={id} />
                </Tab>
                {(user != null && standDetails?.user?.id) === (user && user?.id) ? (
                  <Tab eventKey="Vouchers" title="Vouchers">
                    <VoucherCard 
                      vouchers={standDetails?.stand_vouchers}
                      standId={standDetails?.id}
                      land={standDetails}
                    />
                  </Tab>
                ) : null}
                <Tab eventKey="blog" title="Blog">
                  <StandBlog
                    key={standDetails?.id}
                    standId={standDetails?.id}
                    land={standDetails}
                  />
                </Tab>
                {user != null && standDetails?.user?.id === user?.id && (
                  <Tab eventKey="followers" title="Followers">
                    <Followers standId={id} key={standDetails?.id} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div> : null
          }
          {location?.state?.fromReservation === true && key === "location" ? (
            <div className="col-lg-4 pt-lg-5 location">
              <MapLocation standDetails={standDetails} />
            </div>
          ) : null}
        </div>

        {relatedLands.length > 0 && key === "location" && (
          <div className="related-land">
            <h4 className="mt-5 mb-3 fs-28">Related Listing</h4>
            <LandBookingItems lands={relatedLands} />
          </div>
        )}

        <Modal
          show={show}
          onHide={onHideHandler}
          size={postDetails && postDetails ? "lg" : "md"}
          aria-labelledby="blog-request-dialog"
          centered
          className="blog-request-dialog"
          backdrop="static"
          scrollable
        >
          <Modal.Header closeButton />
          {actionsLoading || isLoading ? (
            <BlogSpinner /> // introduced new spinner to tackle the UI glitch when modal was opened
            ) : (
              <>
                {postDetails && postDetails ? (
                  <Modal.Body>
                    <div className="blog-topbar">
                      <div className="date">
                        <label>{moment(postDetails?.created_at).format("DD")}</label>
                        {moment(postDetails?.created_at).format("MMM YY")}
                      </div>
                      <div>
                        <h3 className="blog-title">{postDetails?.title}</h3>
                        <span className={`blog-status ${postDetails?.status === "approved" ? "approved" : postDetails?.status === "rejected" ? "rejected" : "pending"}`}>{postDetails?.status}</span>
                      </div>
                    </div>
      
                    {/* Displaying the media in the slider */}
                    {
                      (postDetails?.media && postDetails?.media?.length > 0) && (
                        <div className="blog-media-slider-wrapper">
                          <div className="thumbslider">
                            <Swiper
                              observer={true}
                              observerParents={true}
                              navigation={true}
                              thumbs={{ swiper: thumbsSwiper }}
                              key={Math.random(8)}
                              className="stand-slider"
                              pagination={{
                                clickable: true,
                              }}
                              onSlideChange={onSlideChange}
                            >
                              {postDetails?.media &&
                                postDetails?.media?.map((mediaItem, index) => (
                                  <SwiperSlide key={index}>
                                    {mediaItem?.video === null ? (
                                      <img
                                        src={mediaItem?.image}
                                        alt=""
                                        onError={(e) => (e.target.src)}
                                      />
                                    ) : (
                                      <ReactPlayer
                                        ref={(el) => { videoRefs.current[`video-${postDetails.id}-${index}`] = el }}
                                        url={mediaItem?.video}
                                        controls={true}
                                        width={"100%"}
                                        height={"100%"}
                                        playIcon={<img src={playIcon} alt="play" style={{ width: 'auto', height: 'auto' }} />}
                                        light={encodeURI(mediaItem?.image)}
                                        playsinline={true}
                                        playing={true}
                                      />
                                    )}
                                  </SwiperSlide>
                                ))
                              }
                            </Swiper>
                          </div>
                        </div>
                      )
                    }
      
                    <div className="blog-desc">
                      <p dangerouslySetInnerHTML={formatText(postDetails?.description)} />
                    </div>
      
                    {/* If blog's status is pending then show the APPROVE/REJECT buttons */}
                    {postDetails?.status === "pending" && (
                      <div className="blog-request-actions">
                        <button className="blog-request-btn reject" onClick={() => {
                          setShowBlogRequestModal(true);
                          setBlogRequestMessage("Are you sure you want to reject this blog post?");
                          onHideHandler();
                        }}>REJECT</button>
                        <button className="blog-request-btn approve" onClick={() => {
                          setShowBlogRequestModal(true);
                          setBlogRequestMessage("Are you sure you want to approve this blog post?");
                          onHideHandler();
                        }}>APPROVE</button>
                      </div>
                    )}
                  </Modal.Body>) : (
                  <Modal.Body>
                    <div className="deleted-blog-body">
                      <img src={trashIcon} alt="trash" />
                      <p className="deleted-blog-title">Post Deleted</p>
                      <span className="deleted-blog-body">
                        This blog post has been deleted
                      </span>
                    </div>
                  </Modal.Body>
                )}
              </>
          )}
        </Modal>
        <BlogConfirmationModal
          postId={postDetails?.id}
          show={showBlogRequestModal}
          onHide={() => {setShowBlogRequestModal(false);}}
          message={blogRequestMessage}
        />
      </div>
    </>
  );
}
