import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditStandModal } from "modules/dashboard/pages/ownerDashboard/mystands/standItem/editModal/editModal";
import { addFollowStands } from "modules/dashboard/_redux/stand/standActions";
import Spinner from "components/UI/Spinner.js";
import { handleJuniorHunterModal } from "modules/dashboard/_redux/dashboard/dashboardActions";
import { getReservationDetail } from "modules/dashboard/_redux/reservations/reservationActions";
import UserCard from "../usercard";
import "./style.scss";


export default function LandInfo({ land, setKey }) {
  const [showEditModal, setEditModal] = useState(false);
  const [isFollowActive, setFollowActive] = useState(land?.is_following || false);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { auth, reservationDetail } = useSelector((state) => ({ auth: state.auth, reservationDetail: state.reservation.reservationDetail }), );
  const { user } = auth;
  const toastid = history.location.pathname;

  useEffect(() => {
    if (!history?.location?.state?.reservationId) return;
    if ((land?.user?.id !== user?.id && canContactLandowner()) && history?.location?.state?.reservationId) {
      dispatch(getReservationDetail(history?.location?.state?.reservationId));
    }
  }, []);

  const handleFollowToggle = async () => {
    if (localStorage.getItem("token")) {
      setLoading(true)
      await dispatch(addFollowStands(land?.id, toastid));
      setLoading(false)
      setFollowActive(() => !isFollowActive);
    } else {
      history.push("/auth/login")
    }
  }
  const canContactLandowner = () => {
    if (history?.location?.state) {
      if (history?.location?.state?.currentUser == "main_hunter" && !user?.is_junior) {
        return true;
      } else {
        return false
      }
    } else if (!user?.is_junior) {
      return true
    } else return false

  }

  useEffect(() => {
    if (query.get("from") === "notification-unavailability") {
      setTimeout(() => {
        setEditModal(true);
      }, 800);
    }
  }, []);

  useEffect(() => {document.title = 'Listing Details | iHunt'}, []);

  return (
    <>
      <div className="landinfo mb-5 mt-lg-0 mt-5">
          <div className={`follow-button ${isFollowActive ? "" : "unfollowed"}`}>
            <a className="btn btn-primary rounded-3 text-white" onClick={() => handleFollowToggle()}>{
              loading ? <Spinner /> : !isFollowActive ? <span><i className="bi bi-heart"></i> Follow</span> : <span><i className="bi bi-heart-fill"></i> UnFollow</span>
            } </a>
          </div> <h3>ID: {land?.id}</h3>
        <h2>{land?.title}</h2>
        <div className="rating mt-2 mb-2">
          <i
            className={`bi bi-star-fill me-1 ${land?.rating >= 1 ? "checked" : "text-fifth"
              }`}
          ></i>
          <i
            className={`bi bi-star-fill me-1 ${land?.rating >= 2 ? "checked" : "text-fifth"
              }`}
          ></i>
          <i
            className={`bi bi-star-fill me-1 ${land?.rating >= 3 ? "checked" : "text-fifth"
              }`}
          ></i>
          <i
            className={`bi bi-star-fill me-1 ${land?.rating >= 4 ? "checked" : "text-fifth"
              }`}
          ></i>
          <i
            className={`bi bi-star-fill me-1 ${land?.rating >= 5 ? "checked" : "text-fifth"
              }`}
          ></i>
          <span className="ms-2 rating-btn" onClick={() => setKey("reviews")}>{land?.rating}/5</span>
        </div>
        <div>
        </div>
        <div className="price-box mt-4">
          <div>
          </div>
          {
            history?.location?.state?.from !== true && (
              user && land?.user?.id == user?.id ?
                <button
                  className="btn btn-primary rounded-3 text-white"
                  onClick={() => setEditModal(true)}
                >
                  UPDATE
                </button> :
                land?.status != "approved" || land?.active ?
                  land?.next_available_booking ?
                    <div style={{
                      display: "inline-block"
                    }}>
                      {
                        user?.is_junior ?
                          <button
                            className="btn btn-primary rounded-3 text-white"
                            onClick={() => dispatch(handleJuniorHunterModal(true))}
                          >
                            CHECK AVAILABILITY
                          </button> :
                          <Link
                            to={land?.user?.id !== user?.id && `/reserve-stand/${land?.id}`}
                            className="btn btn-primary rounded-3 text-white"
                          >
                            CHECK AVAILABILITY
                          </Link>
                      }
                    </div> : <div className="no-reservation-available">
                      This Listing is fully booked
                    </div>
                  : null
            )
          }
        </div>
        {land?.user?.id !== user?.id &&
          canContactLandowner() ?
          <UserCard
            title="Owner"
            user={land?.user} subject={land?.title}
            reservationId={history?.location?.state?.reservationId}
            showInput={reservationDetail?.is_allowed_chat && reservationDetail?.is_allowed_chat}
            showMessage={!reservationDetail?.is_allowed_chat && !reservationDetail?.is_allowed_chat} /> : null}
      </div>
      <EditStandModal
        show={showEditModal}
        onHide={() => {
          setEditModal(false);
          history.replace(history.location.pathname);
        }}
      />
    </>
  );
}
