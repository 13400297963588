import { authSlice, callTypes } from "./authSlice";
import * as requestFromServer from "./authCrud";
import { getDeviceToken } from "firebase";

const { actions } = authSlice;

export const register = (data, setError, onSuccess) => (dispatch) => {
  data["first_name"] = data["first_name"].trim();
  data["last_name"] = data["last_name"].trim();
  data["email"] = data["email"].trim();

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .register(data)
    .then((response) => {
      const { data, token } = response;

      //checking if the email is verified or not
      dispatch(
        actions.userSignedUp({
          user: data,
          token: token,
        })
      );
      onSuccess();
      setError(null)
    })
    .catch((error) => {
      error.clientMessage = "Can't register";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      setError(error?.response?.data?.errors);
    });
};

export const login =
  (data, setError, onSuccess, showVerifyEmailModal) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .login(data)
      .then((response) => {
        const { data, token } = response;
        if (data?.email_verify) {
          dispatch(
            actions.userLoggedIn({
              user: data,
              token: token,
            })
          );
          onSuccess();
        } else {
          localStorage.setItem("token", token);
          showVerifyEmailModal();
          dispatch(actions.turnOfLoading());
        }
      })
      .catch((error) => {
        error.clientMessage = "Can't login";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        setError(error?.response?.data?.errors);
      });
  };

export const loadUser = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  requestFromServer
    .me()
    .then((response) => {
      if (response.data) {
        dispatch(
          actions.userLoaded({
            data: response.data,
          })
        );
        // get device token
        getDeviceToken();
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const forgotPassword =
  (data, onError, onSuccess) => async (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .forgotPassword(data)
      .then((response) => {
        if (response.data) {
          dispatch(actions.turnOfLoading());
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        onError(error?.response?.data?.errors);
      });
  };

export const resetPassword = (data, onError, onSuccess) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .resetPassword(data)
    .then((response) => {
      if (response.data) {
        dispatch(actions.turnOfLoading());
        onSuccess();
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      onError(error?.response?.data?.errors);
    });
};

export const logout = (onSuccess) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .logout()
    .then(() => {
      localStorage.removeItem('token');
      dispatch(actions.userLoggedOut());
      onSuccess();
    })
    .catch((error) => {
      error.clientMessage = "Can't login";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProfile = (data, onError, onSuccess) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUserProfile(data)
    .then((response) => {
      if (response.data) {
        dispatch(
          actions.userProfileUpdated({
            data: response.data,
          })
        );
        onSuccess(response.message);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Update User Profile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      onError(error?.response?.data?.errors);
    });
};

export const monthlyEmail = (data, onError, onSuccess) => (dispatch) => {
  return requestFromServer
    .monthlyEmail(data)
    .then((response) => {
      if (response.data) {
        onSuccess(response.message);
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      onError(error?.response?.data?.errors);
    });
}

export const uploadImage = (data, onError, onSuccess) => (dispatch) => {
  dispatch(actions.startUploadProfilePictureCall());
  return requestFromServer
    .uploadImage(data)
    .then((response) => {
      if (response.data) {
        dispatch(
          actions.userProfileImageUploaded({
            data: response.data.image,
          })
        );
        onSuccess(response.message, true);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't Update User Profile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      onError(error?.response?.data?.errors);
    });
};

export const deleteUserProfileImage =
  (data, onError, onSuccess) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.userProfileImageDeleted());
  };

export const changePassword = (data, onError, onSuccess) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changePassword(data)
    .then((response) => {
      dispatch(actions.userPasswordChanged());
      onSuccess(response.message);
    })
    .catch((error) => {
      error.clientMessage = "Can't Update Password";
      onError(error?.response?.data?.errors);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setCallbackUrl = (url) => (dispatch) => {
  dispatch(actions.callBackUrlSet({ url }));
};

export const reSendVerificationEmail =
  (onSuccess, onError) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .reSendVerificationEmail()
      .then((response) => {
        dispatch(actions.varifyEmail());
        onSuccess(response.message);
        localStorage.removeItem("token")
      })
      .catch((error) => {
        error.clientMessage = "Can't Update Password";
        onError(error?.response?.data?.errors);
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

export const checkLinkExpiry = (data) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getLinkExpiryStatus(data);
    dispatch(actions.linkExpiry(response));
  } catch (error) {
    dispatch(actions.linkExpiry(error.response.data));
  };
}

export const getUserVerificationUrl = (data) => async (dispatch) => {
  dispatch(actions.requestUserVerificationToken());
  try {
    const response = await requestFromServer.getVerifyUrl(data);
    const verifyUrl = response?.data?.verify_url?.split("&jwt_token=")[0];
    const userToken = response?.data?.verify_url?.split("&jwt_token=")[1]
    dispatch(actions.userVerificationTokenFetched(
      { verifyUrl: verifyUrl, userToken: userToken }));
  } catch (error) {
    dispatch(actions.userVerificationTokenError(error?.response?.data?.message));
  };
}

export const resetUserVerificationToken = () => (dispatch) => {
  dispatch(actions.clearVerificationToken())
}

export const authenticateUser = (data, onError, onSuccess) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .authenticateUser(data)
    .then((response) => {
      dispatch(actions.userAuthenticated());
      onSuccess(response.message);
    })
    .catch((error) => {
      error.clientMessage = "Can't Authenticate User";
      onError(error?.response?.data?.errors);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}