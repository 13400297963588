import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../hoc/mainLayout/layout";
import AuthPage from "../modules/auth/pages/AuthPage";
import DashboardPage from "../modules/dashboard/pages/DashboardPage";
import { ContentRoute } from "./ContentRoute";
import { HuntPackage } from "components/UI/hunterPackageComponent/huntPackage";
import PageNotFound from "components/UI/pageNotFound";
import Privacy from "modules/landingPage/pages/privacy";
import Faq from "modules/landingPage/pages/faq";
import Term from "modules/landingPage/pages/term";
import Contact from "modules/landingPage/pages/contact";
import About from "modules/landingPage/pages/about/about";
import BlogList from "modules/landingPage/pages/blogList";
import LandownerPage from "modules/landingPage/pages/landownerPage";
import HunterPage from "modules/landingPage/pages/hunterPage";
import { LandingPageUIProvider } from "modules/landingPage/pages/LandingPageUIContext";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchFAQs } from "modules/landingPage/_redux/landingPageActions";
import Downloads from "modules/landingPage/pages/downloads";
import Stand from "modules/landingPage/pages/standList";
import HunterAgreement from "components/UI/documents/hunterAgreementDocument";
import AgreementDocument from "components/UI/documents/agreementDocument";
import BlogDetail from "modules/landingPage/pages/blogDetail";
import ReviewsReconsider from "modules/dashboard/pages/reviews/ReconsiderReview/reconsiderReview";
import CreateReservation from "modules/dashboard/pages/reservations/create-reservation";
import LeasingApplication from "modules/landingPage/pages/leasingApp";
import WavierForm from "modules/landingPage/pages/wavier";
import WavierAgreement from "modules/landingPage/pages/wavier/wavier-agreement";
import Success from "modules/landingPage/pages/success";
import StandDetail from "modules/landingPage/pages/standDetail";
import NewHome from "containers/Home/home";
import { useLocation } from 'react-router-dom';


const MainRouter = (props) => {
  const [showHeaderFooter, setShowHeaderFooter] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchFAQs());
  }, []);

  useEffect(() => {
    if (
      location.pathname === '/wavier-form' ||
      location.pathname === '/wavier-form-agreement' ||
      location.pathname === '/hunter-agreement-document' ||
      location.pathname === '/landowner-agreement-document' ||
      location.pathname === '/agreement-documents' ||
      location.pathname.includes('/hunt-package')
    ) {
      setShowHeaderFooter(false);
    } else {
      setShowHeaderFooter(true);
    }
  }, [location.pathname]);

  // PUSHING NESTED ROUTES
  const landingPageUIEvents = {
    openStandDetailPage: (id, mapShown, queryParams) => {
      if (mapShown) {
        history.push(`/stand/${id}`, {
          coordinate: `${queryParams.latitude},${queryParams.longitude}`,
          zoom: queryParams.zoom,
          map: true,
        });
      } else {
        history.push(`/stand/${id}`);
      }
    },
    openBlogDetailPage: (id) => {
      history.push(`/blog/${id}`);
    },
  };

  return (
    <Layout showHeaderFooter={showHeaderFooter}>
      <LandingPageUIProvider landingPageUIEvents={landingPageUIEvents}>
        <Switch>
          <ContentRoute path="/my-profile" component={DashboardPage} />
          <Route exact path="/" component={NewHome} />
          <Route exact path="/stand/:id">
            {({ history, match }) =>
              match !== null && <StandDetail id={match && match.params.id} />
            }
          </Route>
          <Route exact path="/hunter-agreement-document">
            {({ history, match }) => match !== null && <HunterAgreement />}
          </Route>
          <Route exact path="/agreement-documents">
            {({ history, match }) => match !== null && <AgreementDocument />}
          </Route>
          <Route exact path="/landowner-agreement-document">
            {({ history, match }) => match !== null && <HunterAgreement />}
          </Route>
          <Route exact path="/blog/:id">
            {({ history, match }) =>
              match !== null && <BlogDetail id={match && match.params.id} />
            }
          </Route>
          <Route exact path="/review-reconsider/:id">
            {({ history, match }) => match !== null && <ReviewsReconsider />}
          </Route>
          <ContentRoute exact path="/reserve-stand/:id">
            {({ history, match }) =>
              match !== null && <CreateReservation id={match && match.params.id} />
            }
          </ContentRoute>
          <ContentRoute exact path="/leasing-application">
            {({ history, match }) => match !== null && <LeasingApplication />}
          </ContentRoute>
          <Route exact path="/wavier-form">
            {({ history, match }) => match !== null && <WavierForm />}
          </Route>
          <Route exact path="/wavier-form-agreement">
            {({ history, match }) => match !== null && <WavierAgreement />}
          </Route>
          <ContentRoute exact path="/success">
            {({ history, match }) => match !== null && <Success />}
          </ContentRoute>
          <Route exact path="/download" component={Downloads} />
          <Route exact path="/stand" component={Stand} />
          <Route path="/auth" component={AuthPage} />
          <Route path="/landowner-page" component={LandownerPage} />
          <Route path='/hunter-page' component={HunterPage} />
          <Route path="/about" component={About} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/faqs" component={Faq} />
          <Route exact path="/terms" component={Term} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/Tutorials" component={BlogList} />
          <Route path="/hunt-package" component={HuntPackage} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </LandingPageUIProvider>
    </Layout>
  );
};

export default MainRouter;
