
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchStandPosts, createStandPost, updateStandPost, deleteStandPost } from "modules/landingPage/_redux/landingPageActions";
import StandPostSkeleton from "components/layout/skeletons/stand-post-skeleton";
import toastHandler from "components/UI/toastHandler/toastHandler";
import InfiniteScroll from "react-infinite-scroll-component";
import CreateBlogConfirmationModal from "components/UI/modals/create-blog-confirmation-modal/CreateBlogConfirmationModal";
import { BlogConfirmationModal } from "components/UI/modals/blog-confirmation-modal/BlogConfirmationModal";
import { Swiper, SwiperSlide } from "swiper/react";
import NoRecordFound from "utils/noRecordFound";
import Spinner from "components/UI/Spinner.js";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import "./style.scss";
import AddVideoIcon from "assets/icons/video-add-icon.png";
import AddImageIcon from "assets/icons/image-add-icon.png";
import galleryIcon from "assets/icons/gallery-icon.png";
import videoIcon from "assets/icons/video-icon.png";
import playIcon from "assets/icons/play-icon.svg";
import ReactPlayer from "react-player";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


export default function StandBlog({ land }) {
    const dispatch = useDispatch();
    const [hasMore, setHasMore] = useState(false);
    const [show, setShow] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [page, setPage] = useState(1);
    const [isError, setIsError] = useState(false);
    const [isContentError, setIsContentError] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [standPostImages, setStandPostImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [imageCount, setImageCount] = useState(0);
    const [bigSizeImages, setBigSizeImages] = useState(0);
    const [videoCount, setVideoCount] = useState(0);
    const [showFullContent, setShowFullContent] = useState([]);
    const [showBlogConfirmationModal, setShowBlogConfirmationModal] = useState(false);
    const [postId, setPostId] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [uploadedMedia, setUploadedMedia] = useState([]);
    const [editMedia, setEditMedia] = useState([]);
    const [deleteMedia, setDeleteMedia] = useState([]);
    const [combinedMedia, setCombinedMedia] = useState([...editMedia, ...uploadedMedia]);
    const [imageCountExceeded, setImageCountExceeded] = useState(false);
    const [videoCountExceeded, setVideoCountExceeded] = useState(false);
    const [showBlogRequestModal, setShowBlogRequestModal] = useState(false);
    const [blogRequestMessage, setBlogRequestMessage] = useState("");


    const history = useHistory();
    const standId = history.location.pathname.split("/")[2]

    const videoRefs = useRef({});

    const onSlideChange = () => {
        Object.values(videoRefs.current).forEach(videoRef => {
            if (videoRef && videoRef.getInternalPlayer) {
                const player = videoRef.getInternalPlayer();
                if (player && typeof player.pause === 'function') {
                    player.pause();
                }
            }
        });
    };

    const [id, setId] = useState("");
    const {
        postList,
        totalPosts,
        user,
        newRequest,
        uploadProgress
    } = useSelector(
        (state) => ({
            postList: state.landingPage.postList,
            totalPosts: state.landingPage.totalPosts,
            user: state.auth.user,
            newRequest: state.landingPage.newRequest,
            uploadProgress: state.landingPage.uploadProgress
        }),
        shallowEqual
    );

    useEffect(() => {
        setPage(1);
        dispatch(fetchStandPosts(standId, { page: 1, per_page: 4 }, true))
    }, [newRequest, dispatch, standId]);

    useEffect(() => {
        setCombinedMedia([...editMedia, ...uploadedMedia]);
    }, [editMedia, uploadedMedia]);

    useEffect(() => {
        if (combinedMedia?.length > 0) {
          const initialImageCount = combinedMedia.filter(item => item.type === 'image').length;
          const initialVideoCount = combinedMedia.filter(item => item.type === 'video').length;
          
          setImageCount(initialImageCount);
          setVideoCount(initialVideoCount);
          setImageCountExceeded(initialImageCount >= 15);
          setVideoCountExceeded(initialVideoCount >= 3);
        }
    }, [combinedMedia]);

    const handleDeleteStand = (id) => {
        setId(id)
        setIsLoading(true)
        dispatch(deleteStandPost(id, onDeleteSuccess, onError))
    }

    const SortableItem = ({ id, children }) => {
        const {
          attributes,
          listeners,
          setNodeRef,
          transform,
          transition,
        } = useSortable({ id: id });
      
        const style = {
          transform: CSS.Transform.toString(transform),
          transition,
        };
      
        return (
          <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
          </div>
        );
    };

    const onImageChange = (e) => {
        const imageArray = []
        let count = imageCount;
        if (e?.target?.files) {
            Array.from(e?.target?.files)?.map(image => {
                if (count < 15) {
                    imageArray.push({ type: 'image', data: image });
                    count++;
                }
                if (image?.size > 35e6) {
                    setBigSizeImages(() => bigSizeImages + 1)
                }
            })
            setUploadedMedia([...uploadedMedia, ...imageArray]);
            setImageCount(count);
            setIsContentError(false);
            setImageCountExceeded(count >= 15);
        }
    }

    const onVideoChange = (e) => {
        const videoArray = [];
        let count = videoCount;
        if (e?.target?.files) {
            Array.from(e?.target?.files)?.map(video => {
                if (count < 3) {
                    videoArray.push({ type: 'video', data: video });
                    count++;
                }
            });
            setUploadedMedia([...uploadedMedia, ...videoArray]);
            setVideoCount(count);
            setIsContentError(false);
            setVideoCountExceeded(count >= 3);
        }
    }

    const onEditImageChange = (e) => {
        if (e?.target?.files) {
          const newImages = Array.from(e.target.files).map(file => ({
            type: 'image',
            data: file,
            isNew: true
          }));
          
          const newImageCount = imageCount + newImages.length;
          if (newImageCount <= 15) {
            setCombinedMedia([...combinedMedia, ...newImages]);
            setImageCount(newImageCount);
            setBigSizeImages(prev => prev + newImages.filter(img => img.data.size > 35e6).length);
            setIsContentError(false);
          } else {
            setImageError(true);
          }
          setImageCountExceeded(newImageCount >= 15);
        }
    }
      
    const onEditVideoChange = (e) => {
        if (e?.target?.files) {
            const newVideos = Array.from(e.target.files).map(file => ({
                type: 'video',
                data: file,
                isNew: true
            }));
            
            const newVideoCount = videoCount + newVideos.length;
            if (newVideoCount <= 3) {
                setCombinedMedia([...combinedMedia, ...newVideos]);
                setVideoCount(newVideoCount);
                setIsContentError(false);
            }
            setVideoCountExceeded(newVideoCount >= 3);
        }
    }

    const removeImageHandler = (event) => {
        const image = uploadedMedia?.find((data, index) => index == event.target.id);
        const result = uploadedMedia?.filter((data, index) => index != event.target.id);
        setUploadedMedia(result);
        setImageCount(imageCount - 1);
        if (image?.data?.size > 35e6) {
            setBigSizeImages(() => bigSizeImages - 1)
        }
        setImageError(false);
        setImageCountExceeded(false);
    }

    const removeVideoHandler = (event) => {
        const result = uploadedMedia?.filter((_, index) => index != event.target.id);
        setUploadedMedia(result);
        setVideoCount(videoCount - 1);
        setIsContentError(false);
        setVideoCountExceeded(false);
    }

    const removeEditImageHandler = (event) => {
        const index = parseInt(event.target.id);
        const removedItem = combinedMedia[index];
    
        const updatedCombinedMedia = combinedMedia.filter((_, i) => i !== index);
        setCombinedMedia(updatedCombinedMedia);
    
        if (removedItem.type === 'image') {
            setImageCount(prevCount => prevCount - 1);
            if (removedItem.data.size > 35e6) {
                setBigSizeImages(prevCount => prevCount - 1);
            }
        }
    
        if (removedItem.id) {
            setDeleteMedia(prevDeleteMedia => [...prevDeleteMedia, removedItem.id]);
        }
    
        setImageError(false);
        setImageCountExceeded(false);
    }
    
    const removeEditVideoHandler = (event) => {
        const index = parseInt(event.target.id);
        const removedItem = combinedMedia[index];

        const updatedCombinedMedia = combinedMedia.filter((_, i) => i !== index);
        setCombinedMedia(updatedCombinedMedia);

        if (removedItem.type === 'video') {
            setVideoCount(prevCount => prevCount - 1);
        }

        if (removedItem.id) {
            setDeleteMedia(prevDeleteMedia => [...prevDeleteMedia, removedItem.id]);
        }

        setIsContentError(false);
        setVideoCountExceeded(false);
    }

    const removeEditMediaHandler = (event) => {
        const index = parseInt(event.target.id);
        const updatedMedia = combinedMedia?.filter((_, i) => i !== index);
        setEditMedia(updatedMedia);
        const removedItem = combinedMedia[index];
        if (removedItem?.type === 'image') {
            setImageCount(prevCount => prevCount - 1);
            if (removedItem?.size > 35e6) {
                setBigSizeImages(prevCount => prevCount - 1);
            }
        } else if (removedItem?.type === 'video') {
            setVideoCount(prevCount => prevCount - 1);
        }

        if (removedItem?.id) {
            setDeleteMedia(prevDeleteMedia => [...prevDeleteMedia, removedItem.id]);
        }
    }

    const sensors = useSensors(
        useSensor(PointerSensor,{
            activationConstraint: {
                distance: 1
            }
        }),
    );
      
    const onDragEnd = (event) => {
        const { active, over } = event;
        
        if (active.id !== over.id) {
          setUploadedMedia((items) => {
            const oldIndex = items.findIndex((item, index) => `media-${index}` === active.id);
            const newIndex = items.findIndex((item, index) => `media-${index}` === over.id);
            
            return arrayMove(items, oldIndex, newIndex);
          });
        }
    };

    const onEditDragEnd = (event) => {
        const { active, over } = event;
        
        if (active.id !== over.id) {
          setCombinedMedia((items) => {
            const oldIndex = items.findIndex((item, index) => `media-${index}` === active.id);
            const newIndex = items.findIndex((item, index) => `media-${index}` === over.id);
            
            return arrayMove(items, oldIndex, newIndex);
          });
        }
    };

    const renderMedia = useCallback((item, index) => (
        <SortableItem key={`media-${index}`} id={`media-${index}`}>
            {item.type === 'image' ? (
                <div key={`image-${index}`} className={item.data?.size > 35e6 ? `uploaded-image size-exceed-error` : `uploaded-image`}>
                    <img className="preview-image" src={URL.createObjectURL(item.data)} alt="preview" />
                    <span className="del-img" id={index} onClick={removeImageHandler}></span>
                    <img className="type-icon" src={galleryIcon} alt="gallery-icon" />
                </div>
            ) : (
                <div key={`video-${index}-${item.data.name}`} className="uploaded-image">
                    <video className="preview-image" key={`video-${index}-${item.data.name}`}>
                        <source src={URL.createObjectURL(item.data)} type={item.data.type} key={`video-${index}-${item.data.name}`} />
                    </video>
                    <span className="del-img" id={index} onClick={removeVideoHandler}></span>
                    <img className="type-icon" src={videoIcon} alt="video-icon" />
                </div>
            )}
        </SortableItem>
    ), [uploadedMedia]);

    const renderEditMedia = useCallback((item, index) => (
        <SortableItem key={`media-${index}`} id={`media-${index}`}>
            {item?.isNew ? (
                <div key={`upload-${index}`} className={item.data?.size > 35e6 ? `uploaded-image size-exceed-error` : `uploaded-image`}>
                    {item.type === 'image' ? (
                        <div key={`image-${index}`} className={item.data?.size > 35e6 ? `uploaded-image size-exceed-error` : `uploaded-image`}>
                            <img className="preview-image" src={URL.createObjectURL(item.data)} alt="preview" />
                            <span className="del-img" id={index} onClick={removeEditImageHandler}></span>
                            <img className="type-icon" src={galleryIcon} alt="gallery-icon" />
                        </div>
                    ) : (
                        <div key={`video-${index}-${item.data.name}`} className="uploaded-image">
                            <video className="preview-image" key={`video-${index}-${item.data.name}`}>
                                <source src={URL.createObjectURL(item.data)} type={item.data.type} key={`video-${index}-${item.data.name}`} />
                            </video>
                            <span className="del-img" id={index} onClick={removeEditVideoHandler}></span>
                            <img className="type-icon" src={videoIcon} alt="video-icon" />
                        </div>
                    )}
                </div>
            ) : (
                <div key={`edit-${index}`} className="uploaded-image">
                    <img className="preview-image" src={item?.image || item?.url} alt="preview" />
                    <span className="del-img" id={index} onClick={removeEditMediaHandler}></span>
                    <img className="type-icon" src={item?.type === 'image' ? galleryIcon : videoIcon} alt={`${item?.type}-icon`} />
                </div>
            )}
        </SortableItem>
    ), [combinedMedia]);

    const onSubmitHandler = (event) => {
        setIsLoading(true);
        event.preventDefault();
        if (title?.trim()?.length < 1) {
            setIsError(true);
            setIsLoading(false);
            return
        } if (description?.trim()?.length < 1 && uploadedMedia?.length < 1) {
            setIsContentError(true);
            if (title?.trim().length > 1) {
                setIsError(false);
            }
            setIsLoading(false);
        } else {
            const formData = new FormData();
            formData.append('title', title);
            if (description?.length > 1)
                formData.append('description', description);
            formData.append('stand_id', standId);

            if (uploadedMedia?.length > 0) {
                for (var i = 0; i < uploadedMedia.length; i++) {
                    formData.append(`media[${i}]`, uploadedMedia[i].data);
                }
            }
            dispatch(createStandPost(formData, onSuccess, onError));
        }
    }

    const onEditSubmitHandler = (event) => {
        setIsLoading(true);
        event.preventDefault();
        if (title?.trim()?.length < 1) {
            setIsError(true);
            setIsLoading(false);
            return;
        } else if (description?.trim()?.length < 1 && editMedia?.length < 1 && uploadedMedia?.length < 1 && combinedMedia?.length < 1) {
            setIsContentError(true);
            if (title?.trim()?.length > 1) {
                setIsError(false);
            }
            setIsLoading(false);
            return;
        } else {
            const formData = new FormData();
            formData.append('title', title);
            if (description?.length > 1)
                formData.append('description', description);
            formData.append('_method', 'PUT');
            formData.append('stand_id', standId);

            combinedMedia?.forEach((item, index) => {
                if (item.isNew) {
                  formData.append(`media[${index}]`, item.data);
                } else {
                  formData.append(`media[${index}]`, item.id);
                }
            });

            if (deleteMedia?.length > 0) {
                deleteMedia?.forEach((id, index) => {
                    formData.append(`delete_media[${index}]`, id);
                });
            }

            dispatch(updateStandPost(id, formData, onEditSuccess, onError));
            setBigSizeImages(0);
        }
    }

    const onHideHandler = () => {
        setShow(false);
        setShowEditModal(false);
        setTitle('');
        setDescription('');
        setStandPostImages([]);
        setIsError(false);
        setIsContentError(false);
        setImageError(false);
        setImageCount(0);
        setBigSizeImages(0);
        setVideoCount(0);
        setUploadedMedia([]);
        setEditMedia([]);
        setDeleteMedia([]);
        setCombinedMedia([]);
        setImageCountExceeded(false);
        setVideoCountExceeded(false);
    }

    const onEditHandler = (id, title, description, media) => {
        setShowEditModal(true);
        setTitle(title);
        setId(id);
        setDescription(description);
        if (media?.length > 0) {
            const formattedMedia = media?.map(item => ({
                ...item,
                type: item?.video ? 'video' : 'image',
                url: item?.video || item?.image,
                isNew: false
            }));
            setCombinedMedia(formattedMedia);
            setImageCount(media?.filter(item => !item?.video)?.length);
            setVideoCount(media?.filter(item => item?.video)?.length);
        }
    }

    const fetchMoreStandPosts = () => {
        dispatch(fetchStandPosts(standId, { page: page + 1, per_page: 4 }))
        setPage(() => page + 1);
    }

    useEffect(() => {
        if (!postList?.length) {
            return setHasMore(false);
        }
        if (postList?.length >= totalPosts) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [postList, totalPosts]);

    const onSuccess = (response) => {
        dispatch(fetchStandPosts(standId, { page: 1, per_page: 4 }, true))
        setIsLoading(false);
        onHideHandler();
        setShowBlogConfirmationModal(true);
        setPostId(response?.id);
        setTitle(response?.title);
        setDescription(response?.description);
        setStandPostImages(standPostImages);
        setSuccessMessage("Your Blog Post Is Created!");
        setId("");
    }

    const onEditSuccess = (response) => {
        dispatch(fetchStandPosts(standId, { page: 1, per_page: 4 }, true))
        setIsLoading(false);
        onHideHandler();
        setShowBlogConfirmationModal(true);
        setPostId(response?.id);
        setTitle(response?.title);
        setDescription(response?.description);
        setStandPostImages(standPostImages);
        setSuccessMessage("Your Blog Post Is Updated!");
        setId("");
    }

    const onDeleteSuccess = (message) => {
        dispatch(fetchStandPosts(standId, { page: 1, per_page: 4 }, true))
        setIsLoading(false);
        onHideHandler();
        toastHandler(message, "success");
        setId("");
    }

    const onError = (message) => {
        setIsLoading(false);
        toastHandler(message, "error");
    }

    const titleInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleDescriptionChange = (e) => {
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const newValue = capitalizeFirstLetter(e.target.value);
        
        setDescription(newValue);
        setIsContentError(false);

        setTimeout(() => {
            input.setSelectionRange(start, end);
        }, 0);
    };

    const handleTitleChange = (e) => {
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const newValue = capitalizeFirstLetter(e.target.value);
        
        setTitle(newValue);
        setIsError(false);

        setTimeout(() => {
            input.setSelectionRange(start, end);
        }, 0);
    };

    const toggleContent = (id) => {
        const isIdPresent = showFullContent.includes(id);
        const updatedState = isIdPresent
            ? showFullContent.filter(item => item !== id)
            : [...showFullContent, id];
        setShowFullContent(updatedState);
    };

    const formatText = (text) => {
        const urlPattern = /((https?|ftp):\/\/[^\s<]+)/g;
        const formattedText = text
        ?.replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`)
        ?.replace(/\r\n/g, "<br/>")
        ?.replace(/\n/g, "<br/>");
        return { __html: formattedText };
    };

    return (
        <>
            {
                <>
                    <div className={postList.length == 0 ? "no-blog-main" : "no-blog-main"}>
                        {
                            localStorage.getItem("token") && land?.user?.id == user?.id &&
                            <button className="create-btn  mb-3" onClick={() => setShow(true)}>CREATE NEW BLOG POST</button>
                        } {
                            postList.length == 0 ?
                                <NoRecordFound message="No blog post added yet" /> : null
                        }
                    </div>
                    <InfiniteScroll
                        dataLength={postList?.length}
                        hasMore={hasMore}
                        next={fetchMoreStandPosts}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>{postList?.length === 0 ? null : ""}</b>
                            </p>
                        }
                        loader={
                            <StandPostSkeleton />
                        }
                    >
                        {
                            postList?.map(post => {
                                return (
                                    <div>
                                        <div className="main-blog" key={post?.id}>
                                            <div className={`main-blog-topbar ${(localStorage.getItem("token") && post?.user?.id == user?.id) && post?.status === "pending" ? "request-for-approval" : ""}`}>
                                                {(localStorage.getItem("token") && post?.user?.id == user?.id) && post?.status === "pending" ?
                                                    (
                                                        <div className="request-for-approval-bar">
                                                            <p>iHunt admin has created this blog post, would you like to make it public?</p>
                                                            <div className="request-action-btns">
                                                                <button className=" request-action-btn reject" onClick={() => {
                                                                    setShowBlogRequestModal(true);
                                                                    setBlogRequestMessage("Are you sure you want to reject this blog post?");
                                                                    setPostId(post?.id);
                                                                }}>REJECT</button>
                                                                <button className=" request-action-btn approve" onClick={() => {
                                                                    setShowBlogRequestModal(true);
                                                                    setBlogRequestMessage("Are you sure you want to approve this blog post?");
                                                                    setPostId(post?.id);
                                                                }}>APPROVE</button>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between py-3">
                                                    <h3 className="blog-title">
                                                        <span className="date">
                                                            <label>{moment(post.updated_at).format("DD")}</label>
                                                            {moment(post.updated_at).format("MMM YY")}
                                                        </span>
                                                        <div className="d-flex flex-column">
                                                            {post?.title}
                                                            {post?.user?.id == user?.id ? (
                                                                <span
                                                                    className={`blog-status ${post?.status === "approved" ? "approved" : post?.status === "rejected" ? "rejected" : "pending"}`}>{post?.status}</span>) : null
                                                            }
                                                        </div>
                                                    </h3>
                                                    {localStorage.getItem("token") && postList && postList[0]?.user?.id == user?.id &&
                                                        <div className="action-btns">
                                                            <a className="btn btn-edit" onClick={() => { onEditHandler(post?.id, post?.title, post?.description, post?.media) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="11.008" height="11.604" viewBox="0 0 11.008 11.604">
                                                                    <g id="edit" transform="translate(-111.41 -27.641)">
                                                                        <path id="Path_1212" data-name="Path 1212" d="M242.48,35.515l2.431-.3,5.126-5.127L247.946,28l-5.125,5.125Zm.8-2.165,4.662-4.662,1.4,1.4-4.66,4.661-1.63.2Z" transform="translate(-127.973)" fill="currentColor" stroke="currentColor" stroke-width="0.5" />
                                                                        <path id="Path_1213" data-name="Path 1213" d="M111.66,49.921h10.023V43.71h-.489v5.721h-9.045v-9.79h4.828v-.489H111.66Z" transform="translate(0 -10.926)" fill="currentColor" stroke="currentColor" stroke-width="0.5" />
                                                                    </g>
                                                                </svg>
                                                                EDIT
                                                            </a>
                                                            <button className="btn btn-delete" onClick={() => {
                                                                handleDeleteStand(post?.id)
                                                            }}
                                                                disabled={isLoading}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10.097" height="11.314" viewBox="0 0 10.097 11.314">
                                                                    <g id="icon" transform="translate(-161.797 -68.445)">
                                                                        <path id="Path_1214" data-name="Path 1214" d="M338.8,232.46h.583v4.271H338.8Z" transform="translate(-172.246 -159.598)" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                        <path id="Path_1215" data-name="Path 1215" d="M167.625,68.6h-1.546a1.021,1.021,0,0,0-1.021,1.021v.385h-2.813a.292.292,0,1,0,0,.583h.775v7.273a1.751,1.751,0,0,0,1.751,1.751h4.163a1.751,1.751,0,0,0,1.751-1.751V70.592h.775a.292.292,0,0,0,0-.583h-2.813v-.385a1.021,1.021,0,0,0-1.021-1.021Zm-1.985,1.021a.438.438,0,0,1,.438-.438h1.548a.438.438,0,0,1,.438.438v.385h-2.422Zm4.461.969v7.273a1.167,1.167,0,0,1-1.167,1.167H164.77a1.167,1.167,0,0,1-1.167-1.167V70.592Z" transform="translate(-0.006 -0.007)" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                        <path id="Path_1216" data-name="Path 1216" d="M271.6,232.46h.583v4.271H271.6Z" transform="translate(-106.795 -159.598)" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                        <path id="Path_1217" data-name="Path 1217" d="M406,232.46h.583v4.271H406Z" transform="translate(-237.698 -159.598)" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </g>
                                                                </svg>
                                                                {isLoading && id == post?.id ? < Spinner /> : "DELETE"}
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {
                                                (post?.media && post?.media?.length > 0) && (
                                                    <div className="stand-blog-wrapper">
                                                        <div className="thumbslider">
                                                            <Swiper
                                                                observer={true}
                                                                observerParents={true}
                                                                navigation={post?.media?.length > 1}
                                                                thumbs={{ swiper: thumbsSwiper }}
                                                                key={Math.random(8)}
                                                                className="stand-slider"
                                                                pagination={{
                                                                    clickable: true,
                                                                }}
                                                                onSlideChange={onSlideChange}
                                                            >
                                                                {post?.media &&
                                                                    post?.media?.map((mediaItem, index) => (
                                                                        <SwiperSlide key={index}>
                                                                            {mediaItem?.video === null ? (
                                                                                <img
                                                                                    src={mediaItem?.image}
                                                                                    alt=""
                                                                                    onError={(e) => (e.target.src)}
                                                                                />
                                                                            ) : (
                                                                                <ReactPlayer
                                                                                    ref={(el) => { videoRefs.current[`video-${post.id}-${index}`] = el }}
                                                                                    url={mediaItem?.video}
                                                                                    controls={true}
                                                                                    width={"100%"}
                                                                                    height={"100%"}
                                                                                    playIcon={<img src={playIcon} alt="play" style={{ width: 'auto', height: 'auto' }} />}
                                                                                    light={encodeURI(mediaItem?.image)}
                                                                                    playsinline={true}
                                                                                    playing={true}
                                                                                />
                                                                            )}
                                                                        </SwiperSlide>
                                                                    ))
                                                                }
                                                            </Swiper>
                                                        </div>
                                                    </div>)
                                            }
                                            <div className="desc">
                                                {post?.description?.length > 500 ? showFullContent.includes(post?.id) ? (
                                                    <>
                                                    <span dangerouslySetInnerHTML={formatText(post?.description)} />
                                                        <span onClick={() => toggleContent(post?.id)} className="read-more">
                                                            Show Less
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                    <span dangerouslySetInnerHTML={formatText(post?.description?.slice(0, 500))} />
                                                        <span onClick={() => toggleContent(post?.id)} className="read-more">
                                                            Show More
                                                        </span>
                                                    </>
                                                ) : <span dangerouslySetInnerHTML={formatText(post?.description)} />}
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </InfiniteScroll>
                </>
            }
            <Modal
                show={show}
                onHide={onHideHandler}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="create-post-dialog"
                backdrop="static"
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <div>
                        <h4 className="title">Create New Post</h4>
                        <form>
                            <div className="form-floating custom-description">
                                <input
                                    name="text"
                                    value={title}
                                    type="text"
                                    onChange={(e) => handleTitleChange(e)}
                                    className={isError ? "form-control form-control-error" : "form-control"}
                                    ref={titleInputRef}
                                ></input>
                                <label for="floatingInputGrid">Title</label>
                            </div>
                            {isError && <span className="title-error">Post title is required</span>}

                            <div className="form-floating blog-desc custom-description">
                                <textarea name="text" className="form-control" value={description} onChange={(e) => handleDescriptionChange(e)} ref={descriptionInputRef}>
                                </textarea>
                                <label for="floatingInputGrid m-0">
                                    Write something about this post (optional)
                                </label>
                            </div>
                            
                            {(uploadedMedia?.length > 0) && (
                                <DndContext 
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={onDragEnd}
                                >
                                    <SortableContext
                                        items={uploadedMedia.map((_, index) => `media-${index}`)}
                                        strategy={rectSortingStrategy}
                                    >
                                        <div className="uploaded-media-wrapper">
                                            {uploadedMedia?.map((item, index) => renderMedia(item, index))}
                                            {bigSizeImages > 0 && <div className="size-exceed-error-msg">Image must not exceed 35 MB!</div>}
                                            {uploadProgress > 0 && uploadProgress <= 100 && (
                                                <div className="progress-circle">
                                                    <svg viewBox="0 0 36 36" className="circular-chart">
                                                        <path
                                                            className="circle-bg"
                                                            d="M18 2.0845
                                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                                        />
                                                        <path
                                                            className="circle"
                                                            strokeDasharray={`${uploadProgress}, 100`}
                                                            d="M18 2.0845
                                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                                        />
                                                        <text x="18" y="20.35" className="percentage">
                                                            {uploadProgress}%
                                                        </text>
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                    </SortableContext>
                                </DndContext>
                            )}

                            <div className="media-uploader-wrapper">
                                <div className={`image-uploader-wrapper ${imageCountExceeded ? 'exceeded' : ''}`}>
                                    <img src={AddImageIcon} alt="upload media" />
                                    <input type="file"
                                        name='image'
                                        value=""
                                        id='image-create'
                                        onChange={onImageChange}
                                        disabled={imageCount >= 15 || isLoading}
                                        accept="image/*" multiple />
                                    <span className="max-limit"> Maximum 15 images are allowed Image must not exceed 35 MB</span>
                                </div>
                                <div className="seperator"></div>
                                <div className={`video-uploader-wrapper ${videoCountExceeded ? 'exceeded' : ''}`}>
                                    <img src={AddVideoIcon} alt="upload media" />
                                    <input type="file"
                                        name='video'
                                        value=""
                                        id='video-create'
                                        onChange={onVideoChange}
                                        disabled={videoCount >= 3 || isLoading}
                                        accept="video/*" multiple />
                                    <span className="max-limit"> Maximum 3 videos are allowed</span>
                                </div>
                            </div>
                            {
                                imageError ? <div className="image-error-msg">Maximum 15 images are allowed</div> : null
                            }
                            {isContentError && <span className="error">You must provide post content or upload any media</span>}
                            <div className="modal-action-btns mt-4 text-center">
                                <button className='modal-action-btn outlined' type='button' onClick={onHideHandler} disabled={isLoading}>CANCEL</button>
                                <button className='modal-action-btn filled' type='submit' onClick={onSubmitHandler} disabled={isLoading || bigSizeImages}>
                                    {isLoading ? <Spinner /> : "CREATE"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showEditModal}
                onHide={onHideHandler}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="create-post-dialog"
                backdrop="static"
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <div>
                        <h4 className="title">Update Post</h4>
                        <form>
                            <div className="form-floating">
                                <input
                                    name="title"
                                    value={title}
                                    type="text"
                                    onChange={(e) => handleTitleChange(e)}
                                    className={isError ? "form-control form-control-error" : "form-control"}
                                    ref={titleInputRef}
                                ></input>
                                <label for="floatingInputGrid">Title</label>
                                {isError && <span className="error">Post title is required</span>}
                            </div>
                            <div className="form-floating custom-description">
                                <textarea name="description" className="form-control" value={description} onChange={(e) => handleDescriptionChange(e)} ref={descriptionInputRef}>
                                </textarea>
                                <label for="floatingInputGrid">
                                    Write something about this post (optional)
                                </label>
                            </div>

                            {(uploadedMedia?.length > 0 || editMedia?.length > 0 || combinedMedia?.length > 0) && (
                                <DndContext 
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={onEditDragEnd}
                                >
                                    <SortableContext
                                        items={combinedMedia.map((_, index) => `media-${index}`)}
                                        strategy={rectSortingStrategy}
                                    >
                                    <div className="uploaded-media-wrapper">
                                        {combinedMedia?.map((item, index) => renderEditMedia(item, index))}
                                        {bigSizeImages > 0 && <div className="size-exceed-error-msg">Image must not exceed 35 MB!</div>}
                                        {uploadProgress > 0 && uploadProgress <= 100 && (
                                            <div className="progress-circle">
                                                <svg viewBox="0 0 36 36" className="circular-chart">
                                                    <path
                                                        className="circle-bg"
                                                        d="M18 2.0845
                                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                                    />
                                                    <path
                                                        className="circle"
                                                        strokeDasharray={`${uploadProgress}, 100`}
                                                        d="M18 2.0845
                                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                                    />
                                                    <text x="18" y="20.35" className="percentage">
                                                        {uploadProgress}%
                                                    </text>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    </SortableContext>
                                </DndContext>
                            )}

                            <div className="media-uploader-wrapper">
                                <div className={`image-uploader-wrapper ${imageCountExceeded ? 'exceeded' : ''}`}>
                                    <img src={AddImageIcon} alt="upload media" />
                                    <input type="file"
                                        name='image'
                                        value=""
                                        id='image-edit'
                                        onChange={onEditImageChange}
                                        disabled={imageCount >= 15 || isLoading}
                                        accept="image/*" multiple />
                                    <span className="max-limit"> Maximum 15 images are allowed Image must not exceed 35 MB</span>
                                </div>
                                <div className="seperator"></div>
                                <div className={`video-uploader-wrapper ${videoCountExceeded ? 'exceeded' : ''}`}>
                                    <img src={AddVideoIcon} alt="upload media" />
                                    <input type="file"
                                        name='video'
                                        value=""
                                        id='video-edit'
                                        onChange={onEditVideoChange}
                                        disabled={videoCount >= 3 || isLoading}
                                        accept="video/*" multiple />
                                    <span className="max-limit"> Maximum 3 videos are allowed</span>
                                </div>
                            </div>
                            {
                                imageError ? <div className="image-error-msg">Maximum 15 images are allowed</div> : null
                            }
                            {isContentError && <span className="error">You must provide post content or upload any media</span>}
                            <div className="modal-action-btns mt-4 text-center">
                                <button className='modal-action-btn outlined' type='button' onClick={onHideHandler} disabled={isLoading}>CANCEL</button>
                                <button className='modal-action-btn filled' type='submit' onClick={onEditSubmitHandler} disabled={isLoading || bigSizeImages}>
                                    {isLoading ? <Spinner /> : "UPDATE"}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <CreateBlogConfirmationModal
                standId={land.id}
                postId={postId}
                setPostId={setPostId}
                show={showBlogConfirmationModal}
                onHide={() => {
                    setShowBlogConfirmationModal(false);
                    onHideHandler();
                }}
                title={title}
                setTitle={setTitle}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
            />

            <BlogConfirmationModal
                postId={postId}
                show={showBlogRequestModal}
                onHide={() => {setShowBlogRequestModal(false);}}
                message={blogRequestMessage}
            />
        </>
    )
}
