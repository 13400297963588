import { useFormik } from "formik";
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { sendFundRequest } from "modules/dashboard/_redux/reservations/reservationActions";
import * as Yup from "yup";
import Spinner from "components/UI/Spinner.js";
import { getIhuntSettings } from "modules/dashboard/_redux/reservations/reservationActions";
import { useHistory } from "react-router-dom";


export default function RequestFundsModal({
    show,
    onHide,
    id,
    setShowRequestFundModal,
    getRequest
}) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { actionsLoading, ihuntFee } = useSelector(
        (state) => ({
            actionsLoading: state.reservation.actionsLoading,
            ihuntFee: state.reservation.ihuntSettings
        }),
        shallowEqual
    );

    useEffect(() => {
        if (show) {
            dispatch(getIhuntSettings({ key: "IHunt share" }))
        }
    }, [show])


    let pattern = new RegExp('^(([0-9]{1,7})(\\.[0-9]{1})?)$');
    const toastId = history.location.pathname;

    const validationSchema = Yup.object({
        transfer_amount: Yup.number().required("Amount is required").moreThan(0, "Invalid amount").test('value', 'Invalid amount', val => pattern.test(val)),
        desc: Yup.string().trim().required("Description is required")
    });

    const onFormHide = () => {
        onHide()
        formik.resetForm();
    }

    const formik = useFormik({
        initialValues: {
            transfer_amount: "0.0",
            desc: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const params = {
                id: id,
                body: {
                    amount: values.transfer_amount,
                    description: values.desc,
                },
            };
            dispatch(sendFundRequest(params, onSuccess, onError));
        },
    });

    const onSuccess = (message) => {
        toastHandler(message, "success", toastId);
        setShowRequestFundModal(false);
        dispatch(getRequest())
        formik.resetForm()
    }
    const onError = (message) => {
        toastHandler(message, "error", toastId);
        setShowRequestFundModal(false);
        dispatch(getRequest())
        formik.resetForm()
    }

    const onFocusHandler = () => {
        if (formik.values.transfer_amount === '0.0') {
            formik.setFieldValue('transfer_amount', '');
        }
    };

    const onBlurHandler = () => {
        if (formik.values.transfer_amount === '') {
            formik.setFieldValue('transfer_amount', '0.0');
        }
    };

    const onClickHandler = () => {
        if (formik.values.transfer_amount === '0.0') {
            formik.setFieldValue('transfer_amount', '');
        }
    };

    return (
        <Modal
            show={show}
            onHide={onFormHide}
            className="transfer-model "
            centered
        >
            <Modal.Header className=" px-5 pt-5" closeButton>
                <h5>Request Funds</h5>
            </Modal.Header>
            <Modal.Body className="px-5">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-floating mb-3">
                        {
                            <span style={{
                                position: "absolute",
                                left: 14,
                                top: 20,
                                color: "#000",
                                fontSize: 14,
                                zIndex: 2
                            }}>$</span>
                        }
                        <input
                            type="number"
                            className="form-control"
                            name="transfer_amount"
                            value={formik.values.transfer_amount || ''}
                            onFocus={onFocusHandler}
                            onBlur={onBlurHandler}
                            onClick={onClickHandler}
                            onChange={formik.handleChange}
                            style={{ paddingLeft: 24 }}
                        />
                        <label for="mobilenumber">Amount</label>
                        {formik?.touched?.transfer_amount &&
                            formik?.errors?.transfer_amount && (
                                <div style={{ color: "red" }}>
                                    {formik?.errors?.transfer_amount}
                                </div>
                            )}
                    </div>
                    <div className="form-floating mb-3">

                        <textarea
                            type="text"
                            className="form-control"
                            name="desc"
                            value={formik.values.desc}
                            onChange={formik.handleChange}
                        />
                        <label for="mobilenumber">Description</label>
                        {formik?.touched?.desc &&
                            formik?.errors?.desc && (
                                <div style={{ color: "red" }}>
                                    {formik?.errors?.desc}
                                </div>
                            )}
                    </div>
                    <p className="py-3 mb-0 fw-bold">iHunt will take {`${ihuntFee?.value || 0}`}% share of this transaction</p>
                    <button
                        className="btn btn-primary text-white d-flex mx-auto text-captilize"
                        disabled={actionsLoading}
                    >
                        {actionsLoading ? <Spinner /> : "SEND REQUEST"}
                    </button>
                </form>
            </Modal.Body>
        </Modal >
    );
}
