import React from "react";
import LoginScreen from "./../../../../assets/images/login-screen.png";
import PlaceHolderImage from "./../../../../assets/images/login-screen-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function accountDetailGuide() {
    return (
        <section className="account-detail-guide">
            <div className="inner-container">
                <div className="row justify-content-between">
                    <div className="col-lg-5 col-md-6">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Download and Create An Account</h5>
                                <p>Download the iHunt app from your device's app store and complete the installation process.</p>
                                <p>Launch the app and create a new account by providing the required information. This includes your name, email address, phone number, date of birth, and a secure password. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={LoginScreen}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={LoginScreen}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
