import React, { useState, useMemo, useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import EyeOffIcon from "assets/icons/feather-icon - eye-off.svg";
import EyeOnIcon from "assets/icons/feather-eye.svg";
import TopBar from "../../../landingPage/pages/topBar.js";
import * as actions from "../../_redux/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useAuthUIContext } from "../AuthUIContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getDeviceToken } from "../../../../firebase";
import Spinner from "components/UI/Spinner.js";
import "./style.scss";

export default function Login() {
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated } = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState("");

  const authUIContext = useAuthUIContext();
  const authUIProps = useMemo(() => {
    return {
      initLogin: authUIContext.initLogin,
    };
  }, [authUIContext]);


  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const showVerifyEmailModal = () => {
    history.push("/auth/email-verification/?=login");
  };

  const handleSubmit = async (values) => {
    setError("");
    setIsLoading(true);
    await dispatch(
      actions.login(values, setError, handleSuccess, showVerifyEmailModal)
    );
    setIsLoading(false);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: authUIProps.initLogin,
    onSubmit: handleSubmit,
  });

  const [isLoading, setIsLoading] = useState(false);

  const callbackUrl = state?.from?.pathname;
  const queryParams = new URLSearchParams(state?.from?.search);
  
  if (state?.from?.search && state?.from?.search === `?ref=hlrbo`) {
    const referralSource = queryParams.get('ref');
    const currentDate = new Date().getTime();
    const referralInfo = {
      ref: referralSource,
      date: currentDate
    };
    localStorage.setItem("referralInfo", JSON.stringify(referralInfo));
  }

  const handleSuccess = () => {
    const invitationCode = queryParams.get("invitation_code");
    const showCalendar = queryParams.get("show_calendar");
    if (callbackUrl) {
      if (state?.from?.search && state?.from?.search === `?approve=false`) {
        localStorage.setItem("status", "false");
        history.push(callbackUrl);
      } else if (state?.from?.search && state?.from?.search === `?approve=true`) {
        localStorage.setItem("status", "true");
        history.push(callbackUrl);
      } else if (showCalendar) {
        history.push(callbackUrl + state?.from?.search);
      } else if (invitationCode) {
        history.push(callbackUrl + state?.from?.search);
      } else if (state?.from?.search && state?.from?.search === `?ref=hlrbo`) {
        history.push(callbackUrl + state?.from?.search);
      } else {
        history.push(callbackUrl);
      }
    } else {
      history.push("/my-profile/my-stands");
    }
    getDeviceToken();
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/")
    }
  }, [])

  useEffect(() => {
    setError("")
  }, [formik?.values])

  useEffect(() => { document.title = 'Login | iHunt' }, []);

  return (
    <div className="inner-container">
      <TopBar />
      <div className="form-container">
        <h2 className="text-center mb-1">Login</h2>
        <p className="text-center">Enter your email and password</p>
        <div className="form-box">
          {error && (
            <Form.Control.Feedback type="d-block invalid">
              {error}
            </Form.Control.Feedback>
          )}
          <Form onSubmit={formik.handleSubmit}>
            <FloatingLabel controlId="floatingInputGrid" label="Email">
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FloatingLabel>
            {formik?.touched?.email && formik?.errors?.email && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            )}
            <FloatingLabel controlId="floatingInputGrid" label="Password">
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <div
                className={
                  showPassword ? "input-icon text" : "input-icon password"
                }
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <img src={showPassword ? EyeOnIcon : EyeOffIcon} alt="" />
              </div>
            </FloatingLabel>
            {formik?.touched?.password && formik?.errors?.password && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            )}

            <button className="btn btn-primary w-100 text-white mt-4">
              {isLoading ? <Spinner /> : "LOGIN"}
            </button>
          </Form>
          <span className="mt-4 d-block ">
            <Link
              className="text-secondary fw-light text-decoration-none"
              to="/auth/forgot-password"
            >
              Forgot Your Password?
            </Link>
          </span>
          <Link className="btn btn-secondary w-100 mt-4" to="/auth/signup">
            APPLY TO JOIN OUR CLUB
          </Link>
        </div>
      </div>
    </div>
  );
}
