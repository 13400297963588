import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearStandDetailsFectched } from "modules/landingPage/_redux/landingPageActions";
import Caution from "assets/icons/caution-icon.svg";
import Cross from "assets/icons/cross.svg";
import CustomSpinner from "components/layout/Loader/Spinner";
import "./style.scss";

export default function TopBar({ land, callerLand = false }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
    dispatch(clearStandDetailsFectched());
  };
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
      if (land && land.status === "rejected") {
        setShowRejectionReason(true);
      } else {
        setShowRejectionReason(false);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [land]);

  return (
    <div className="inner-container">
      {loading ? (
        <CustomSpinner />
      ) : (
      <>
        {showRejectionReason && (
          <div className="status-bar mt-5 mb-5">
            <img src={Caution} alt="" />
            <div className="content-holder">
              <h5>Your land was not accepted</h5>
              <p>{land?.reason}</p>
            </div>
            <a href="javascript:void(0)" className="bar-action">
              <img src={Cross} alt="" />
            </a>
          </div>
        )}
        <div className="top-bar mt-4 mb-3 d-flex justify-content-between align-items-center">
          <div className="top" onClick={handleBack}>
            <i className="bi bi-arrow-left-short me-2"></i>
            <span>Go back</span>
          </div>
          {callerLand && (
            <div className="path">
              <ul>
                <li className="fw-bold">
                  {" "}
                  Listing <i className="bi bi-chevron-right me-2"></i>{" "}
                </li>
                <li>
                  {land?.title} <i className="bi bi-chevron-right me-2"></i>{" "}
                </li>
                <li className="active">{land?.title}</li>
              </ul>
            </div>
          )}
        </div>
      </>
      )}
    </div>
  );
}
