import { wavierSlice, callTypes } from "./wavierSlice";
import * as requestFromServer from "./wavierCRUD";
import { loadUser } from "modules/auth/_redux/authActions";
import toastHandler from "components/UI/toastHandler/toastHandler";

const { actions } = wavierSlice;

export const getWavierQuestions = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getWavierQuestions();
    if (response.status) {
      dispatch(actions.wavierQuestionsFetched({ entities: response.data }));
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearWavierQuestions());
    }
    return 0;
  }
};

export const postWavie = (data) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    let response = await requestFromServer.postWavie(data);
    if (response.status) {
      dispatch(actions.wavierQuestionsSubmitted({ entities: response.data }))
      dispatch(loadUser())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
      toastHandler(error?.response?.data?.message, "error")
    }
    return 0;
  }
};

export const setRedirectUrl = (url) => async (dispatch) => {
  await dispatch(actions.setRedirectUrl(url))
}

export const clearState = () => async (dispatch) => {
  await dispatch(actions.clearWavierFormData())
  await dispatch(actions.clearWavierSignature())
  await dispatch(actions.clearUserWavierStatus())
  await dispatch(actions.clearRedirectUrl())
};


