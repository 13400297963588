import { fetchStandFollower } from "modules/landingPage/_redux/landingPageActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoRecordFound from "utils/noRecordFound";
import Avatar from "assets/images/avatar.png"
import "./style.scss";


export default function Followers({ standId }) {
  const dispatch = useDispatch();
  const { standFollower } = useSelector(state => state.landingPage);

  useEffect(() => {
    dispatch(fetchStandFollower(standId));
  }, [dispatch, standId])
  
  return (
    <>
      <div className="followers-grid">
        {standFollower && standFollower.map((follower) => {
          return <div key={follower?.id} className="follower-card">
            <div className="image"><img src={follower.image ? follower.image : Avatar} alt=""></img></div>
            {follower.first_name + " " + follower.last_name}
          </div>
        })}
      </div>
      {
        standFollower.length === 0 && <NoRecordFound message="No follower yet" />
      }
    </>
  );
}

