import React, { useEffect } from "react";
import "./style.scss";
import Collapsee from "../collapse";
import { useSelector } from "react-redux";


export default function Faq() {
  const faqs = useSelector((state) => state.landingPage.faqs);
  useEffect(() => {document.title = 'FAQs | iHunt'}, []);
  
  return (
    <div className="mt-5 mb-5">
      <div className="inner-container">
        <h1 className="text-center">Frequently Asked Question</h1>
        <div className="faq-wrapper">
        {faqs && faqs.length
          ? faqs?.map((faq) => (
              <div className="faq-container">
                <Collapsee heading={faq.question} content={faq.answer} />
              </div>
            ))
          : null}
        </div>
      </div>
    </div>
  );
}
