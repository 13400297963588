import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
    error: null,
    actionsLoading: null,
    listLoading: null,
    deleteActionsLoading: null,
    groups: [],
    created: false,
    statusCode: 0,
    totalGroups: 0,
    selectedGroup: null,
    assigned: false,
};

export const callTypes = {
    list: "list",
    action: "action",
    delete: "delete"
};

export const groupSlice = createSlice({
    name: "groups",
    initialState: initialDashboardState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } if (action.payload.callType === callTypes.action) {
                state.actionsLoading = false;
            } else {
                state.deleteActionsLoading = false
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } if (action.payload.callType === callTypes.action) {
                state.actionsLoading = true;
            } else {
                state.deleteActionsLoading = true;
            }
        },
        clearEntities: (state, action) => {
            state.totalGroups = 0;
            state.actionsLoading = false;
            state.listLoading = false;
            state.deleteActionsLoading = false;
        },
        groupsFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.groups = entities;
        },
        groupDetailsFetched: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.groupDetails = action.payload.selectedGroup;
        },
        // create Site Type
        groupCreated: (state) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
            state.created = !state.created
        },
        groupAssigned: (state) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
            state.assigned = !state.assigned
        },
        groupDeleted: (state) => {
            state.error = null;
            state.deleteActionsLoading = false;
            state.created = !state.created;
            state.assigned = !state.assigned
        },
    },
});
