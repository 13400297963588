import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss"
import { FloatingLabel, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import Spinner from "components/UI/Spinner.js";
import { useDispatch } from "react-redux";
import Calendar from "react-calendar";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { format } from "date-fns";
import { createStandVoucher, fetchStandVouchers } from "modules/landingPage/_redux/landingPageActions";
import CreateVoucherConfirmationModal from "../create-voucher-confirmation-modal/CreateVoucherConfirmationModal";


const CreateVoucherModal = ({ show, onHide, land }) => {
  const [discountOption, setDiscountOption] = useState("percentage")
  const [fieldToggle, setFieldToggle] = useState("max_usage");
  const [loading, setLoading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showVoucherConfirmationModal, setShowVoucherConfirmationModal] = useState(false);
  const [voucherId, setVoucherId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successDescription, setSuccessDescription] = useState("");
  const [voucherName, setVoucherName] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [expiryType, setExpiryType] = useState("");
  const [discountType, setDiscountType] = useState("");

  const dispatch = useDispatch();
  const calendarRef = useRef();

  useEffect(() => {
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, []);

  const manageOutsideClick = (event) => {
    if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
      setShowCalendar(false)
    }
  }

  const isMonthOrYearClicked = (target) => {
    if (target.classList.contains("react-calendar__tile") ||
    target.classList.contains("react-calendar__year-view__months__month") ||
    target.classList.contains("react-calendar__decade-view__years__year") ||
    target.classList.contains("react-calendar__century-view__decades__decade")) 
    {
      return true;
    }
    if (target.tagName === "ABBR" && target.getAttribute("aria-label")) {
      return true;
    }
    return false;
  };

  const AddVoucherSchema = Yup.object({
    expiryType: Yup.string().required(),

    expiryDate: Yup.date().when("expiryType", {
      is: (expiryType) => expiryType === "expiry_date",
      then: Yup.date().required("Expiry Date is a required field"),
    }),

    maxUsage: Yup.number()
      .max(99999, "Upto 5 digits are allowed")
      .when("expiryType", {
        is: (expiryType) => expiryType === "max_usage",
        then: Yup.number().required("Max Usage is a required field"),
      }),
    voucher: Yup.string()
      .min(3, "Voucher should be at least 3 characters")
      .max(50, "Voucher should be less than 50 characters")
      .required("Voucher is a required field"),

    discount: Yup.number()
      .max(99999, "Upto 5 digits are allowed")
      .required("Discount is a required field"),
  });

  const formInitialValues = {
    voucher: "",
    discount: "",
    discountType: "percentage",
    expiryType: "max_usage",
    expiryDate: "",
    maxUsage: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const formData = {
      name: values.voucher,
      discount_type: values.discountType,
      discount: values.discount,
      amount: values.discount,
      expiry_type: values.expiryType,
    };
    if (fieldToggle == "expiry_date") {
      const date_format = format(new Date(values.expiryDate), "MMM dd yyyy");
      formData["expiry_date"] = date_format;
    } else {
      formData["max_usage"] = values.maxUsage;
    }
    dispatch(createStandVoucher(land?.id, formData, onSuccess, onError));
  };

  const onSuccess = (response) => {
    dispatch(fetchStandVouchers(land?.id, true))
    setLoading(false);
    onHideHandler();
    setShowVoucherConfirmationModal(true);
    setVoucherId(response?.id);
    setVoucherName(response?.name);
    setVoucherCode(response?.code);
    {response?.expiry_type === "max_usage"
      ? setExpiryType(`${response?.max_usage} users limit`)
      : setExpiryType(response?.expiry_date)
    }
    {response?.discount_type === "percentage" ? (
      setDiscountType(`${response?.discount}%`)
    ) : (
      setDiscountType(`$${response?.discount}`)
    )}
    setSuccessMessage("Your Voucher Is Created!");
    setSuccessDescription("Your voucher has been created and is ready to be shared with your friends and family.");
  }

  const onError = () => {
    setLoading(false)
  }

  const formik = useFormik({
    validationSchema: AddVoucherSchema,
    initialValues: formInitialValues,
    onSubmit: handleSubmit,
  });

  const onHideHandler = () => {
    setDiscountOption("percentage");
    setFieldToggle("max_usage");
    formik.resetForm();
    onHide();
  }

  return (
    <>
      <Modal className="create-voucher-modal" show={show} onHide={onHideHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Create A Voucher
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-body-text">Give a voucher to friends and family so they can enjoy your location at a discounted rate.</p>
          <Form className="create-voucher-form" onSubmit={formik.handleSubmit}>
            <FloatingLabel label="Voucher Name">
              <Form.Control
                type="text"
                name="voucher"
                id="voucher"
                value={formik.values.voucher}
                onChange={(e) => {
                  formik.setFieldValue("voucher", e.target.value);
                }}
                className={formik?.touched?.voucher && formik?.errors?.voucher ? "form-control-error fs-14" : "fs-14"}
              />
            </FloatingLabel>
            {formik?.touched?.voucher && formik?.errors?.voucher && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.voucher}
              </Form.Control.Feedback>
            )}

            <div className="discount-section">
              <div className="discount-section-left">
                <FloatingLabel label={discountOption === "percentage" ? "%" : "$"}>
                  <Form.Control
                    type="number"
                    name="discount"
                    id="discount"
                    value={formik.values.discount}
                    onChange={(e) =>  {
                      formik.setFieldValue("discount", e.target.value);
                    }}
                    className={formik?.touched?.discount && formik?.errors?.discount ? "form-control-error fs-14" : "fs-14"}
                  />
                </FloatingLabel>
                {formik?.touched?.discount && formik?.errors?.discount && (
                  <Form.Control.Feedback type="d-block invalid">
                    {formik.errors.discount}
                  </Form.Control.Feedback>
                )}
              </div>
              <div className='discount-section-right'>
                <input
                  type="radio"
                  className="btn-check"
                  name="discountType"
                  id="percentage"
                  autocomplete="off"
                  value="percentage"
                  checked={discountOption==="percentage"}
                  onClick={() => {
                    formik.setFieldValue("discountType", "percentage");
                    setDiscountOption("percentage")
                  }}
                />
                <label className="btn btn-secondary" htmlFor="percentage">%</label>

                <input
                  type="radio"
                  className="btn-check"
                  name="discountType"
                  id="amount"
                  autocomplete="off"
                  value="amount"
                  checked={discountOption==="amount"}
                  onClick={() => {
                    formik.setFieldValue("discountType", "amount");
                    setDiscountOption("amount")
                  }}
                />
                <label className="btn btn-secondary" htmlFor="amount">$</label>
              </div>
            </div>

            <div className="toggle-usage-and-expiry">
              <div>
                <label htmlFor="max" className="label">
                  <input
                    id="max"
                    type="radio"
                    name="expiryType"
                    value="max_usage"
                    className="radio-input"
                    checked={fieldToggle==="max_usage"}
                    onClick={() => {
                      formik.setFieldValue("expiryType", "max_usage");
                      setFieldToggle("max_usage");
                    }}
                  />
                  <span>Usage Limit</span>
                </label>
              </div>

              <div>
                <label htmlFor="expiry" className="label">
                  <input
                    id="expiry"
                    type="radio"
                    name="expiryType"
                    value="expiry_date"
                    className="radio-input"
                    checked={fieldToggle==="expiry_date"}
                    onClick={() => {
                      formik.setFieldValue("expiryType", "expiry_date");
                      setFieldToggle("expiry_date");
                    }}
                  />
                  <span>Expiry Date</span>
                </label>
              </div>
            </div>

            {
              fieldToggle === "max_usage" ? (
                <FloatingLabel label="Users Per Voucher">
                  <Form.Control
                    type="number"
                    name="maxUsage"
                    id="maxUsage"
                    value={formik.values.maxUsage}
                    onChange={(e) =>  {
                      formik.setFieldValue("maxUsage", e.target.value);
                    }}
                    className={formik?.touched?.maxUsage && formik?.errors?.maxUsage ? "form-control-error fs-14" : "fs-14"}
                  />
                  {formik?.touched?.maxUsage && formik?.errors?.maxUsage && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.maxUsage}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              ) : (
                <FloatingLabel label="Expiry Date" ref={calendarRef}>
                  <Form.Control
                    type="text"
                    name="expiryDate"
                    id="expiryDate"
                    value={formik.values.expiryDate}
                    onChange={(e) =>  {
                      formik.setFieldValue("expiryDate", e.target.value);
                    }}
                    onClick={() => setShowCalendar(!showCalendar)}
                    readOnly={true}
                    className={formik?.touched?.expiryDate && formik?.errors?.expiryDate ? "form-control-error fs-14 cursor-pointer" : "fs-14 cursor-pointer"}
                  />
                  <div
                    className="input-icon"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    <i className="far fa-calendar"></i>
                  </div>
                  <div>
                    <Calendar
                      calendarType={"ISO 8601"}
                      className={showCalendar ? "" : "hide"}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "expiryDate",
                          moment(value).format("MMM DD YYYY")
                        );
                        setShowCalendar(false);
                      }}
                      minDate={new Date()}
                    />
                  </div>
                  {formik?.touched?.expiryDate && formik?.errors?.expiryDate && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.expiryDate}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              )
            }

            <div className='modal-action-btns'>
              <button className='modal-action-btn outlined' type='button' onClick={onHideHandler}>CANCEL</button>
              <button className='modal-action-btn filled' type='submit'>
              {loading ? <Spinner /> : "CREATE"}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <CreateVoucherConfirmationModal
        standId={land?.id}
        voucherId={voucherId}
        setVoucherId={setVoucherId}
        show={showVoucherConfirmationModal}
        onHide={() => {
          setShowVoucherConfirmationModal(false);
          onHideHandler();
        }}
        discountOption={discountOption}
        setDiscountOption={setDiscountOption}
        fieldToggle={fieldToggle}
        setFieldToggle={setFieldToggle}
        voucherName={voucherName}
        setVoucherName={setVoucherName}
        voucherCode={voucherCode}
        setVoucherCode={setVoucherCode}
        expiryType={expiryType}
        setExpiryType={setExpiryType}
        discountType={discountType}
        setDiscountType={setDiscountType}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
        successDescription={successDescription}
        setSuccessDescription={setSuccessDescription}
      />
    </>
  );
}

export default CreateVoucherModal;
