import { useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import * as actions from "modules/auth/_redux/authActions";
import TopBar from "../../../landingPage/pages/topBar.js";
import { modifyValuesWithDialCode } from "utils/helpers";
import { NAME_REGEX, EMAIL_REGEX, ADDRESS_REGEX } from "utils/helpers";
import { useAuthUIContext } from "../AuthUIContext";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import AccountInfo from "./AccountInfo";
import ProfileInfo from "./ProfileInfo";
import { useFormik } from "formik";
import startsWith from 'lodash.startswith';
import * as Yup from "yup";
import "./style.scss";
import { handleAddressComponentStatus } from "modules/dashboard/_redux/stand/standActions.js";


export default function Signup() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPhonValid, setIsPhonValid] = useState(false);
  const [isSecandryPhonValid, setIsSecandryPhonValid] = useState(false);
  const [isPlaceSelected, setIsPlaceSelected] = useState(false);
  const history = useHistory();

  const authUIContext = useAuthUIContext();
  const authUIProps = useMemo(() => {
    return {
      initRegistrationStep1: authUIContext.initRegistrationStep1,
      initRegistrationStep2: authUIContext.initRegistrationStep2,
    };
  }, [authUIContext]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(
        NAME_REGEX,
        "Name should be Alphanumeric, Starting with Alphabet"
      ),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(
        NAME_REGEX,
        " Name should be Alphanumeric, Starting with Alphabet"
      ),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required")
      .matches(
        EMAIL_REGEX,
        "Email should be alphnumeric with leading alphabets"
      ),
    isSecandryPhon: Yup.boolean(),
    phone: Yup.string().required("Phone Number is required")
      .test('len', 'Invalid Phone number', val => isPhonValid),
    secondary_phone: Yup.string().when("isSecandryPhon", {
      is: true,
      then: Yup.string()
        .test('len', 'Invalid Phone number', val => isSecandryPhonValid),
      otherwise: Yup.string().notRequired(),
    }),
    country_dial_code: Yup.string().required(),
    secondary_dial_code: Yup.string(),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string()
      .required("Confirm Password is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    birthday: Yup.string().required("Birthday is required")
  });

  const validationSchema1 = Yup.object().shape({
    address: Yup.string()
      .required('Address is required')
      .min(10, 'Address must be at least 10 characters long')
      .matches(ADDRESS_REGEX, 'Address must be alphanumeric with at least one letter'),
    city: Yup.string().nullable().notRequired("City is required"),
    state_name: Yup.string().nullable().notRequired("State is required"),
    zipcode: Yup.string().nullable().notRequired("Zipcode is required"),
    country: Yup.string().nullable().notRequired("Country is required"),
  });

  const handleSubmit = async (values) => {
    if (step <= 0) {
      setStep(s => s + 1)
    } else {
      values = { ...formik.values, ...formik1.values }
      setLoading(true);
      setError("");
      const updatedValues = modifyValuesWithDialCode(values);
      localStorage.setItem("pending", true);
      await dispatch(actions.register(updatedValues, setError, handleSuccess));
      setLoading(false);
    }
  };

  const handleBack = () => {
    setStep(s => s - 1)
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: authUIProps.initRegistrationStep1,
    onSubmit: handleSubmit,
  });

  const formik1 = useFormik({
    validationSchema: validationSchema1,
    initialValues: authUIProps.initRegistrationStep2,
    onSubmit: handleSubmit,
  });

  const handleSuccess = () => {
    dispatch(handleAddressComponentStatus(false))
    history.push("/auth/email-verification/?=signup");
  };

  const ValidatePhonNumber = (phone, country) => {
    let length = country.format?.replace(/[^.]/g, '')?.length;
    if (length > 15) {
      length = length - 1;
    }
    if ((startsWith(phone, country.dialCode) ||
      startsWith(country.dialCode, phone)) &&
      phone?.length == length) {
      return true
    } else return false
  }

  const getStepClass = (stepNumber) => {
    if (step === stepNumber) {
      return "progress-body active";
    } else if (step > stepNumber) {
      return "progress-body active";
    } else {
      return "progress-body";
    }
  };

  useEffect(() => {document.title = 'Signup | iHunt'}, []);

  return (
    <div className="inner-container">
      <TopBar />
      <div className="form-container">
        <h2 className="text-center mb-1">Create Account</h2>
        <p className="text-center">
          To start reservation you need to create account
        </p>
        <div className="progress-style">
          <div className="">
            <span className={getStepClass(0)}>1</span>
            <br />
            <span>Account Info</span>
          </div>
          <div>
            <span className={getStepClass(1)}>2</span>
            <br />
            <span>Profile Info</span>
          </div>
        </div>
        {step == 0 ?
          <AccountInfo
            formik={formik}
            setIsPhonValid={setIsPhonValid}
            setIsSecandryPhonValid={setIsSecandryPhonValid}
            ValidatePhonNumber={ValidatePhonNumber}
            error={error}
          >
            <button
              className="btn btn-primary w-100 text-white mt-4"
              onClick={formik.handleSubmit}
              type="button"
            >
              NEXT
            </button>
            <span className="mt-4 d-block">
              Already have an account? <Link to="/auth/login">Login</Link>
            </span>
          </AccountInfo > :
          <ProfileInfo
            formik={formik1}
            handleBack={handleBack}
            error={error}
            loading={loading}
            isPlaceSelected={isPlaceSelected}
            setIsPlaceSelected={setIsPlaceSelected}
          />
        }
      </div>
    </div>
  );
}
