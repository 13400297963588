import React, { useEffect } from "react";
import HuntForHunter from "./huntForHunter";
import HuntingExperience from "./huntingExperience";
import BookHunt from "./bookHunt";
import AccountDetailGuide from "./accountDetailGuide";
import BrowseHuntingSite from "./browseHuntingSite";
import ChooseHuntingSite from "./chooseHuntingSite";
import PaymentConfirmation from "./paymentConfirmation";
import ParkingInfo from "./parkingInfo";
import CheckInHuntingLocation from "./checkInHuntingLocation";
import EnjoyHunting from "./enjoyHunting";
import CheckOutReview from "./checkOutReview";
import EnjoyDownloadHunting from "./enjoyDownloadHunting";


export default function HunterPage() {
    useEffect(() => {document.title = 'For Hunters | iHunt'}, []);
    return (
        <div className="hunter-page-main">
            <HuntForHunter />
            <HuntingExperience />
            <BookHunt />
            <AccountDetailGuide />
            <BrowseHuntingSite />
            <ChooseHuntingSite />
            <PaymentConfirmation />
            <ParkingInfo />
            <CheckInHuntingLocation />
            <EnjoyHunting />
            <CheckOutReview />
            <EnjoyDownloadHunting />
        </div>
    );
}
