import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss"
import { ButtonGroup, Dropdown, } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchStandPosts, getStandPostById, updateStandPost, deleteStandPost } from "modules/landingPage/_redux/landingPageActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import Spinner from "components/UI/Spinner.js";
import AddVideoIcon from "assets/icons/video-add-icon.png";
import AddImageIcon from "assets/icons/image-add-icon.png";
import galleryIcon from "assets/icons/gallery-icon.png";
import videoIcon from "assets/icons/video-icon.png";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


const CreateBlogConfirmationModal = ({ 
  standId,
  postId,
  setPostId,
  show,
  onHide,
  title,
  setTitle,
  successMessage,
  setSuccessMessage
}) => {

  const dispatch = useDispatch();

  const { postDetails, uploadProgress } = useSelector(
    (state) => ({
      postDetails: state.landingPage.postDetails,
      uploadProgress: state.landingPage.uploadProgress
    }),
    shallowEqual
  );

  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [standPostImages, setStandPostImages] = useState([]);
  const [imageCount, setImageCount] = useState(0);
  const [bigSizeImages, setBigSizeImages] = useState(0);
  const [videoCount, setVideoCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [editMedia, setEditMedia] = useState([]);
  const [deleteMedia, setDeleteMedia] = useState([]);
  const [combinedMedia, setCombinedMedia] = useState([]);
  const [imageCountExceeded, setImageCountExceeded] = useState(false);
  const [videoCountExceeded, setVideoCountExceeded] = useState(false);

  useEffect(() => {
    if (show && (standId || postId)) {
      dispatch(getStandPostById(postId));
    }
  }, [show, standId, postId, dispatch]);

  useEffect(() => {
    setCombinedMedia([...editMedia, ...uploadedMedia]);
  }, [editMedia, uploadedMedia]);

  useEffect(() => {
    if (combinedMedia?.length > 0) {
      const initialImageCount = combinedMedia.filter(item => item.type === 'image').length;
      const initialVideoCount = combinedMedia.filter(item => item.type === 'video').length;
      
      setImageCount(initialImageCount);
      setVideoCount(initialVideoCount);
      setImageCountExceeded(initialImageCount >= 15);
      setVideoCountExceeded(initialVideoCount >= 3);
    }
  }, [combinedMedia]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <i
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }} className="fas fa-ellipsis-h fa-lg" />
  ));

  const onDeleteSuccess = (message) => {
    toastHandler(message, "success");
    setId("");
    setTitle("");
    setDescription("");
    setStandPostImages([]);
    setIsError(false);
    setIsContentError(false);
    setImageError(false);
    setImageCount(0);
    setBigSizeImages(0);
    setVideoCount(0);
    setUploadedMedia([]);
    setEditMedia([]);
    setDeleteMedia([]);
    setCombinedMedia([]);
    onHide();
  }

  const onError = (message) => {
    setIsLoading(false);
    toastHandler(message, "error");
  }

  const handleDeleteStandPost = (id) => {
    dispatch(deleteStandPost(id, onDeleteSuccess, onError))
  }

  const onImageChange = (e) => {
    if (e?.target?.files) {
      const newImages = Array.from(e.target.files).map(file => ({
        type: 'image',
        data: file,
        isNew: true
      }));
      
      const newImageCount = imageCount + newImages.length;
      if (newImageCount <= 15) {
        setCombinedMedia([...combinedMedia, ...newImages]);
        setImageCount(newImageCount);
        setBigSizeImages(prev => prev + newImages.filter(img => img.data.size > 35e6).length);
        setIsContentError(false);
      } else {
        setImageError(true);
      }
      setImageCountExceeded(newImageCount >= 15);
    }
  }
  
  const onVideoChange = (e) => {
    if (e?.target?.files) {
      const newVideos = Array.from(e.target.files).map(file => ({
        type: 'video',
        data: file,
        isNew: true
      }));
      
      const newVideoCount = videoCount + newVideos.length;
      if (newVideoCount <= 3) {
        setCombinedMedia([...combinedMedia, ...newVideos]);
        setVideoCount(newVideoCount);
        setIsContentError(false);
      }
      setVideoCountExceeded(newVideoCount >= 3);
    }
  }

  const removeImageHandler = (event) => {
    const index = parseInt(event.target.id);
    const removedItem = combinedMedia[index];

    const updatedCombinedMedia = combinedMedia.filter((_, i) => i !== index);
    setCombinedMedia(updatedCombinedMedia);

    if (removedItem.type === 'image') {
        setImageCount(prevCount => prevCount - 1);
        if (removedItem.data.size > 35e6) {
            setBigSizeImages(prevCount => prevCount - 1);
        }
    }

    if (removedItem.id) {
        setDeleteMedia(prevDeleteMedia => [...prevDeleteMedia, removedItem.id]);
    }

    setImageError(false);
    setImageCountExceeded(false);
  }

  const removeVideoHandler = (event) => {
    const index = parseInt(event.target.id);
    const removedItem = combinedMedia[index];

    const updatedCombinedMedia = combinedMedia.filter((_, i) => i !== index);
    setCombinedMedia(updatedCombinedMedia);

    if (removedItem.type === 'video') {
        setVideoCount(prevCount => prevCount - 1);
    }

    if (removedItem.id) {
        setDeleteMedia(prevDeleteMedia => [...prevDeleteMedia, removedItem.id]);
    }

    setIsContentError(false);
    setVideoCountExceeded(false);
  }

  const SortableItem = ({ id, children }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ id: id });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
  
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {children}
      </div>
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor,{
        activationConstraint: {
          distance: 1
        }
    }),
  );

  const onDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      setCombinedMedia((items) => {
        const oldIndex = items.findIndex((item, index) => `media-${index}` === active.id);
        const newIndex = items.findIndex((item, index) => `media-${index}` === over.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const removeEditMediaHandler = (event) => {
    const index = parseInt(event.target.id);
    const updatedMedia = combinedMedia?.filter((_, i) => i !== index);
    setEditMedia(updatedMedia);
    
    const removedItem = combinedMedia[index];
    if (removedItem?.type === 'image') {
        setImageCount(prevCount => prevCount - 1);
        if (removedItem?.size > 35e6) {
            setBigSizeImages(prevCount => prevCount - 1);
        }
    } else if (removedItem?.type === 'video') {
        setVideoCount(prevCount => prevCount - 1);
    }
    
    if (removedItem?.id) {
        setDeleteMedia(prevDeleteMedia => [...prevDeleteMedia, removedItem.id]);
    }
  }

  const renderMedia = useCallback((item, index) => (
    <SortableItem key={`media-${index}`} id={`media-${index}`}>
      {item?.isNew ? (
        <div key={`upload-${index}`} className={item.data?.size > 35e6 ? `uploaded-image size-exceed-error` : `uploaded-image`}>
          {item.type === 'image' ? (
            <div key={`image-${index}`} className={item.data?.size > 35e6 ? `uploaded-image size-exceed-error` : `uploaded-image`}>
              <img className="preview-image" src={URL.createObjectURL(item.data)} alt="preview" />
              <span className="del-img" id={index} onClick={removeImageHandler}></span>
              <img className="type-icon" src={galleryIcon} alt="gallery-icon" />
            </div>
          ) : (
            <div key={`video-${index}-${item.data.name}`} className="uploaded-image">
              <video className="preview-image" key={`video-${index}-${item.data.name}`}>
                  <source src={URL.createObjectURL(item.data)} type={item.data.type} key={`video-${index}-${item.data.name}`} />
              </video>
              <span className="del-img" id={index} onClick={removeVideoHandler}></span>
              <img className="type-icon" src={videoIcon} alt="video-icon" />
            </div>
          )}
        </div>
      ) : (
        <div key={`edit-${index}`} className="uploaded-image">
          <img className="preview-image" src={item?.image || item?.url} alt="preview" />
          <span className="del-img" id={index} onClick={removeEditMediaHandler}></span>
          <img className="type-icon" src={item?.type === 'image' ? galleryIcon : videoIcon} alt={`${item?.type}-icon`} />
        </div>
      )}
    </SortableItem>
  ), [combinedMedia]);

  const titleInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleDescriptionChange = (e) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const newValue = capitalizeFirstLetter(e.target.value);
    
    setDescription(newValue);
    setIsContentError(false);

    setTimeout(() => {
      input.setSelectionRange(start, end);
    }, 0);
  };

  const handleTitleChange = (e) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const newValue = capitalizeFirstLetter(e.target.value);
    
    setTitle(newValue);
    setIsError(false);

    setTimeout(() => {
      input.setSelectionRange(start, end);
    }, 0);
  };

  const onEditSubmitHandler = (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (title?.trim()?.length < 1) {
        setIsError(true);
        setIsLoading(false);
        return;
    } else if (description?.trim()?.length < 1 && editMedia?.length < 1 && uploadedMedia?.length < 1 && combinedMedia?.length < 1) {
        setIsContentError(true);
        if (title?.trim()?.length > 1) {
            setIsError(false);
        }
        setIsLoading(false);
        return;
    } else {
        const formData = new FormData();
        formData.append('title', title);
        if (description?.length > 1)
            formData.append('description', description);
        formData.append('_method', 'PUT');
        formData.append('stand_id', standId);

        combinedMedia?.forEach((item, index) => {
          if (item.isNew) {
            formData.append(`media[${index}]`, item.data);
          } else {
            formData.append(`media[${index}]`, item.id);
          }
        });

        if (deleteMedia?.length > 0) {
            deleteMedia?.forEach((id, index) => {
                formData.append(`delete_media[${index}]`, id);
            });
        }

        dispatch(updateStandPost(id, formData, onEditSuccess, onError));
        setBigSizeImages(0);
    }
  }

  const onHideHandler = () => {
    setShowEditModal(false);
    setId("");
    setDescription("");
    setStandPostImages([]);
    setIsError(false);
    setIsContentError(false);
    setImageError(false);
    setImageCount(0);
    setBigSizeImages(0);
    setVideoCount(0);
    setUploadedMedia([]);
    setEditMedia([]);
    setDeleteMedia([]);
    setCombinedMedia([]);
  }

  const onEditHandler = (id, title, description, media) => {
    setShowEditModal(true);
    setTitle(title);
    setId(id);
    setDescription(description);
    if (media?.length > 0) {
          const formattedMedia = media?.map(item => ({
            ...item,
            type: item?.video ? 'video' : 'image',
            url: item?.video || item?.image,
            isNew: false
        }));
        setCombinedMedia(formattedMedia);
        setImageCount(media?.filter(item => !item?.video)?.length);
        setVideoCount(media?.filter(item => item?.video)?.length);
    }
  }

  const onEditSuccess = (response) => {
    dispatch(fetchStandPosts(standId, { page: 1, per_page: 4 }, true))
    setIsLoading(false);
    setTitle("");
    onHideHandler();
    setPostId(response?.id);
    setTitle(response?.title);
    setDescription(response?.description);
    setSuccessMessage("Your Blog Post Is Updated!");
    dispatch(getStandPostById(postId));
  }

  return (
    <>
      <Modal className="create-blog-confirmation-modal" show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {successMessage}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-body-text">This blog post will tell about your location to Sportsman what it has to offer.</p>
          <form className='create-blog-confirmation-form'>

            <div className="created-blog-section">
              <div className="content">
                {postDetails?.images && postDetails?.images.length > 0 ? (
                  <img 
                    className='blog-image' 
                    src={postDetails?.images[0]?.image} 
                    alt='blog post' 
                  />
                ) : standPostImages && standPostImages?.length > 0 ? (
                  <img
                    className='blog-image' 
                    src={URL.createObjectURL(standPostImages[0])} 
                    alt='blog post' 
                  />
                ) : null}
                <h4 className="blog-title">{postDetails?.title}</h4>
              </div>
              <div className="toggle-menu">
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle as={CustomToggle} drop="down-centered" />
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => { onEditHandler(postDetails?.id, postDetails?.title, postDetails?.description, postDetails?.media) }}>Edit</Dropdown.Item>
                    <Dropdown.Item className='red-dropdown-item' onClick={() => {handleDeleteStandPost(postId)}}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className='modal-action-btns'>
              <button className='modal-action-btn filled' type='button' onClick={onHide}>Done</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showEditModal}
        onHide={onHideHandler}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-blog-post-dialog"
        backdrop="static"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            <h4 className="title">Update Post</h4>
            <form>
              <div className="form-floating">
                <input
                    name="title"
                    value={title}
                    type="text"
                    onChange={(e) => handleTitleChange(e)}
                    className={isError ? "form-control form-control-error" : "form-control"}
                    ref={titleInputRef}
                ></input>
                <label for="floatingInputGrid">Title</label>
                {isError && <span className="error">Post title is required</span>}
              </div>
              <div className="form-floating custom-description">
                <textarea name="description" className="form-control" value={description} onChange={(e) => handleDescriptionChange(e)}  ref={descriptionInputRef}>
                </textarea>
                <label for="floatingInputGrid">
                    Write something about this post (optional)
                </label>
              </div>

              {(uploadedMedia?.length > 0 || editMedia?.length > 0 || combinedMedia?.length > 0) && (
                <DndContext 
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    items={combinedMedia.map((_, index) => `media-${index}`)}
                    strategy={rectSortingStrategy}
                  >
                    <div className="uploaded-media-wrapper">
                      {combinedMedia?.map((item, index) => renderMedia(item, index))}
                      {bigSizeImages > 0 && <div className="size-exceed-error-msg">Image must not exceed 35 MB!</div>}
                      {uploadProgress > 0 && uploadProgress <= 100 && (
                        <div className="progress-circle">
                          <svg viewBox="0 0 36 36" className="circular-chart">
                            <path
                              className="circle-bg"
                              d="M18 2.0845
                                                  a 15.9155 15.9155 0 0 1 0 31.831
                                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              className="circle"
                              strokeDasharray={`${uploadProgress}, 100`}
                              d="M18 2.0845
                                                  a 15.9155 15.9155 0 0 1 0 31.831
                                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" className="percentage">
                              {uploadProgress}%
                            </text>
                          </svg>
                        </div>
                      )}
                    </div>
                  </SortableContext>
                </DndContext>
              )}

              <div className="media-uploader-wrapper">
                  <div className={`image-uploader-wrapper ${imageCountExceeded ? 'exceeded' : ''}`}>
                      <img src={AddImageIcon} alt="upload media" />
                      <input type="file"
                          name='image'
                          value=""
                          id='image-edit'
                          onChange={onImageChange}
                          disabled={imageCount >= 15 || isLoading}
                          accept="image/*" multiple />
                      <span className="max-limit"> Maximum 15 images are allowed Image must not exceed 35 MB</span>
                  </div>
                  <div className="seperator"></div>
                  <div className={`video-uploader-wrapper ${videoCountExceeded ? 'exceeded' : ''}`}>
                      <img src={AddVideoIcon} alt="upload media" />
                      <input type="file"
                          name='video'
                          value=""
                          id='video-edit'
                          onChange={onVideoChange}
                          disabled={videoCount >= 3 || isLoading}
                          accept="video/*" multiple />
                      <span className="max-limit"> Maximum 3 videos are allowed</span>
                  </div>
              </div>
              {
                imageError ? <div className="image-error-msg">Maximum 15 images are allowed</div> : null
              }
              {isContentError && <span className="error">You must provide post content or upload any media</span>}
              <div className="modal-action-btns mt-4 text-center">
                  <button className='modal-action-btn outlined' type='button' onClick={onHideHandler} disabled={isLoading}>CANCEL</button>
                  <button className='modal-action-btn filled' type='submit' onClick={onEditSubmitHandler} disabled={isLoading || bigSizeImages}>
                      {isLoading ? <Spinner /> : "UPDATE"}
                  </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateBlogConfirmationModal;
