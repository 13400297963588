import { ReactComponent as Percent } from "assets/icons/percent.svg";
import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/stand/standActions";
import { useDashboardUIContext } from "../DashboardUIContext";
import Pagination from "components/UI/pagination/Pagination";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { initialFilter } from "../DashboardUIHelper";
import CustomSpinner from "components/layout/Loader/Spinner";
import NoRecordFound from "utils/noRecordFound";
import "./style.scss";


export default function Vouchers() {
  const dispatch = useDispatch();
  const history = useHistory();

  const toastId = history.location.pathname;
  const [key, setKey] = useState("current");

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.voucherQueryParams,
      setQueryParams: dashboardPageUIContext.setVoucherQueryParams,
    };
  }, [dashboardPageUIContext]);

  const { listLoading, userVouchers, totalRecords } = useSelector(
    (state) => ({
      listLoading: state.stand.listLoading,
      userVouchers: state.stand.userVouchers,
      totalRecords: state.stand.totalVouchers,
    }),
    shallowEqual
  );

  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(
      Math.ceil(totalRecords / dashboardPageUIProps?.queryParams?.per_page)
    );
  }, [totalRecords]);

  const hanldeActiveKeyChange = (key) => {
    setKey(key);
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      status: key,
    });
  };

  useEffect(() => {
    dispatch(actions.getAllUserVouchers(dashboardPageUIProps.queryParams));
  }, [key, dashboardPageUIProps.queryParams]);

  useEffect(() => {
    return () => {
      setKey("current");
      dashboardPageUIProps.setQueryParams({
        ...initialFilter,
        status: key,
      });
    };
  }, []);

  return (
    <div className="trophies-dashboard row">
      <div className="pb-4">
        <h6 className="d-content-inner-title font-roboto mb-0">Vouchers</h6>
      </div>
      <div className="my-land mt-2">
        <div className="tabs">
          <Tabs
            transition
            defaultActiveKey="current"
            className="mb-3"
            onSelect={(k) => hanldeActiveKeyChange(k)}
          >
            <Tab eventKey="current" title="Current">
              <div className="row voucher-boxes mt-4 pt-3">
                {userVouchers && userVouchers?.length
                  ? userVouchers.map((voucher) => (
                    <>
                      <div
                        className="col-xl-6 col-lg-6 col-xxl-4 mb-4 faide-in item"
                        key={voucher.id}
                      >
                        <div className="overflow-hidden">
                          <div className="vchr-box">
                            <div className="vchr-top-content-holder">
                              <div className="upperdetails">
                                <Percent />
                                <div className="mt-neg-2">
                                  <div className="mb-1 fs-18 fw-bold oneline-text">
                                    {voucher.name}
                                  </div>
                                  <div className="fs-14">
                                    <span className="text-tertiary me-1">
                                      EXP:{" "}
                                    </span>
                                    <span>
                                      {voucher?.expiry_type === "max_usage"
                                        ? `${voucher.max_usage} users limit`
                                        : voucher?.expiry_date}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {voucher.discount_type === "percentage" ? (
                                <div className="discount">
                                  {voucher.discount}% Off
                                </div>
                              ) : (
                                <div className="discount">
                                  ${voucher.discount} Off
                                </div>
                              )}
                            </div>

                            <hr className="vchr-box-sep my-0" />
                            <div className="vchr-bottom">
                              <button
                                className="btn btn-secondary"
                                onClick={debounce(() => {
                                  navigator.clipboard
                                    .readText()
                                    .then((text) => {
                                      if (text !== voucher?.code) {
                                        navigator.clipboard.writeText(
                                          voucher?.code
                                        );
                                        toastHandler(
                                          "Code Copied Successfully",
                                          "success"
                                        );
                                      } else {
                                        toastHandler(
                                          "Already Copied",
                                          "error",
                                          toastId
                                        );
                                      }
                                    });
                                }, 1000)}
                              >
                                USE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                  : !listLoading ? (
                    <NoRecordFound message="No voucher found" />
                  ) : (
                    <CustomSpinner />
                  )}
                {userVouchers && userVouchers?.length ? (
                  <div className="inner-container">
                    <Pagination
                      totalRecords={totalRecords}
                      uiProps={dashboardPageUIProps}
                    />
                  </div>
                ) : null}
              </div>
            </Tab>
            <Tab eventKey="past" title="Past">
              <div className="row voucher-boxes past-boxes mt-4 pt-3">
                {userVouchers && userVouchers?.length ? (
                  userVouchers.map((voucher) => (
                    <>
                      <div className="col-xl-6 col-lg-6 col-xxl-4 mb-4 fade-in ">
                        <div className="overflow-hidden">
                          <div className="vchr-box">
                            <div className="upperdetails">
                              <Percent />
                              <div className="mt-neg-2">
                                <div className="mb-1 fs-18 fw-bold oneline-text">
                                  {voucher?.name}
                                </div>
                                <div className="fs-14">
                                  <span className="text-tertiary me-1">
                                    Valid Upto:{" "}
                                  </span>
                                  <span>{voucher?.expiry_date || `${voucher?.max_usage} users limit`}</span>
                                </div>
                              </div>
                            </div>
                            <hr className="vchr-box-sep my-0" />
                            <div className="text-end vchr-bottom">
                              <button className="btn disabled">EXPIRED</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : !listLoading ? (
                  <NoRecordFound message="No voucher found" />
                ) : (
                  <CustomSpinner />
                )}
                {userVouchers && userVouchers?.length ? (
                  <div className="inner-container">
                    <Pagination
                      totalRecords={totalRecords}
                      uiProps={dashboardPageUIProps}
                    />
                  </div>
                ) : null}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
