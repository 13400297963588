import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import FaqImage1 from "../../../../assets/images/faq-1.png";
import FaqImage2 from "../../../../assets/images/faq-2.png";
import { getHunterQuestions, getLandownerQuestions } from "modules/dashboard/_redux/reviews/reviewActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomSpinner from "components/layout/Loader/Spinner";


export default function FormModal({ show, setShow }) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHunterQuestions())
        dispatch(getLandownerQuestions())
    }, [])

    const {
        listLoading,
        hunterQuestion,
        landowerQuestion } = useSelector(
            (state) => ({
                hunterQuestion: state.review.hunterQuestion,
                listLoading: state.review.listLoading,
                landowerQuestion: state.review.landowerQuestion,
            }),
            shallowEqual
        );

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            className="faq-modal"
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                <div className="col-12">
                    <h4>Review form</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    listLoading ? <CustomSpinner /> :
                        <>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <h4 className="d-flex justify-content-center">Hunter Questions:</h4>
                                </div>
                                <div className="col-lg-5">
                                    <div className="img">
                                        <img src={FaqImage1} alt="" className="" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="content">
                                        <div className="inner-content">
                                            <ul>
                                                {
                                                    hunterQuestion?.map((item, index) => {
                                                        return (
                                                            <li key={`ln-pg-hn-qs${index}`}>
                                                                <span>{item?.question}</span>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="d-flex justify-content-center">Landowner Questions:</h4>
                                </div>
                                <div className="col-lg-7">
                                    <div className="content">
                                        <div className="inner-content">
                                            <ul>
                                                {
                                                    landowerQuestion?.map((item, index) => {
                                                        return (
                                                            <li key={`ln-pg-ln-${index}`}>
                                                                <span>{item?.question}</span>
                                                            </li>)
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="img">
                                        <img src={FaqImage2} alt="" className="" />
                                    </div>
                                </div>

                            </div>
                        </>
                }
            </Modal.Body>
        </Modal >
    )
}