import Pagination from "components/UI/pagination/Pagination";
import ReviewItem from "modules/dashboard/pages/reviews/reviewItem";
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/landingPageActions";
import { useLandingPageUIContext } from "../LandingPageUIContext";
import NoRecordFound from "utils/noRecordFound";
import "./style.scss";

export default function Reviews({ standId }) {
  const dashboardPageUIContext = useLandingPageUIContext();
  const dispatch = useDispatch();

  const { reviews, totalRecords } = useSelector(
    (state) => ({
      reviews: state.landingPage.standReviews,
      totalRecords: state?.landingPage?.totalReviewsRecords,
    }),
    shallowEqual
  );

  const [trophies, setTrophies] = useState([]);

  useEffect(() => {
    if (trophies && reviews) setTrophies([...trophies, ...reviews]);
  }, [reviews]);

  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.reviewsQueryParams,
      setQueryParams: dashboardPageUIContext.setReviewsQueryParamsBase,
    };
  }, [dashboardPageUIContext]);

  useEffect(() => {
    dispatch(
      actions.fetchStandReviews(standId, dashboardPageUIProps.queryParams)
    );
  }, [dashboardPageUIProps.queryParams]);

  return (
    <>
      <div className="reviews">
        {reviews && reviews?.length > 0 ? (
          reviews?.map((review, index) => (
            <ReviewItem key={index} review={review} callerStandDetail={true} />
          ))
        ) : 
          <NoRecordFound message="No review yet" />
        }
      </div>
      {reviews && reviews?.length > 0 ? (
        <div className="inner-container">
          <Pagination
            totalRecords={totalRecords}
            uiProps={dashboardPageUIProps}
          />
        </div>
      ) : null}
    </>
  );
}
