import { standSlice, callTypes } from "./standSlice";
import * as requestFromServer from "./standCrud";
import toastHandler from "components/UI/toastHandler/toastHandler";
const { actions } = standSlice;

export const fetchStandsCapacity = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getStandCapacity();
    if (response.status) {
      dispatch(
        actions.standCapacitiesFetched({
          entities: response.data,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};

export const getAllSiteTypes = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getAllSiteTypes(queryParams);
    if (response.status) {
      dispatch(
        actions.siteTypesFetched({
          entities: response.data,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};

export const getAllSpecies = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getAllSpecies(queryParams);
    if (response.status) {
      dispatch(
        actions.speciesFetched({
          entities: response.data,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};

export const getAllUserVouchers = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getAllUserVouchers(queryParams);
    if (response.status) {
      dispatch(
        actions.userVouchersFetched({
          totalEntities: response.total_records || 0,
          entities: response.data,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};
export const fetchStates = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getAllStates();
    if (response.status) {
      dispatch(
        actions.getAllStatesFetched({
          entities: response.data,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};

export const handleAddressComponentStatus = (status) => async (dispatch) => {
  dispatch(actions.AddressStatusChanged(status))
};

export const getStandById = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.standDetailsFetched({ selectedStand: null }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getStandById(id);
    if (response.status) {
      const stand = response.data;
      dispatch(actions.standDetailsFetched({ selectedStand: stand }));
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return 0;
  }
};

export const getStandDetails = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.standDetailFetched({ selectedStand: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getStandDetails(id);
    if (response.status) {
      const stand = response.data;
      dispatch(actions.standDetailFetched({ selectedStand: stand }));
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return 0;
  }
};
export const clearEntities = () => (dispatch) => {
  dispatch(actions.clearEntities());
};

export const deleteStand = (id, reload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .deleteStand(id)
    .then((res) => {
      if (res.status) {
        dispatch(actions.standDeleted());
        toastHandler("Listing Deleted Successfully", 'success');
        reload()
      }
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        toastHandler(error.response.data.message, 'error');
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      reload()
    });
};

export const toggleStandStatus = (id, body, reload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .toggleStand(id, body)
    .then((res) => {
      if (res.status) {
        dispatch(actions.standToggled());
        if (body.active == 1) {
          toastHandler("Listing successfully activated", 'success');
          reload()
        } else {
          toastHandler("Listing successfully deactivated", 'success');
          reload()
        }
      }
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        toastHandler(error.response.data.message, 'error');
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getStands = (queryParams, source) => async (dispatch) => {
  if (queryParams.status == "inactive") {
    dispatch(getInActiveStands(queryParams, source))
  }
  if (queryParams.status == "all" || queryParams.status == "approved" ||
    queryParams.status == "pending" || queryParams.status == "rejected") {
    dispatch(getActiveStands(queryParams, source))
  }
  if (queryParams.status == "reserved") {
    dispatch(getBookedStands(queryParams, source))
  }
  if (queryParams.status == "history") {
    dispatch(getBookingHistory(queryParams, source))
  }
};

export const getActiveStands = (queryParams, source) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    let response = await requestFromServer.getStands(queryParams, source);
    if (response.status) {
      dispatch(
        actions.standsFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};

export const getBookedStands = (queryParams, source) => async (dispatch) => {
  dispatch(actions.bookedStandRequest());
  try {
    let response = await requestFromServer.getStands(queryParams, source);
    if (response.status) {
      dispatch(
        actions.bookedStandFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.bookedStandRequestFailed({ error: error?.response?.data?.message || "Something went wrong" }));
    }
    return 0;
  }
};

export const getBookingHistory = (queryParams, source) => async (dispatch) => {
  dispatch(actions.bookingHistoryRequest());
  try {
    let response = await requestFromServer.getStands(queryParams, source);
    if (response.status) {
      dispatch(
        actions.bookingHistoryFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.bookedStandRequestFailed({ error: error?.response?.data?.message || "Something went wrong" }));
    }
    return 0;
  }
};

export const getInActiveStands = (queryParams, source) => async (dispatch) => {
  dispatch(actions.inActiveStandRequest());
  try {
    const response = await requestFromServer.getInactiveStands(queryParams, source);
    if (response.status) {
      dispatch(
        actions.inActiveStandFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.inActiveStandRequestFailed({ error: error?.response?.data?.message || "something went wrong" }));
    }
    return 0;
  }
};

export const addFollowStands = (id, toastId) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    let response = [];
    response = await requestFromServer.addFollowStands(id);
    if (response.status) {
      dispatch(actions.followStandAdded());
      toastHandler(response?.message, "success", toastId);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};

export const getFollowedStands = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    let response = await requestFromServer.getFollowedStands(queryParams);
    if (response.status) {
      dispatch(
        actions.standsFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};

export const updateDraftStand = (stand, id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  try {
    const response = await requestFromServer.updateDeaftStand(stand, id);

    if (response.status) {
      toastHandler(response.message, "success");
      dispatch(
        actions.updatedDraftStand({
          totalCount: 10,
          entity: response.data,
        })
      );
      return 1;
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
  }
};

export const getStandReservedDates = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(
      actions.reservedDatesForStandFetched({ reservedDates: null })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getStandReservedDates(id);
    if (response.status) {
      const reservedDates = response.data;
      dispatch(
        actions.reservedDatesForStandFetched({ reservedDates: reservedDates })
      );
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return 0;
  }
};

export const fetchStandReservations =
  (standId, queryParams) => async (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    try {
      const response =
        await requestFromServer.getStandReservations(
          standId,
          queryParams
        );
      if (response.status) {
        const standReservations = response.data;
        dispatch(
          actions.standReservationsFetched({
            standReservations: standReservations,
            totalRecords: response.total_records
          })
        );
      }
    } catch (error) {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return 0;
    }
  };
export const clearStandsList = () => (dispatch) => {
  dispatch(actions.clearEntities());
};

export const fetchHuntingDetails = (standId, queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getHuntingDetails(standId, queryParams);
    if (response.status) {
      const huntingDetails = response.data;
      dispatch(
        actions.huntingDetailsFetched({
          details: huntingDetails
        })
      );
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return 0;
  }
};

export const filterUser = (params) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.filterUser(params);
    if (response.status) {
      let status = 200;
      if (response.message?.includes == "Successfully") {
        status = 200;
      } else if (response.message?.includes("No Record")) {
        status = 404;
      }
      const filteredUsers = response.data;
      dispatch(
        actions.filteredUserFetched({
          details: filteredUsers,
          statusCode: status
        })
      );
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return 0;
  }
};

export const clearUsers = () => async (dispatch) => {
  dispatch(actions.ClearFetchedUsers())
}

export const changeInviteStatus = (id, body, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.changeInviteStatus(id, body);
    if (response.status) {
      dispatch(actions.standStatusChanged());
      onSuccess(response?.message)
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    onError(error?.response?.data?.message)
    return 0;
  }
};

export const standInvitation = (id, body, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.standInvitation(id, body);
    if (response.status) {
      dispatch(actions.standTransfered());
      onSuccess()
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.message, "error");
    }
    onError()
    return 0;
  }
};

export const verifyStandInvitation = (body, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.verifyStandInvitation(body);
    if (response.status) {
      dispatch(actions.standInvitationVerified());
      onSuccess(response)
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
    onError(error?.response?.data?.message)
    return 0;
  }
};

export const standInvitationAction = (body, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.standInvitationAction(body);
    if (response.status) {
      dispatch(actions.standStatusChanged());
      onSuccess(response?.message)
      return 1;
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
    onError()
    return 0;
  }
};

export const cancelStandTransferInvitaion = (id, onDeleteSuccess, onDeleteError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.cancelStandTransferInvitaion(id);
    if (response.status) {
      dispatch(actions.standCancelled());
      onDeleteSuccess()
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
    onDeleteError()
    return 0;
  }
};