import InvitationNotificationModal from "components/UI/modals/invitationNotification-modal/InvitationNotificationModal";
import { verifyAdditionalHunterInvitation } from "modules/dashboard/_redux/reservations/reservationActions";
import { verifyStandInvitation } from "modules/dashboard/_redux/stand/standActions";
import { HunterNotificationModal } from "modules/dashboard/pages/my-reservation/additionalHunter/notifications/hunterNotificationModal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


const InvitationCodeRedirector = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(window.location.search);
    let invitationCode = queryParams.get("invitation_code");

    const [openInvitationNotificationModal, setOpenInvitationNotificationModal] = useState(false);
    const [openHunterInvitationNotificationModal, setOpenHunterInvitationNotificationModal] = useState(false);
    const [invitationData, setInvitationData] = useState("");
    const [invitationCodeValue, setInvitationCodeValue] = useState("");
    const [value, setValue] = useState(0);

    const reload = () => {
        setValue(value + 1)
    }
  
    useEffect(() => {
      if (window.location.pathname.includes("my-profile/my-stands")) {
        if (invitationCode) {
            setInvitationCodeValue(invitationCode);
            dispatch(verifyStandInvitation({ invitation_code: invitationCode }, onSuccess,  onError));
        }
      } else if (window.location.pathname.includes("my-profile/my-reservations")) {
        if (invitationCode) {
            setInvitationCodeValue(invitationCode);
            dispatch(verifyAdditionalHunterInvitation({ invitation_code: invitationCode }, onSuccess,  onError));
        }
      }
    }, [invitationCode, history]);

    const onError = () => {
        if (window.location.pathname.includes("my-profile/my-stands")) {
            setOpenInvitationNotificationModal(false);
            history.replace("/my-profile/my-stands");
        } else if (window.location.pathname.includes("my-profile/my-reservations")) {
            setOpenHunterInvitationNotificationModal(false);
            history.replace("my-reservations");
        }
    };
    
    const onSuccess = (response) => {
        setInvitationData(response?.data);
        if (window.location.pathname.includes("my-profile/my-stands")) {
            setOpenInvitationNotificationModal(true);
        } else if (window.location.pathname.includes("my-profile/my-reservations")) {
            setOpenHunterInvitationNotificationModal(true);
        }
    };
  
    const handleCloseModal = () => {
        if (window.location.pathname.includes("my-profile/my-stands")) {
            setOpenInvitationNotificationModal(false);
            history.replace("/my-profile/my-stands");
        } else if (window.location.pathname.includes("my-profile/my-reservations")) {
            setOpenHunterInvitationNotificationModal(false);
            history.replace("my-reservations");
        }
    };

  return (
    <>
        {openInvitationNotificationModal && (
            <InvitationNotificationModal
                show={openInvitationNotificationModal}
                setShow={setOpenInvitationNotificationModal}
                onHide={handleCloseModal}
                invitationData={invitationData}
                invitationCode={invitationCodeValue}
                reload={reload}
            />
        )}

        {openHunterInvitationNotificationModal && (
            <HunterNotificationModal
                show={openHunterInvitationNotificationModal}
                setShow={setOpenHunterInvitationNotificationModal}
                onHide={handleCloseModal}
                invitationData={invitationData}
                invitationCode={invitationCodeValue}
                reload={reload}
            />
        )}
    </>
  );
};

export default InvitationCodeRedirector;