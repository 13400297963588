import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss"
import { Calendar } from 'react-calendar';
import moment from "moment";

const CalendarModal = ({ show, handleClose, startDate, onDateSelect }) => {

  const [selectedDate, setSelectedDate] = useState(startDate);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDone = () => {
    localStorage.setItem("selectedStartDate", selectedDate);
    onDateSelect(selectedDate);
    handleClose();
  };

  return (
    <Modal className="calendar-modal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Calendar
          calendarType={"ISO 8601"}
          minDate={moment(selectedDate)._d}
          maxDate={moment(selectedDate)?.subtract(1, 'day')?.add(3, "y")?._d}
          onChange={handleDateChange}
          defaultActiveStartDate={moment(selectedDate)._d}
          
        />
        <button className='done-btn' type='submit' onClick={handleDone}>DONE</button>
      </Modal.Body>
    </Modal>
  );
}

export default CalendarModal;
