import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { Link, useHistory } from "react-router-dom";
import Timer from "../../../../../assets/images/comment-timer.svg";
import Pagination from "components/UI/pagination/Pagination";
import { useDashboardUIContext } from "../../DashboardUIContext";
import avatar from "assets/images/avatar.png";
import { getUserReviews } from "../../../_redux/reviews/reviewActions";
import NoRecordFound from "utils/noRecordFound";
import Spinner from "components/UI/Spinner.js";


export default function UserReview() {

  const history = useHistory();
  const dispatch = useDispatch();

  const { reviewsList, listLoading, totalRecords } = useSelector(
    (state) => ({
      reviewsList: state.review.reviewsList,
      totalRecords: state.review.totalReviews,
      listLoading: state.review.listLoading,
    }),
    shallowEqual
  );

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.userReviewQueryParams,
      setQueryParams: dashboardPageUIContext.setUserReviewQueryParams,
    };
  }, [dashboardPageUIContext]);

  const handleBack = () => {
    if (history.location.state && history.location.state.map === true) {
      const state = history.location.state;
      history.push({
        pathname: "/stand",
        search:
          "?" +
          new URLSearchParams({ coordinate: state.coordinate }).toString() +
          "&" +
          new URLSearchParams({ map: true }).toString() +
          "&" +
          new URLSearchParams({ zoom: state.zoom }).toString(),
      });
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    const userReviewId = history.location.pathname.split("/")[3];
    dispatch(getUserReviews(userReviewId, dashboardPageUIProps.queryParams));
  }, [dashboardPageUIProps.queryParams]);

  const user = reviewsList[0] && reviewsList[0]?.reviews.filter(item => {
    return item.type == "hunter"
  })[0]

  const title = reviewsList[0]?.reservation?.user?.first_name + " " + reviewsList[0]?.reservation?.user?.last_name

  useEffect(() => {document.title = 'Reviews | iHunt'}, []);
  
  return (
    <>
      <div className="reviews-reconsider  row">
        <div className="pb-4">
          <h6 className="d-content-inner-title font-roboto mb-0">Reviews</h6>
        </div>
          <div className="top-bar mb-6 d-flex justify-content-between align-items-center flex-wrap">
            <div className="top" onClick={handleBack}>
              <i className="bi bi-arrow-left-short me-2"></i>
              <span>Go back</span>
            </div>
          </div>
          {listLoading ? null :
          reviewsList.length ? (
            <div className="reviewer-profile">
              <img src={user?.user?.image || reviewsList[0]?.reservation?.user?.image || avatar} alt=""></img>
              <div>
                <div className="name">
                  <b> {title} </b>
                </div>
                <p>
                  There are {totalRecords} Reviews about {title}
                </p>
              </div>
            </div>
          ) : null}
          <div>
            {listLoading ? <Spinner /> :
            reviewsList?.length ?
              reviewsList.map((review) => {
                const hunterReview = review.reviews.filter(item => {
                  return item.type == "hunter"
                })[0];

                const landownerReview = review.reviews.filter(item => {
                  return item.type == "landowner"
                })[0];

                return (
                  <div className="comment-section w-100">
                    <div className="comment-header">
                      <p>{review?.reservation?.stand_detail.title}</p>
                      <Link
                        to={`/stand/${review?.reservation?.stand_detail.id}`}
                        className="view-details"
                      >
                        View details
                      </Link>
                    </div>
                    <div className="comment-body">
                      {
                        landownerReview && (
                          <>
                            <div className="reviewer-profile">
                              <div className="user-profile">
                                <img
                                  src={landownerReview?.user.image || avatar}
                                  alt=""
                                ></img>
                                <div className="name">
                                  {landownerReview?.user.first_name +
                                    " " +
                                    landownerReview?.user.last_name}
                                </div>
                              </div>
                              <div className="rating-section">
                                <i className="bi text-star fs-20 bi-star-fill  "></i>
                                <p className="rate m-0">
                                  {landownerReview?.rating}
                                </p>
                              </div>
                            </div>
                            {landownerReview?.review ? <p className="comment">{landownerReview?.review}</p> : null}
                            <span className="comment-date">
                              <img src={Timer} className="timer-icon" alt=""></img>
                              {landownerReview?.created_at}
                            </span>
                          </>
                        )
                      }
                      {
                        hunterReview && (<div className="post-comment-section">
                          <div className="user-profile">
                            <div className="d-flex flex-row align-items-center">
                              <img
                                src={hunterReview?.user.image || avatar}
                                alt=""
                              ></img>
                              <div className="name">
                                {hunterReview?.user?.first_name +
                                  " " +
                                  hunterReview?.user.last_name}
                              </div>
                            </div>
                            <div className="rating-section">
                              <i className="bi text-star fs-20 bi-star-fill  "></i>
                              <p className="rate m-0">
                                {hunterReview?.rating}
                              </p>
                            </div>
                          </div>
                          {hunterReview?.review ? <p className="reply"> {hunterReview?.review}</p> : null}
                          <span className="comment-date">
                            <img src={Timer} className="timer-icon" alt=""></img>
                            {hunterReview?.created_at}
                          </span>
                        </div>)
                      }

                    </div>
                  </div>
                );
              }) : (
                <NoRecordFound message="No review yet" />
              )}
          </div>
      </div>
      {listLoading ? null : 
      reviewsList?.length > 0 ? (
      <div className="inner-container">
        <Pagination totalRecords={totalRecords} uiProps={dashboardPageUIProps} />
      </div>
      ) : null}
    </>
  );
}
