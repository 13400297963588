import React from "react";
import "./style.scss";
import moment from "moment";

export default function WeeklyWeather({ weatherReport }) {
  return (
    <div className="weekly-weather table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col" className="text-left">Days</th>
            <th scope="col" className="text-left">Temperature</th>
            <th scope="col" className="text-left">Feels Like</th>
            <th scope="col" className="text-left">Sunrise</th>
            <th scope="col" className="text-left">Sunset</th>
            <th scope="col" className="text-left"></th>
          </tr>
        </thead>
        <tbody>
          {weatherReport && weatherReport?.daily?.length
            ? weatherReport?.daily?.map((day, index) => (
                <tr key={index}>
                  <td>
                    <div className="date">
                      {moment.unix(day.dt).format("MMM D YYYY")}
                    </div>
                  </td>
                  <td>
                    <div className="temp">
                      {" "}
                      <span className="fw-bold">
                        {day.temp.day && Math.round(day.temp.day)}
                      </span>
                      <sup>o</sup>
                    </div>
                  </td>
                  <td>
                    <div className="temp">
                      {" "}
                      <span className="fw-bold">
                        {day.feels_like.day && Math.round(day.feels_like.day)}
                      </span>
                      <sup>o</sup>
                    </div>
                  </td>
                  <td>
                    <div className="sunrise">
                      <i className="bi bi-arrow-bar-up me-2"></i>
                      {moment.unix(day.sunrise).format("hh:mm A")}
                    </div>
                  </td>
                  <td>
                    <div className="sunset">
                      <i className="bi bi-arrow-bar-down me-2"></i>{" "}
                      {moment.unix(day.sunset).format("hh:mm A")}
                    </div>
                  </td>
                  <td>
                    <div>
                      <img src={`https://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`} className="weather-image" alt="" />
                    </div>
                  </td>
                </tr>
              ))
            : "N/A"}
        </tbody>
      </table>
    </div>
  );
}
