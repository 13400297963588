import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";
export default function StandItemSkeleton(props) {

  const mapViewskeleton = (
    <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
      <div className="col-lg-5 col-md-5 col-sm-6">
        <Skeleton height={200} />
      </div>
      <div className="course-details col-lg-7 col-md-7 col-sm-6">
        <div className="detail-top-skeleton">
          <div className="col-md-12">
            <h5>
              <Skeleton />
            </h5>
            <p>
              <Skeleton />
            </p>
            <p>
              <Skeleton />
            </p>
          </div>
        </div>
        <div className="detail-btn">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Skeleton />
        </div>
      </div>
    </div>
  );

  const skeleton = (
    <div className="col-lg-4 col-md-6 col-sm-12">
      <div className="carusal-item">
        <Skeleton height={300} />
      </div>
      <div className="course-details">
        <div className="detail-top-skeleton">
          <div className="row col-md-12">
            <h5>
              <Skeleton />
            </h5>
            <p>
              <Skeleton />
            </p>
          </div>
        </div>
        <div className="detail-btn">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Skeleton />
        </div>
      </div>
    </div>
  );

  const numberOfSkeleton = Array.from({ length: 3 }, () => skeleton);
  return (
    <>
      {props.showMap
        ?  window.innerWidth < 768 ? skeleton : mapViewskeleton 
        : numberOfSkeleton.map((item) => {
          return item;
        })}
    </>
  );
}
