import React, { useState } from "react";
import LiabilityImg from "./../../../../assets/images/liability-mobile.png";
import PlaceHolderImage from "./../../../../assets/images/liability-mobile-1.jpg";
import LandingPageWavierModal from "./wavierModal";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Liability() {
    const [openModal, setOpenModal] = useState(false)

    return (
        <>
            <section className="liability-section">
                <div className="inner-container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="d-flex justify-content-center pb-2 text-white">What About Liability?</h3>
                        </div>
                        <div className="col-xxl-8 col-lg-11 col-md-12 mx-auto">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-8 col-10 pb-md-0 pb-4 mx-auto">
                                    <div className="left">
                                        <div className="left-inner">
                                            <LazyLoadImage
                                                src={LiabilityImg}
                                                alt=""
                                                className="lazy-load-image"
                                                placeholderSrc={PlaceHolderImage}
                                                visibleByDefault={LiabilityImg}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7">
                                    <div className="right">
                                        <div className="right-inner">
                                            <p>
                                                Worried about dealing with hunters? Worried about Liability insurance? No Problem! We have you covered. When you list your property with us, you automatically get liability insurance for your property, included at no extra charge. Each hunter signs a very stringent liability waiver ( See Link ) to access your property and this is kept on file at iHunt. We take care of all the paperwork for you so the only thing you have to do is watch the money roll into your account! Hunters choose the days they want to hunt that you have available, or book your property for the whole season. You can choose to lease your property by the day or only by the whole season. You will get email notifications as each booking occurs. Again, we give you the tools you need to lease your land the way you want to lease it. Stop sweating all the hard details! We have you covered!
                                            </p>
                                            <button className="btn btn-primary text-white text-uppercase" onClick={() => { setOpenModal(true) }}>Hunter waiver</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LandingPageWavierModal show={openModal} setShow={setOpenModal} type="hunter" />
        </>
    );
}
