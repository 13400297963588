import React, { useEffect } from "react";

import "./style.scss";
import AboutiHunt from "./AboutiHunt";
import WorkingiHunt from "./WorkingiHunt";
import DirectioniHunt from "./DirectioniHunt";
import Liability from "./Liability";
import LongContract from "./LongContract";
import Reviews from "./Review";
import UserStory from "./UserStory";
import ContactSection from "./ContactSection";

export default function LandownerPage() {
    useEffect(() => {document.title = 'For Landowners | iHunt'}, []);
    return (
        <div className="landowner-main">
            <AboutiHunt />
            <WorkingiHunt />
            <DirectioniHunt />
            <Liability />
            <LongContract />
            <Reviews />
            <UserStory />
            <ContactSection />
        </div>
    );
}
