import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import avatar from "../../../../assets/images/avatar.png";
import { FloatingLabel, Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { handleAddressComponentStatus } from "modules/dashboard/_redux/stand/standActions";
import Spinner from "components/UI/Spinner.js";
import { Link } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

const ProfileInfo = ({ formik,
  handleBack,
  error,
  loading,
  isPlaceSelected,
  setIsPlaceSelected }) => {
  const [imgSrc, setImgSrc] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const { isAddressSelected, } = useSelector(
    (state) => ({
      isAddressSelected: state.stand.isAddressSelected,
    }),
    shallowEqual
  );

  const handleAddressInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };


  const showApplyButton = () => {
    if (isPlaceSelected) {
      return !(formik.isValid && formik.dirty)
    } else {
      return true
    }
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="form-box">
      <div className="image-container">
        {imgSrc ? (
          <img src={imgSrc} onError={(ev) => (ev.target.src = avatar)} alt="profile" />
        ) : (
          <img src={avatar} alt="profile" />
        )}
        <label htmlFor="profile">
          <i
            className="fal fa-camera"
            aria-hidden="true"
          ></i>
        </label>
        <input
          type="file"
          id="profile"
          name="profile"
          accept="image/png, image/jpeg"
          onChange={async (event) => {
            const imageFile = event.target.files[0];
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            try {
              const compressedFile = await imageCompression(imageFile, options);
              setFile(compressedFile);
              setImgSrc(URL.createObjectURL(compressedFile));
            } catch (error) {
              setFile(null);
              setImgSrc("");
            }
          }}
        />
      </div>
      {error ? (
        <Form.Control.Feedback type="text-center d-block invalid">
          {error}
        </Form.Control.Feedback>
      ) : null}
      <Form onSubmit={formik.handleSubmit}>
        <FloatingLabel
          className="mb-3"
          controlId="floatingInputGrid"
          label="Address"
        >
          <Autocomplete
            name="address_field"
            id="address_field"
            className="form-control shadow-none"
            onPlaceSelected={(place, value) => {
              formik?.setFieldValue('address', value?.value)
              formik?.setFieldValue('city', place.address_components.find(component => component.types?.includes('locality'))?.long_name || "");
              formik?.setFieldValue("zipcode", place.address_components.find(component => component.types?.includes('postal_code'))?.long_name || "")
              formik?.setFieldValue("state_name", place.address_components.find(component => component.types?.includes('administrative_area_level_1'))?.long_name || "")
              formik?.setFieldValue("state_code", place.address_components.find(component => component.types?.includes('administrative_area_level_1'))?.short_name || "")
              formik?.setFieldValue("country", place.address_components.find(component => component.types?.includes('country'))?.long_name)
              dispatch(handleAddressComponentStatus(true));
              setIsPlaceSelected(true);
            }}
            componentRestrictions={{ country: "za" }}
            onChange={(e) => {
              e.preventDefault();
              if (!e.target.value) {
                dispatch(handleAddressComponentStatus(false));
                setIsPlaceSelected(false);
                formik?.setFieldValue('city', "");
                formik?.setFieldValue("zipcode", "")
                formik?.setFieldValue("state_name", "")
                formik?.setFieldValue("country", "")
              }
              formik.setFieldValue("address", e.target.value)
            }}
            onKeyPress={handleAddressInputKeyPress}
            value={formik.values.address}
            options={{
              fields: ["address_components", "geometry", "place_id"],
              types: [],
            }}
            placeholder="Enter a Location..."
          />
          {formik?.touched?.address && formik?.errors?.address && (
            <Form.Control.Feedback type="d-block invalid">
              {formik.errors.address}
            </Form.Control.Feedback>
          )}
        </FloatingLabel>
        {isAddressSelected ?
          <>
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="Zipcode"
            >
              <Form.Control
                type="string"
                name="zipcode"
                placeholder="Zipcode"
                onChange={formik.handleChange}
                value={formik.values.zipcode}
              />
              {formik?.touched?.zipcode && formik?.errors?.zipcode && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.zipcode}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <div className="row mx-auto">
              <div className="col-md-6 pe-1 ps-0">
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingInputGrid"
                  label="City"
                >
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    onChange={formik.handleChange}
                    value={formik.values.city || "N/A"}
                    disabled
                  />
                  {formik?.touched?.city && formik?.errors?.city && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.city}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </div>
              <div className="col-md-6 ps-1 pe-0">
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingSelectGrid"
                  label="State"
                >
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="State"
                    onChange={formik.handleChange}
                    value={formik.values.state_name || "N/A"}
                    disabled
                  />
                  {formik?.touched?.state_name && formik?.errors?.state_name && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.state_name}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </div>
            </div>
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="Country"
            >
              <Form.Control
                type="text"
                name="country"
                placeholder="Country"
                onChange={formik.handleChange}
                value={formik.values.country || "N/A"}
                disabled
              />
              {formik?.touched?.country && formik?.errors?.country && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.country}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </> : null}

        <button
          className="btn btn-primary text-white mt-4 width-50 margin-right"
          onClick={handleBack}
          type="button"
        >
          BACK
        </button>
        <button
          className="btn btn-primary text-white mt-4 width-50"
          type="submit"
          disabled={showApplyButton()}
        >
          {loading ? <Spinner /> : "APPLY"}
        </button>
        <span className="mt-4 d-block">
          Already have an account? <Link to="/auth/login">Login</Link>
        </span>
      </Form>
    </div>
  );
};

export default ProfileInfo;
