import { ReactComponent as Pin } from "assets/icons/pindark.svg";
import { ReactComponent as Singh } from "assets/icons/singh.svg";
import CustomSpinner from "components/layout/Loader/Spinner";
import Pagination from "components/UI/pagination/Pagination";
import React, { useEffect, useMemo, useState } from "react";
import defaultImage from "../../../../assets/images/default.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import NoRecordFound from "utils/noRecordFound";
import * as actions from "../../_redux/trophies/trophyActions";
import { useDashboardUIContext } from "../DashboardUIContext";
import "./style.scss";

export default function TrophiesDashboard() {
  const dispatch = useDispatch();
  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.trophyQueryParams,
      setQueryParams: dashboardPageUIContext.setTrophyQueryParams,
    };
  }, [dashboardPageUIContext]);

  const { trophiesList, totalRecords, listLoading } = useSelector(
    (state) => ({
      trophiesList: state.trophy.trophiesList,
      totalRecords: state.trophy.totalTrophies,
      listLoading: state.trophy.listLoading,
    }),
    shallowEqual
  );

  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(
      Math.ceil(totalRecords / dashboardPageUIProps?.queryParams?.per_page)
    );
  }, [totalRecords]);
  useEffect(() => {
    dispatch(actions.getTrophies(dashboardPageUIProps.queryParams));
  }, [dashboardPageUIProps.queryParams]);

  return (
    <div className="trophies-dashboard row">
      <div className="pb-4">
        <h6 className="d-content-inner-title font-roboto mb-0">Trophies</h6>
      </div>
      {trophiesList && trophiesList?.length > 0 ? (
        <>
          {trophiesList?.map((trophy, index) => (
            <div className="col-xxl-4 col-xl-6 fade-in item" key={index}>
              <div className="trophy-box bg-sixth mb-4">
                <div className="p-4 d-flex align-items-center">
                  <div className="trophies-img">
                    <img src={trophy?.image || defaultImage} alt="" />
                    <Singh className="singh" />
                  </div>
                  <div>
                    <div className="mb-2 fs-18 fw-bold oneline-text">
                      {trophy?.stand?.title}
                    </div>
                    <p
                      className="mb-0 fs-14 text-tertiary twoline-text"
                      style={{ height: "2.8rem" }}
                    >
                      <Pin className="me-2 pin mw-12" />
                      {trophy?.stand?.address}
                    </p>
                  </div>
                </div>
                <hr className="my-0" />
                <div className="trophies-bottom d-inline-flex justify-content-between w-100 mt-2">
                  <div
                    className={`text-${trophy?.status === "rejected"
                      ? "rejected"
                      : trophy?.status === "approved"
                        ? "primary"
                        : "warning"
                      } d-flex align-items-center fs-14`}
                  >
                    <span
                      className={`dot-${trophy?.status === "rejected"
                        ? "secondary"
                        : trophy?.status === "approved"
                          ? "primary"
                          : "warning"
                        }`}
                    ></span>{" "}
                    {trophy?.status === "rejected"
                      ? "Rejected"
                      : trophy?.status === "approved"
                        ? "Approved"
                        : "Pending"}
                  </div>
                  <button className="btn" style={{ cursor: "default" }}>
                    {trophy?.harvest_date}
                  </button>
                </div>
              </div>
            </div>
          ))}

          {trophiesList && trophiesList?.length > 0 ? (
            <Pagination
              totalRecords={totalRecords}
              uiProps={dashboardPageUIProps}
            />
          ) : null}
        </>
      ) : trophiesList === null ? (
        <NoRecordFound message="No trophy found" />
      ) : listLoading ? (
        <CustomSpinner />
      ) : null}
    </div>
  );
}
