import React, { useState } from "react";
import FormModal from "./formModal";
import "./style.scss";


export default function Review() {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <section className="reviews-section">
                <div className="inner-container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="d-flex justify-content-center pb-2 text-white">Did We Mention Our 5-Star Review System?</h3>
                        </div>
                        <div className="col-lg-8 mx-auto">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="left">
                                        <div className="left-inner">
                                            <div className="box-reviews">
                                                <i className="bi text-star bi-star-fill me-2"></i>
                                                <i className="bi text-star bi-star-fill me-2"></i>
                                                <i className="bi text-star bi-star-fill me-2"></i>
                                                <i className="bi text-star bi-star-fill me-2"></i>
                                                <i className="bi text-star bi-star me-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="right">
                                        <div className="right-inner">
                                            <p>
                                                Landowners can review each hunter with our 5-star review system, so each hunter will have a track record of his performance across our platform. If a hunter has a subpar record, they will not be able to book your property based on the threshold you set. Landowners can view a hunter’s complete history from other landowners and only allow the people on your property that you want to have access. Once again, we are giving you full control and visibility of how you lease your property.
                                            </p>
                                            <button className="btn btn-primary text-white text-uppercase" onClick={() => setOpenModal(true)}>REVIEW FORM</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FormModal show={openModal} setShow={setOpenModal} />
        </>
    );
}
