import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./router/MainRouter";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./utils/scrollToTop";
import ChatListener from "./hoc/ChatHandle";
import * as actions from "./modules/auth/_redux/authActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import config from "config";
import { CancelationPolicyModal } from "components/UI/modals/cancelationPolicyModal";
import { openFourOThreeModal, openAuthenticationModal, openLoginModal } from "modules/landingPage/_redux/landingPageActions";
import JuniorHunterWelcomeModal from "components/UI/modals/juniorHunterWelcomeModal";
import AuthenticateModal from "components/UI/modals/authenticate-modal/AuthenticateModal";
import UnAuthorizedModal from "components/UI/modals/unauthorized-modal/UnAuthorizedModal";

ReactGA.initialize(config?.GA_TRACKING_ID);


function App() {

  const dispatch = useDispatch();

  const { token, user, userToken } = useSelector(
    (state) => ({
      user: state.auth.user,
      token: state.auth.token,
      userToken: state.auth.userToken
    }),
    shallowEqual
  );

  const { isForOthree, forOThreeMessage, authenticationRequired, isLogin, unAuthorizedMessage } = useSelector(state => state.landingPage)

  useEffect(() => {
    if (token && !user && !userToken) {
      dispatch(actions.loadUser());
    }
  }, [dispatch, token, user]);

  const onHide = () => {
    dispatch(openFourOThreeModal(false, ""))
  }

  const onHideUnAuthorizedModal = () => {
    dispatch(openLoginModal(false, ""))
  }

  return (
    <>
      {"serviceWorker" in navigator ? (
        <ChatListener>
          <BrowserRouter>
            <ScrollToTop />
            <MainRouter />
          </BrowserRouter>
        </ChatListener>
      ) : (
        <BrowserRouter>
          <ScrollToTop />
          <MainRouter />
        </BrowserRouter>
      )}

      <ToastContainer />
      <JuniorHunterWelcomeModal />

      <CancelationPolicyModal
        show={!authenticationRequired && isForOthree}
        title="Permission Denied"
        onHide={onHide}
        cancelationPolicy={forOThreeMessage} />

      <AuthenticateModal 
        show={authenticationRequired}
        onHide={() => dispatch(openAuthenticationModal(false))} />

      <UnAuthorizedModal
        show={isLogin}
        title="Permission Denied"
        onHide={onHideUnAuthorizedModal}
        message={unAuthorizedMessage} />
    </>
  );
}

export default App;
