import axios from "../../../services/axiosInstance";
import moment from "moment";

export function register(data) {
  const newData = {...data, current_date: moment().format("MMM DD YYYY")}
  return axios.post("auth/register", newData );
}

export function login(data) {
  return axios.post("auth/login", data);
}

export function logout() {
  return axios.get("user-logout");
}

export function me() {
  return axios.get("user-profile");
}

export function forgotPassword(data) {
  return axios.post("auth/forgot-password", data);
}

export function resetPassword(data) {
  return axios.post("auth/reset-password", data);
}

export function updateUserProfile(data) {
  return axios.put("update-profile", data);
}

export function uploadImage(data) {
  return axios.post("profile-image", data);
}

export function monthlyEmail(data) {
  return axios.post('enable-monthly-email', data);
}

export function changePassword(data) {
  return axios.patch("change-password", data);
}

export function reSendVerificationEmail() {
  return axios.post("auth/email/resend");
}

export function getLinkExpiryStatus(data) {
  return axios.get("auth/check-reset-password-token", {
    params: data
  });
}

export function getVerifyUrl(data) {
  return axios.get("auth/verify-url", {
    params: data
  });
}

export function authenticateUser(data) {
  return axios.post("validate-user", data);
}