import { useFormik } from "formik";
import React from 'react';
import Modal from "react-bootstrap/Modal";
import "./style.scss"
import { FloatingLabel, Form } from 'react-bootstrap';
import Spinner from "components/UI/Spinner.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as actions from "../../../../modules/dashboard/_redux/reservations/reservationActions";


const VoucherModal = ({ show, handleClose, formik, standId, setDiscount, setVoucherType, setVoucherId, subTotal }) => {
  const dispatch = useDispatch();

  const { verifyingVoucher } = useSelector(
    (state) => ({
      verifyingVoucher: state.reservation.verifyingVoucher,
    }),
    shallowEqual
  );

  const discountFormik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Voucher code is required"),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("code", values.code);
      dispatch(actions.verifyVoucher(data, standId, onSuccess, onError));
    },
  });

  const onSuccess = (data) => {
    let discountedValue = 0;
    if (data.discount_type === "percentage") {
      discountedValue = Math.round(
        (data.discount / 100) * subTotal
      );
    } else {
      discountedValue = data.discount;
    }
    formik.setFieldValue("discount", discountedValue);
    formik.setFieldValue("voucher_type", data.type);
    formik.setFieldValue("voucher_id", data.id);
    setDiscount(discountedValue)
    setVoucherType(data.type)
    setVoucherId(data.id)
    onHide();
  };

  const onError = (message) => {
    discountFormik.setFieldError("code", message);
  };

  const onHide = () => {
    discountFormik.resetForm();
    handleClose();
  };
  
  return (
    <Modal className="voucher-modal" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Voucher
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='voucher-form' onSubmit={discountFormik.handleSubmit}>
          <FloatingLabel controlId="floatingInputGrid" label="Enter Voucher Code">
            <Form.Control
              type="text"
              name="code"
              id="code"
              value={discountFormik.values.code}
              onChange={discountFormik.handleChange}
              className={`form-control fs-14`}
            />
          </FloatingLabel>
          {discountFormik?.touched?.code && discountFormik?.errors?.code && (
            <Form.Control.Feedback type="d-block invalid">
              {discountFormik.errors.code}
            </Form.Control.Feedback>
          )}

          <div className='modal-action-btns'>
            <button className='modal-action-btn outlined' type='button' onClick={onHide}>CANCEL</button>
            <button className='modal-action-btn filled' type='submit'>
            {verifyingVoucher ? (
              <Spinner />
            ) : (
              "APPLY"
            )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default VoucherModal;
