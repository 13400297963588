import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getGroupsById, assignGroups } from "modules/dashboard/_redux/groups/groupActions";
import Spinner from "components/UI/Spinner.js";
import Modal from "react-bootstrap/Modal";
import "./style.scss"

const AssignToGroupModal = ({ id, show, onHide, groups }) => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const dispatch = useDispatch();
  
  const { groupDetails } = useSelector(
    (state) => ({
      groupDetails: state.group.groupDetails,
      actionsLoading: state.group.actionsLoading,
      listLoading: state.group.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (show && id) {
      dispatch(getGroupsById(id));
      setIsSaveDisabled(true);
    }
  }, [show, id, dispatch]);

  useEffect(() => {
    if (groupDetails) {
      setSelectedGroups(groupDetails?.map(detail => detail?.id));
    }
  }, [groupDetails]);

  const toggleGroupSelection = (groupId) => {
    setSelectedGroups(prevSelectedGroups => {
      const newSelectedGroups = prevSelectedGroups.includes(groupId)
        ? prevSelectedGroups.filter(id => id !== groupId)
        : [...prevSelectedGroups, groupId];

      setIsSaveDisabled(false);
      
      return newSelectedGroups;
    });
  };

  const handleSave = () => {
    setLoading(true);
    dispatch(assignGroups(id, { group: selectedGroups }, onSuccess, onError));
  };

  const onSuccess = () => {
    setLoading(false);
    onHide();
  };

  const onError = () => {
    setLoading(false);
  };
  
  return (
    <Modal className="assign-to-group-modal" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Assign To Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="tags">
          {groups?.length > 0 && groups?.map((group, index) => (
            <button 
              key={index}
              className={selectedGroups.includes(group?.id) ? "tag selected" : "tag"}
              onClick={() => toggleGroupSelection(group?.id)}
            >
              {group?.name}
            </button>
          ))}
        </div>
        
        <button className="save-btn" type="submit" onClick={handleSave} disabled={isSaveDisabled}>{loading ? <Spinner /> : "SAVE"}</button>

      </Modal.Body>
    </Modal>
  );
}

export default AssignToGroupModal;
