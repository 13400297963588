import { chatSlice } from "./chatSlice";
import * as requestFromServer from "./chatCrud";

const { actions } = chatSlice;

export const getChatThread = (_id, userId) => async (dispatch) => {
  try {
    const response = await requestFromServer.getChatThread(_id, userId);
    dispatch(actions.chatThread({ chat: response.data, status: 200 }));
  } catch (error) {
    dispatch(actions.chatThread({ chat: null, status: error?.response?.status }))
    return 0;
  }
};
export const me = () => async (dispatch) => {
  try {
    const response = await requestFromServer.me();
    dispatch(actions.activeUser(response.data));
  } catch (error) {
    return 0;
  }
};
export const sendMessageToThread = (_id, message, userId, onSuccess) => async (dispatch) => {
  try {
    const response = await requestFromServer.sendMessage(_id, message, userId);
    dispatch(actions.sendMessage({ message, status: response.status }));
    onSuccess();
  } catch (error) {
    return 0;
  }
};

export const setActiveThread = (data) => async (dispatch) => {
  try {
    dispatch(actions.setActiveThread(data));
  } catch (error) {
    return 0;
  }
};
