import { landingPageSlice, callTypes } from "./landingPageSlice";
import * as requestFromServer from "./landingPageCrud";
import toastHandler from "components/UI/toastHandler/toastHandler";

const { actions } = landingPageSlice;

export const fetchLandingPageData = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLandingPageData(params)
    .then((response) => {
      const { all_stands, popular_stands, species } = response.data;
      dispatch(
        actions.landingPageDataFetched({
          allStands: all_stands,
          popularStands: popular_stands,
          species: species,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find producer";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStands =
  (queryParams, source, showMap, firstTimeFetched) =>
    (dispatch) => {
      dispatch(actions.requestFetchStand());
      if (!showMap) {
        delete queryParams.sw_lat;
        delete queryParams.sw_long;
        delete queryParams.ne_lat;
        delete queryParams.ne_long;
      }
      delete queryParams?.points_of_interest
      delete queryParams?.site_type
      delete queryParams?.terrain
      return requestFromServer
        .getStandsList(queryParams, source)
        .then((response) => {
          const { total_records = 0, data } = response;
          dispatch(
            actions.standsFetched({
              totalRecords: total_records,
              stands: data,
              firstTimeFetched: firstTimeFetched,
            })
          );
        })
        .catch((error) => {
          error.clientMessage = "Can't find listing";
          dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
    };

export const fetchStandDetail = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getStandDetail(id)
    .then((response) => {
      const { data } = response;
      dispatch(actions.standDetailsFetched({ standDetails: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find listing";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const clearStandDetailsFectched = () => (dispatch) => {
  dispatch(actions.clearStandDetailsFectched());
};
export const clearStandListing = () => (dispatch) => {
  dispatch(actions.clearStandListing());
};
export const fetchWeatherReport = (data, isCurrent) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getWeatherUpdate(data)
    .then((response) => {
      dispatch(actions.weatherReportFetched({ weatherReport: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch weather report";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRecentBlogs = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBlogs()
    .then((response) => {
      const { data } = response;
      dispatch(
        actions.recentBlogsFetched({
          blogs: data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch recent blogs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBlogList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBlogs(queryParams)
    .then((response) => {
      const { total_records, data } = response;
      dispatch(
        actions.blogsListFetched({
          blogs: data,
          totalRecords: total_records,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch blogs list";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBlogDetail = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBlogDetail(id)
    .then((response) => {
      const { data } = response;
      dispatch(actions.blogDetailFetched({ blog: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find blog";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllStandBlogs = (params, source, firstTimeFetched) => (dispatch) => {
  dispatch(actions.requestFetchStand());
  delete params?.points_of_interest
  delete params?.site_type
  delete params?.terrain
  return requestFromServer.getAllStandBlogs(params, source).then((response) => {
    const { data, total_records = 0 } = response;
    dispatch(actions.standBlogFetched({ 
      totalRecords: total_records,
      blogs: data,
      firstTimeFetched: firstTimeFetched, 
    }));
  })
    .catch((error) => {
      error.clientMessage = "Can't Fetch blogs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchVerse = (bibleId, verseID) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getVerse(bibleId, verseID)
    .then((response) => {
      const { data } = response;
      let verse = "";
      if (data.verses.length) {
        const verseIndex = Math.floor(Math.random() * data.verses.length);
        verse = data.verses[verseIndex];
      }
      dispatch(
        actions.verseFetched({
          verse,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch verse";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDashboardCounters = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDashboardCounters()
    .then((response) => {
      const { data } = response;
      dispatch(actions.dashboardCountersFetched({ dashboardCounters: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find blog";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStandTrophies = (standId, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStandTrophies(standId, queryParams)
    .then((response) => {
      const { data, total_records } = response;
      dispatch(
        actions.standTrophiesFecthed({
          trophies: data,
          totalData: total_records,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Listing Trophies";
    });
};

export const fetchFAQs = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getFAQs()
    .then((response) => {
      const { data } = response;
      dispatch(actions.faqsFetched({ faqs: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find blog";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStandReviews = (standId, queryparams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStandReviews(standId, queryparams)
    .then((response) => {
      const { data, total_records } = response;
      dispatch(
        actions.standReviewsFecthed({
          reviews: data,
          totalReviews: total_records,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Listing Reviews";
    });
};

export const fetchStandFollower = (standId, queryparams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStandFollowers(standId, queryparams)
    .then((response) => {
      const { data, total_records } = response;
      dispatch(
        actions.standFollowersFetched({
          follower: data,
          totalFollower: total_records,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Listing Reviews";
    });
};

export const fetchTutorialsList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTutorials(queryParams)
    .then((response) => {
      const { total_records, data } = response;
      dispatch(
        actions.tutorialsListFetched({
          tutorials: data,
          totalRecords: total_records || data.length,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch blogs list";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNotifications = (queryParams, isFirstFetch) => (dispatch) => {
  dispatch(actions.startNotificationsCall());
  return requestFromServer
    .getNotifications(queryParams)
    .then((response) => {
      const { total_records, data } = response;
      dispatch(
        actions.notificationsFetched({
          notifications: data,
          totalRecords: total_records || data.length,
          isFirstFetch,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch blogs list";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const toggleNewNotificationFlag = (flag) => (dispatch) => {
  dispatch(actions.toggleNewNotificationFlag({ flag }));
};

export const addContactUsQuery = (data, onSuccess, onError) => () => {
  return requestFromServer
    .postContactUsQuery(data)
    .then((response) => {
      onSuccess();
    })
    .catch((error) => {
      onError();
    });
};

export const fetchFeedbacks = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getFeedbacks()
    .then((response) => {
      const { data } = response;
      dispatch(
        actions.feebacksFetched({
          feedbacks: data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch related lands";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLeasingMarkup = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLeasingMarkup()
    .then((response) => {
      const { data } = response;
      dispatch(
        actions.leasingMarkupFetched({
          markup: data.body,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch blogs list";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const postLeaseApplication =
  (data, onSuccess, onError) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .postLeaseApplication(data)
      .then((response) => {
        dispatch(actions.leaseApplicationPosted());
        onSuccess();
      })
      .catch((error) => {
        error.clientMessage = "Can't post lease application";
        onError(error?.response?.data?.errors);
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
  };

export const getNotificationsCount = () => (dispatch) => {
  dispatch(actions.startNotificationsCall());
  return requestFromServer
    .getNotificationsCount()
    .then((response) => {
      dispatch(
        actions.notificationCountFetched({
          count: response.data.count,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const markNotificationsRead = (id, data) => (dispatch) => {
  dispatch(actions.startNotificationsCall());
  return requestFromServer
    .markNotificationsRead(id, data)
    .then((response) => {
      dispatch(actions.toggleLoading())
      dispatch(getNotificationsCount());
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const markAllNotificationsRead = (onSuccess) => (dispatch) => {
    dispatch(actions.startNotificationsCall());
    return requestFromServer
        .markAllNotificationsRead()
        .then((response) => {
            dispatch(getNotificationsCount());
            toastHandler(response.message, "success");
            onSuccess();
        })
        .catch((error) => {
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const fetchStandPosts = (id, queryParams, firstTime) => async (dispatch) => {
  try {
    const response = await requestFromServer.getStandPosts(id, queryParams);
    const { total_records, data } = response;
    dispatch(actions.standPostsFetched({ data, total_records, firstTime }))
  } catch (error) {
    error.clientMessage = "Can't find Listing Posts";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const getStandPostById = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.postDetailsFetched({ selectedPost: null }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getStandPostById(id);
    if (response.status) {
      const post = response.data;
      dispatch(actions.postDetailsFetched({ selectedPost: post }));
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return 0;
  }
};

export const deleteStandPost = (id, onDeleteSuccess, onError) => async (dispatch) => {
  try {
    const response = await requestFromServer.deleteStandPost(id);
    if (response.status)
    {
      dispatch(actions.deletedStandPost())
      onDeleteSuccess(response.message);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
    onError(error?.response?.message)
  }
};

export const createStandPost = (formData, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.setUploadProgress({ progress: 0 }));

  try {
    const response = await requestFromServer.createStandPost(formData, (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch(actions.setUploadProgress({ progress: percentCompleted }));
    });

    dispatch(actions.createStandPost(response.data));
    onSuccess(response.data);
    dispatch(actions.setUploadProgress({ progress: 100 }));
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    onError(error?.response?.data?.errors);
  } finally {
    dispatch(actions.setUploadProgress({ progress: 0 }));
  }
};

export const updateStandPost = (id, formData, onEditSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.setUploadProgress({ progress: 0 }));

  try {
    const response = await requestFromServer.updateStandPost(id, formData, (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch(actions.setUploadProgress({ progress: percentCompleted }));
    });

    dispatch(actions.updateStandPost(response.data));
    onEditSuccess(response.data);
    dispatch(actions.setUploadProgress({ progress: 100 }));
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    onError(error?.response?.data?.errors);
  } finally {
    dispatch(actions.setUploadProgress({ progress: 0 }));
  }
};

export const fetchPointOfInterest = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getPointOfInterest();
    const { data } = response;
    dispatch(actions.fetchedPointOfInterest({ data }))
  } catch (error) {
    error.clientMessage = "Can't find point of interest";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchSiteTypes = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getSiteTypes();
    const { data } = response;
    dispatch(actions.fetchedSiteTypes({ data }))
  } catch (error) {
    error.clientMessage = "Can't find site type";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchTerrains = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getTerrains();
    const { data } = response;
    dispatch(actions.fetchedTerrains({ data }))
  } catch (error) {
    error.clientMessage = "Can't find terrain";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchFacebookLink = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getFacebookLink()
    .then((response) => {
      const { data } = response;
      dispatch(
        actions.facebookFetched({
          link: data.value,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch link";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const openFourOThreeModal = (flag, message) => (dispatch) => {
  dispatch(actions.setFourOThree({
    flag: flag,
    message: message
  }))
}

export const openAuthenticationModal = (flag) => (dispatch) => {
  dispatch(actions.setAuthenticationRequired({ flag: flag }))
}

export const setAuthStatus = (flag) => (dispatch) => {
  dispatch(actions.setAuthStatus({ flag: flag }))
}

export const setReservationLoader = (flag) => (dispatch) => {
  dispatch(actions.setReservationLoader({ flag: flag }))
}

export const fetchSliderPictures = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSliderPicture()
    .then((response) => {
      const { data } = response;
      dispatch(
        actions.sliderPicturesFetched({
          images: data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch Images";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUserDetails = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUser(id)
    .then((response) => {
      const { data } = response;
      dispatch(actions.userDetailFetched({ user: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const openLoginModal = (flag, message) => (dispatch) => {
  dispatch(actions.setLogin({
    flag: flag,
    message: message
  }))
}

export const fetchStandVouchers = (id, firstTime) => async (dispatch) => {
  try {
    const response = await requestFromServer.getStandVouchers(id);
    const { total_records, data } = response;
    dispatch(actions.standVouchersFetched({ data, total_records, firstTime }))
  } catch (error) {
    error.clientMessage = "Can't find Listing Vouchers";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const getStandVoucherById = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.voucherDetailsFetched({ selectedVoucher: null }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getStandVoucherById(id);
    if (response.status) {
      const voucher = response.data;
      dispatch(actions.voucherDetailsFetched({ selectedVoucher: voucher }));
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return 0;
  }
};

export const deleteStandVoucher = (id, onDeleteSuccess, onError) => async (dispatch) => {
  try {
    const response = await requestFromServer.deleteStandVoucher(id);
    if (response.status)
    {
      dispatch(actions.deletedStandVoucher())
      onDeleteSuccess(response.message);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
    onError(error?.response?.message)
  }
};

export const createStandVoucher = (id, params, onSuccess, onError) => async (dispatch) => {
  return requestFromServer
    .createStandVoucher(id, params)
    .then((response) => {
      dispatch(actions.createStandVoucher(response.data))
      onSuccess(response.data)
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        toastHandler(error.response.data.errors, "error");
      }
      onError(error?.response?.data?.errors)
    });
}

export const updateStandVoucher = (id, params, onSuccess, onError) => async (dispatch) => {
  return requestFromServer
    .updateStandVoucher(id, params)
    .then((response) => {
      dispatch(actions.updateStandVoucher(response.data))
      onSuccess(response.data)
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        toastHandler(error.response.data.errors, "error");
      }
      onError(error?.response?.data?.errors)
    });
}

export const blogPostRequest = (id, data, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.blogPostRequest(id, data).then((response) => {
    dispatch(actions.requestBlogPost(response.data))
    onSuccess(response.message)
  })
  .catch((error) => {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (error?.response?.data?.message) {
      onError(error?.response?.data?.errors)
    }
  });
}

export const clearPostDetailsFectched = () => (dispatch) => {
  dispatch(actions.clearPostDetailsFectched());
};