import { isEqual, isFunction } from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";
import { List } from "immutable";
import {
  initialBlogsFilter,
  initialFilter,
  initialTutorialsFilter,
  inititalReviewsFilter,
  initialStandBlogsFilter,
  initialTrophiesFilter,
  initialNotificationsFilter,
} from "./LandingPageUIHelper";
// Creating Context
const LandingPageUIContext = createContext();

//export to use this context

export function useLandingPageUIContext() {
  return useContext(LandingPageUIContext);
}

export function LandingPageUIProvider({ landingPageUIEvents, children }) {
  //query params for stands page api
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [blogsQueryParams, setBlogsQueryParamsBase] =
    useState(initialBlogsFilter);

  const [reviewsQueryParams, setReviewsQueryParamsBase] = useState(
    inititalReviewsFilter
  );

  const [standBlogsQueryParams, setStandBlogsQueryParamsBase] = useState(
    initialStandBlogsFilter
  );

  const [tutorialsQueryParams, setTutorialsQueryParamsBase] = useState(
    initialTutorialsFilter
  );

  const [trophiesQueryParams, setTrophiesQueryParamsBase] = useState(
    initialTrophiesFilter
  );

  const [notificationQueryParams, setNotificationsQueryParamsBase] = useState(
    initialNotificationsFilter
  );

  const [showMap, setShowMap] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('list');
  const [mapState, setMapState] = useState({
    center: new List([44.5, -89.5]),
    zoom: 10
  });

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const setBlogsQueryParams = useCallback((nextQueryParams) => {
    setBlogsQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const setTutorialsQueryParams = useCallback((nextQueryParams) => {
    setTutorialsQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const setNotificationsQueryParams = useCallback((nextQueryParams) => {
    setNotificationsQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  // COMBVINING ALL THE STATES AND ASSOCIATED FUNCTIONS
  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    blogsQueryParams,
    setBlogsQueryParams,
    tutorialsQueryParams,
    setTutorialsQueryParams,
    reviewsQueryParams,
    setReviewsQueryParamsBase,
    trophiesQueryParams,
    setTrophiesQueryParamsBase,
    notificationQueryParams,
    setStandBlogsQueryParamsBase,
    standBlogsQueryParams,
    setNotificationsQueryParams,
    showMap,
    setShowMap,
    currentScreen,
    setCurrentScreen,
    mapState,
    setMapState,
    openStandDetailPage: landingPageUIEvents?.openStandDetailPage || null,
    openBlogDetailPage: landingPageUIEvents?.openBlogDetailPage || null,
  };

  // ULTIMATE PROIDER FOR CONTEXT
  return (
    <LandingPageUIContext.Provider value={value}>
      {children}
    </LandingPageUIContext.Provider>
  );
}
