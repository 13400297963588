import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
import ChatBox from './../../../../modules/dashboard/pages/chat'

const ChatModal = ({ id, name, user, show, handleClose, showInput, showMessage }) => {
  return (
    <Modal className="chat-model" show={show} onHide={handleClose} centered>
      <Modal.Body closeButton>
        {/* <ChatUi /> */}
        <ChatBox
          id={id}
          name={name}
          user={user}
          showInput={showInput}
          showMessage={showMessage}
        />
        <Button onClick={handleClose} className="close-btn">
          <i className="bi text-white bi-x"></i>
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default ChatModal;
