import { dashboardSlice, callTypes } from "./dashboardSlice";
import * as requestFromServer from "./dashboardCrud";

const { actions } = dashboardSlice;

export const transferAmount = (data, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .transferAmount(data)
    .then((response) => {
      dispatch(actions.amountTransfered());
      onSuccess();
    })
    .catch((error) => {
      error.clientMessage = "Can't transfer amount";
      if (error?.response?.data?.message) {
        onError(error?.response?.data?.errors);
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getTransactions = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getTransactions(queryParams);
    if (response.status) {
      dispatch(
        actions.transactionsFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.catchError({ error }));
    }
  }
};

export const getAmountInfo = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getAmountInfo();
    if (response.status) {
      dispatch(
        actions.amountInfoFetched({
          amountInfo: response.data,
        })
      );
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.catchError({ error }));
    }
  }
};

export const fetchAmountStatsGraphData = (filter) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.fetchAmountStatsGraphData(filter);
    dispatch(
      actions.amountGraphDataFetched({
        amountGraphData: response.data,
      })
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.catchError({ error }));
    }
  }
};

export const fetchTrophiesRoomList = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.fetchTrophiesRoomList(queryParams);
    dispatch(
      actions.trophiesRoomListFetched({
        data: response.data,
        total_records: response.total_records
      })
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.catchError({ error }));
    }
  }
};

export const getDocument = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getDocument(queryParams);
    dispatch(
      actions.documentFetched(response.data)
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.catchError({ error }));
    }
  }
};

export const handleJuniorHunterModal = (flag) => async (dispatch) => {
  dispatch(actions.handleJuniorHunterModal({ flag: flag }));
};

export const searchUserStand = (text) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.searchUserStand(text);
    if (response.status) {
      dispatch(actions.usersStandsFiltered({ stands: response.data }));
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const changeTransactionTab = (tab) => (dispatch) => {
  dispatch(actions.changeTransactionTab(tab))
};

export const resetTransactionTab = (tab) => (dispatch) => {
  dispatch(actions.changeTransactionTab(tab))
};