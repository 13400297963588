import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import { deletePendingReservation, clearBookingDetails } from "modules/dashboard/_redux/reservations/reservationActions";
import { useDispatch } from "react-redux";


export default function PaymentForm({ amount, setPaying, processCompletionTitle, processCompletionDescription, huntApprovalSetting }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  const showAlert = () => {
    window.alert("Payment in Process. If you want to cancel this reservation. Go to My Reservation Section");
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsLoading(true);
    setPaying(true)
    window.addEventListener('popstate', showAlert);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = huntApprovalSetting === "instant" ? await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/success?process_completion_title=${processCompletionTitle}&process_completion_description=${processCompletionDescription}`,
      },
    }) : await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/success?process_completion_title=${processCompletionTitle}&process_completion_description=${processCompletionDescription}`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (e.g., payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsLoading(false);
      setPaying(false)
      localStorage.removeItem("amount");
      window.removeEventListener('popstate', showAlert);
      dispatch(deletePendingReservation())
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      // show proccessing
      setIsLoading(false);
      setPaying(false)
      localStorage.removeItem("amount");
      dispatch(clearBookingDetails())
      window.removeEventListener('popstate', showAlert);
    }

  };

  // 6️⃣ Construct UI.
  return (
    <form id="payment-form" onSubmit={handleSubmit} className="p-5">
      <PaymentElement />
      <Row className="p-2">
        <Button type="submit" disabled={!stripe} className=" mt-2 text-white">
          {isLoading ? (
            <Spinner />
          ) : huntApprovalSetting === "instant" ? (
            `PAY $${amount || localStorage.getItem("amount")}`
          ) : (
            `SETUP $${amount || localStorage.getItem("amount")}`
          )}
        </Button>
      </Row>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}
