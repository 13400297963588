import React from "react";
import DirectionshuntImg from "./../../../../assets/images/directions-iHunt.png";
import PlaceHolderImage from "./../../../../assets/images/directions-iHunt-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function parkingInfo() {
    return (
        <section className="account-detail-guide bg-3">
            <div className="inner-container">
                <div className="row justify-content-between">
                    <div className="col-lg-5 col-md-6">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Get Directions and Parking Information</h5>
                                <p>
                                Check the email confirmation or access the app to obtain directions to the property and the designated parking location. Make sure to follow the provided instructions for a smooth arrival.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={DirectionshuntImg}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={DirectionshuntImg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
