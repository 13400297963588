import React from "react";
import LandBookingItem from "../landBookingItem";
import "./style.scss";

export default function LandBookingItems({ lands, isMap, isFeatured }) {
  return (
    <div className="stand-booking-wrapper">
      <div className="courses-causal">
        {lands.map((land, index) => (
          <LandBookingItem
            key={index}
            land={land}
            isMap={isMap}
            isFeatured={isFeatured}
          />
        ))}
      </div>
    </div>
  );
}
