import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function ElevationGraph({ elevations }) {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (elevations.length) {
      let series1 = [
        ...elevations?.map((elevation) => elevation?.alt?.toFixed(0)),
      ];
      setSeries([
        {
          name: "Elevation",
          data: series1,
        },
      ]);
      setOptions({
        chart: {
          height: 280,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            ...elevations.map((elevation, index) =>
              Math.floor(elevations.length / 2) === index ? "Elevation" : ""
            ),
          ],
        },
        colors: ["#EA9B45"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
      });
    }
  }, [elevations]);

  return <ReactApexChart options={options} series={series} type="area" />;
}

export default ElevationGraph;
