import React, { useEffect } from "react";
import Andriod from "../../../../assets/images/andriod.png";
import Apple from "../../../../assets/images/apple.png";
import Mobile from "../../../../assets/images/HM.png";
import config from "./../../../../config";
import "./style.scss";
import { useLocation } from "react-router-dom";

export default function Downloads() {
  const location = useLocation();
  const appStoreLink = "https://apps.apple.com/us/app/i-hunt/id1596269965";
  const playStoreLink = "https://play.google.com/store/apps/details?id=com.tso.ihunt"

  const isIos = /iPhone|iPad|iPod/.test(navigator.userAgent);
  const isAndroid = /android|Android/.test(navigator.userAgent);

  const handleRedirection = (link, platform, otherPlatformLink) => {
    if (location?.search?.includes(platform)) {
      window.location = config.APP_URL_SCHEME;
      setTimeout(() => {
        window.location = link;
      }, 1000);
    } else {
      window.location = otherPlatformLink
    }
  }

  useEffect(() => {
    if (location.pathname === "/download") {
      if (isIos) {
        handleRedirection(appStoreLink, 'ios', playStoreLink)
        return
      }
      if (isAndroid) {
        handleRedirection(playStoreLink, 'android', appStoreLink)
        return
      } else {
        if (location?.search?.includes('ios')) {
          window.location = appStoreLink
        }
        if (location?.search?.includes('android')) {
          window.location = playStoreLink
        }
      }
    }
  }, [])

  return (
    <div className="downlodes">
      <div className="inner-container position-relative">
        <div className="row">
          <div className="col-md-9">
            <div className="downlodes-left">
              <h2 className="text-secondary mb-4">Download Our Mobile App!</h2>
              <p className="text-tertiary fs-18">
                Join thousands of learners who have got benchmark results.
                Download our App available at Apple Store and Google Play.
              </p>
              <div>
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/i-hunt/id1596269965"
              >
                <img className="mt-lg-4" src={Apple} alt="" />
              </a>
              <a
                className="ms-4"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.tso.ihunt"
              >
                <img className="mt-lg-4" src={Andriod} alt="" />
              </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 img-section">
            <div>
              <img src={Mobile} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
