import { createSlice } from "@reduxjs/toolkit";

const initialCustomerServicePortalState = {
  error: null,
  actionsLoading: false,
  listLoading: false,
  customerServicesList: [],
  ticketsList: [],
  totalTickets: 0,
  ticketDetail: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const customerServicePortalSlice = createSlice({
  name: "customerServicePortal",
  initialState: initialCustomerServicePortalState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    ticketCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    customerServicesListFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.customerServicesList = action.payload.customerServicesList;
    },
    ticketsFetched: (state, action) => {
      const { data, total_records } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.ticketsList = data;
      state.totalTickets = total_records;
    },
    ticketDetailFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.ticketDetail = action.payload.ticketDetail;
    },
    commentAddedToTicket: (state, action) => {
      const { comment } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      // update ticket detail
      let ticketDetail = {
        ...state.ticketDetail,
      };
      ticketDetail.comment_count += 1;
      ticketDetail.comments.unshift(comment);
    },
    clearTicketsList: (state) => {
      state.ticketsList = [];
    },
  },
});
