import React from 'react';
import Header from '../../components/layout/header';
import Footer2 from 'containers/Home/footer';

export default function Layout({ children, showHeaderFooter }) {

  return (<>
    {
      showHeaderFooter ?
        <div>
          <Header />
          {children}
          <Footer2 />
        </div> :
        <div>
          {children}
        </div>
    }
  </>)
}
