import toastHandler from "components/UI/toastHandler/toastHandler";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import axios from "axios";
import Spinner from "components/UI/Spinner.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUserVerificationUrl, resetUserVerificationToken } from "modules/auth/_redux/authActions";


export default function EmailVerficationConfirmationComponent() {

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const history = useHistory();

  const params = new URLSearchParams(window.location.search);
  let code = params.get('code')

  const {
    verificationUrl,
    userToken,
    fetchingVerificationUrl,
    codeError,
  } = useSelector(
    (state) => ({
      verificationUrl: state.auth.verificationUrl,
      userToken: state.auth.userToken,
      fetchingVerificationUrl: state.auth.fetchingVerificationUrl,
      codeError: state.auth.codeError,
    }),
    shallowEqual
  );

  useEffect(() => {
    return () => {
      dispatch(resetUserVerificationToken())
    }
  }, [])


  // redirect the user to login page if no signature, verify_url, and expry seconds of token found in verification email
  useEffect(() => {
    if (codeError) {
      toastHandler(codeError, "error");
      history.push("/auth/login");
    }
    if (code) {
      dispatch(getUserVerificationUrl({ code: code }))
    } else {
      history.replace("/auth/login");
    }
  }, [code, codeError]);

  useEffect(() => {
      if (localStorage.getItem("token")) {
        toastHandler("You don't have permission to perform this action", "error");
        history.push("/my-profile/my-stands");
      }

      if (!localStorage.getItem("token")) {
        if (verificationUrl) {
          verify();
        }
      }
  }, [verificationUrl]);


  const verify = async () => {
    setLoading(true);
    const response = await axios
      .get(verificationUrl, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      })
      .catch(() => {
        toastHandler("Link has been expired", "error");
        history.replace("/auth/login");
      });
    if (response) {
      setLoading(false);
      localStorage.removeItem("token");
      history.push("/auth/login");
      toastHandler("Email Verified Successfully", "success");
    } else {
      setLoading(false)
      toastHandler(response?.error, "error");
    }
  }

  return (
    <div className="form-box">
      {
        loading || fetchingVerificationUrl ? <Spinner /> : null
      }
      <span>Please wait while your email is being verified.</span>
    </div>
  );
}
