import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/dashboard/dashboardActions";
import ReactApexChart from "react-apexcharts";

export default function AmountStatsGraph() {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [filter, setFilter] = useState("yearly");
  const dispatch = useDispatch();
  const { amountGraphData } = useSelector(
    (state) => ({
      amountGraphData: state.dashboard.amountGraphData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAmountStatsGraphData(filter));
  }, [filter]);

  useEffect(() => {
    if (amountGraphData) {
      let series1 = [];
      let series2 = [];
      let year = [];
      amountGraphData.forEach((item) => {
        year.push(item.key);
        series1.push(item.spending);
        series2.push(item.earning);
      });

      setSeries([
        {
          name: "Earnings",
          data: series2,
        },
        {
          name: "Spendings",
          data: series1,
        },
      ]);

      setOptions({
        colors: ["#EA9B45", "#CB2027"],
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetY: -40
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: year,
          title: {
            text:
              filter === "yearly"
                ? "Year"
                : filter === "monthly"
                ? "Month"
                : "Week",
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: "Overview",
          align: "left",
          style: {
            fontSize: "1.75rem",
            fontWeight: 500,
            color: "#333",
            fontFamily: "Minionpro",
          },
          offsetY: 30
        },
      });
    }
  }, [amountGraphData]);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={255}
        />
        <div className="btn-group-main d-flex ms-5">
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-outline-primary filterButton ${
                filter === "yearly" ? "btn-primary" : ""
              }`}
              onClick={() => {
                setFilter("yearly");
              }}
            >
              Yearly
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary filterButton ${
                filter === "monthly" ? "btn-primary" : ""
              }`}
              onClick={() => {
                setFilter("monthly");
              }}
            >
              Monthly
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary filterButton ${
                filter === "weekly" ? "btn-primary" : ""
              }`}
              onClick={() => {
                setFilter("weekly");
              }}
            >
              Weekly
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
