import React, { useState } from "react";
import DirectionsiHunt from "./../../../../assets/images/directions-iHunt.png";
import PlaceHolderImage from "./../../../../assets/images/directions-iHunt-1.jpg";
import { EditStandModal } from "modules/dashboard/pages/ownerDashboard/mystands/standItem/editModal/editModal";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function DirectioniHunt() {

    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen = () => {
        setOpenModal(true)
    }

    const handleModalClose = () => {
        setOpenModal(false)
    }

    return (
        <>
            <section className="directional-section">
                <div className="inner-container">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-11 col-md-12 mx-auto">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 order-md-1 order-2">
                                    <div className="left">
                                        <div className="left-inner">
                                            <p>
                                                The same thing for directions from the parking spot to a tree listing or hunting location you already have. Track a route they can follow, while walking to their hunting location in the morning darkness. You don’t need to show them where to hunt. The easy-to-follow trail that was recorded on the initial setup is there for them to follow. Not just a straight line, but an actual live route, right to there hunting location. Must I mention it again? No phone calls from lost hunters!
                                            </p>
                                            <button className="btn btn-primary text-white text-uppercase" onClick={handleModalOpen}>Download app</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 order-md-2 order-1 col-sm-8 col-10 pb-md-0 pb-4 mx-auto">
                                    <div className="right">
                                        <div className="right-inner">
                                            <LazyLoadImage
                                                src={DirectionsiHunt}
                                                alt=""
                                                className="lazy-load-image"
                                                placeholderSrc={PlaceHolderImage}
                                                visibleByDefault={DirectionsiHunt}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <EditStandModal
                show={openModal}
                onHide={handleModalClose}
            />
        </>
    );
}
