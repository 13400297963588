import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import "./style.scss"
import { FloatingLabel, Form } from 'react-bootstrap';
import { createGroups } from "modules/dashboard/_redux/groups/groupActions";
import Spinner from "components/UI/Spinner.js";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LEADING_TRAILING_SPACES } from 'utils/helpers';


const CreateGroupModal = ({ show, onHide }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    group: Yup.string().required("Group is required")
      .test("len", "Group title length must be between 3-20 characters", val => val?.length >= 3 && val?.length <= 20).matches(LEADING_TRAILING_SPACES, "Group title should not contain leading and trailing spaces"),
  });

  const formik = useFormik({
    initialValues: {
      group: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(createGroups({ groups: values.group }, onSuccess, onError))
    },
  });

  const onSuccess = (response) => {
    handleClose();
  };

  const onError = () => {
    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    formik?.resetForm();
    onHide();
  }

  return (
    <Modal className="create-group-modal" show={show} onHide={() => handleClose()} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Create a Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='create-group-form' onSubmit={formik.handleSubmit}>
          <FloatingLabel controlId="floatingInputGrid" label="Enter Your Group">
            <Form.Control
              type="text"
              name="group"
              id="group"
              value={formik?.values?.group}
              onChange={formik.handleChange}
              className={`form-control fs-14`}
            />
          </FloatingLabel>
          {formik?.touched?.group &&
            formik?.errors?.group && (
              <div style={{ color: "red" }}>
                {formik?.errors?.group}
              </div>
            )
          }
          <button className='save-btn' type='submit'>{loading ? <Spinner /> : "SAVE"}</button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateGroupModal;
