import React from "react";
import NoResult from "assets/images/no-result.SVG";

const NoRecordFound = (params) => {

    return (<div
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            flexDirection: "column",
            marginBlock: `${params?.from === "additional-hunter" ? "5rem" : "5rem 0rem"}`,
        }}
    >
        <img src={NoResult} style={{width: "99px", height: "105px", marginBottom: "9px"}} alt="" />
        {
            params.message ? <span style={{color:'#959595',fontWeight:'500'}}>{params.message}</span>
                : <span style={{marginTop: "0px"}}>No record found</span>

        }
    </div>)

}

export default NoRecordFound;