import axios from "axios";
import config from "../config";
import { openFourOThreeModal, openAuthenticationModal, openLoginModal } from "modules/landingPage/_redux/landingPageActions";
import store from "store/store";

const baseInstance = axios.create({
  baseURL: config.REACT_APP_BASE_URL,
});

baseInstance.defaults.headers.common["Accept"] = "application/json";
baseInstance.defaults.headers.common["Content-Type"] = "application/json";

// Set the AUTH token for any request
baseInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});


baseInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    if (err?.response?.status === 401) {
      localStorage.removeItem("token");
      let reqUrl = err?.response?.request?.responseURL?.split("/");
      if (err?.response?.data?.errors === "Unauthenticated!") {
        store.dispatch(openLoginModal(true, "Your Session has been expired!"))
      }
      if (reqUrl?.includes("email")) {
        store.dispatch(openLoginModal(true, "Your Session has been expired!"))
      }

      if (reqUrl.length && reqUrl[reqUrl.length - 1] !== "login") {
        if (window.location.pathname !== "/auth/forgot-password")
          store.dispatch(openLoginModal(true, "Your Session has been expired!"))
      }
    } 
    else if (err?.response?.status === 403) {
      const error = err?.response?.data?.message;
      if (!error.includes('Please verify your email before you can continue!') && !error.includes('Fund Transfer Request Already Declined!') && !error.includes('Listing already deactivated!') && !error.includes('Listing already activated!') && !error.includes('You cannot use your own listing ownership invitation!') && !error.includes('This invitation code has been expired!') && !error.includes('Invitation Code has Expired!') && !error.includes('Invitation already sent!') && !error.includes('Unable to create record: Invalid') && !error.includes('Invitation Already Sent!') && !error.includes('Unauthorized to perform this action!') && !error.includes('You must be 18 years old or above!') && !error.includes('Main hunter of this reservation cannot be an additional hunter.') && !error.includes('Invalid Invitation Code!') && !error.includes('Cannot Cancel this Invitation!') && !error.includes('This value must be greater than or equal to 1.') && !error.includes('You have already joined this reservation as an additional hunter!') && !error.includes('Before transferring the amount, please ensure your Stripe account is connected.') && !error.includes('Your destination account needs to have at least one of the following capabilities enabled: transfers, crypto_transfers, legacy_payments')) {
        store.dispatch(openFourOThreeModal(true, error))
      }
    } else if (err?.response?.status === 412 && err?.response?.data?.precondition_failure_type === "verify_last_login") {
      store.dispatch(openAuthenticationModal(true))
    }
    throw err;

  }
);

export default baseInstance;
