import React, { createContext, useContext } from "react";
// Creating Context
const AuthUIContext = createContext();

//export to use this context

export function useAuthUIContext() {
  return useContext(AuthUIContext);
}

export function AuthUIProvider({ authUIEvents, children }) {
  // INITIAL USER CONTEXT
  const initRegistrationStep1 = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    seconday_phone: "",
    password: "",
    password_confirmation: "",
    birthday: "",
    isSecandryPhon: false
  };
  const initRegistrationStep2 = {
    address: "",
    city: "",
    state_name: "",
    state_code: "",
    country: "",
    zipcode: ""
  };
  const initLogin = {
    email: "",
    password: "",
  };
  const initForgotPasword = {
    email: "",
  };
  const initResetPasword = {
    token: "",
    email: "",
    password: "",
    password_confirmation: "",
  };

  // COMBVINING ALL THE STATES AND ASSOCIATED FUNCTIONS
  const value = {
    initRegistrationStep1,
    initRegistrationStep2,
    initLogin,
    initForgotPasword,
    initResetPasword,
    openEmailVerificationModal: authUIEvents.openEmailVerificationModal,
  };

  // ULTIMATE PROIDER FOR CONTEXT
  return (
    <AuthUIContext.Provider value={value}>{children}</AuthUIContext.Provider>
  );
}
