import axios from "../../../../services/axiosInstance";

export function getGroups() {
    return axios.get(`/user-groups`);
}

export function createGroups(body) {
    return axios.post(`user-group`, body);
}

export function getGroupsById(id) {
    return axios.get(`/stand-group/${id}`);
}

export function assignGroups(id, body) {
    return axios.post(`stand-group/${id}`, body);
}

export function deleteGroup(id) {
    return axios.delete(`user-group/${id}`);
}