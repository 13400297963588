import React, { useEffect, useMemo, useState } from "react";
import CommentIcon from "assets/images/comment.svg";
import "./style.scss";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Filter } from "./filter/filter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useDashboardUIContext } from "../../DashboardUIContext";
import { getTickets, clearTicketList } from "modules/dashboard/_redux/customer-service-portal/customerServicePortalActions";
import { NewTicketDialog } from "../newTicketDialog/newTicketDialog";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import getHelpImg from "assets/images/getHelp.svg";
import Pagination from "components/UI/pagination/Pagination";
import { useHistory } from "react-router-dom";
import CustomSpinner from "components/layout/Loader/Spinner";

export const TicketsList = () => {
  const [showNewTicketModal, setShowNewTicketModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.customerPortalTicketsListQueryParams,
      setQueryParams:
        dashboardPageUIContext.setCustomerPortalTicketsListQueryParams,
    };
  }, [dashboardPageUIContext]);

  const { listLoading, ticketsList, totalRecords } = useSelector(
    (state) => ({
      listLoading: state.customerServicePortal.listLoading,
      ticketsList: state.customerServicePortal.ticketsList,
      totalRecords: state.customerServicePortal.totalTickets,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getTickets(dashboardPageUIProps.queryParams));
  }, [dashboardPageUIProps.queryParams]);

  const handleNavigation = (ticketId) => {
    history.push(`/my-profile/customer-portal/ticket/${ticketId}`);
  };

  useEffect(() => {
    return () => {
      dispatch(clearTicketList());
    };
  }, []);

  return (
    <>
      <div className="tickets-section">
        <Filter onCreateNewTicket={() => setShowNewTicketModal(true)} />
        {ticketsList && ticketsList?.length > 0 ? (
        <div className="ticket-section2">
          <ul className="tickets-status-lists fade-in">
            {ticketsList.map((ticket, index) => (
              <li key={index} onClick={() => handleNavigation(ticket.id)}>
                <div className="ticket-status-header">
                  <div className="ticket-number">Ticket # {ticket.id}</div>
                  <div className={ticket.status === "hold" ? "ticket-status-pending" : ticket.status === "open" ? "ticket-status-open" : "ticket-status-resolved"}>
                    {ticket.status}
                  </div>
                </div>
                <div className="ticket-title">{ticket.subject}</div>
                <div className="ticket-status-body">
                  {ticket.description?.slice(0, 320)}
                </div>
                <div className="ticket-status-detail">
                  <div className="ticket-status-date">
                    {ticket.created_at}
                  </div>
                  <div className="ticket-status-chat">
                    <img src={CommentIcon} className="commentImg" alt="" />
                    <span className="chat-counter">
                      {ticket.comment_count}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        ) : !listLoading && ticketsList?.length == 0 ? (
          <div className="no-tickets fade-in item">
            <div className="d-get-help-middle-content">
              <div className="d-flex justify-content-center flex-column">
                <img className="ticket-avatar" src={getHelpImg} alt="" />
                <h6 className="d-content-inner-title font-roboto mb-0">No Tickets Yet !</h6>
                <p className="text-muted small font-roboto">Create new ticket to get any help</p>
              </div>
            </div>
          </div>
        ) : (
          <CustomSpinner />
        )}
        {ticketsList && ticketsList?.length > 0 ? (
          <Pagination
            totalRecords={totalRecords}
            uiProps={dashboardPageUIProps}
          />
        ) : null}
      </div>
      <NewTicketDialog
        show={showNewTicketModal}
        onHide={(ticketCreated) => {
          if (ticketCreated) {
            dispatch(getTickets(dashboardPageUIProps.queryParams));
          }
          setShowNewTicketModal(false);
        }}
      />
    </>
  );
};
