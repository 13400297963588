import React from "react";
import { Modal } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";

export function ConfirmationModal({
  show,
  onHide,
  title = "Cancel Reservation",
  message = "Are you sure to cancel this reservation?",
  yesString = "YES",
  noString = "NO",
  onlyYes = false,
  isLoading = false,
}) {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        className="logout-popup"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{message}</span>
        </Modal.Body>
        <Modal.Footer>
          {!onlyYes && !isLoading && (
            <button
              type="button"
              onClick={() => {
                onHide(false);
              }}
              className="btn btn-outline-secondary px-3 border-fifth me-2"
            >
              {noString}
            </button>
          )}
          <button
            type="button"
            onClick={() => {
              onHide(true);
            }}
            className="btn btn-primary text-white "
          >
            {isLoading ? <Spinner /> : yesString}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
