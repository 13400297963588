import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";

export function ContentRoute({ children, component, render, ...props }) {
  const history = useHistory();
  const { user, token } = useSelector((state) => state.auth);

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (user && token) {
          if (typeof children === "function") {
            return <>{children(routeProps)}</>;
          }

          if (!routeProps.match) {
            return null;
          }

          if (children) {
            return <>{children}</>;
          }

          if (component) {
            return <>{React.createElement(component, routeProps)}</>;
          }

          if (render) {
            return <>{render(routeProps)}</>;
          }

          return null;
        } else {
          if (!token) {
            return (
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: history.location },
                }}
              />
            );
          }
        }
      }}
    ></Route>
  );
}
