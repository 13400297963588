import { Spinner } from "react-bootstrap";
import "./style.scss";

const CustomSpinner = () => {
  return (
    <div className="spinner-loader">
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        style={{ leftMargin: "2px" }}
        aria-hidden="true"
      />
    </div>
  );
};

export default CustomSpinner;
