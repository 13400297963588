import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { blogPostRequest } from "modules/landingPage/_redux/landingPageActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { useDispatch } from "react-redux";
import Spinner from "components/UI/Spinner.js";


export function BlogConfirmationModal({ show, onHide, postId, message }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const confirmStatus = message?.includes("approve") ? "approved" : "rejected";

    const confirmationHandler = (postId, confirmStatus) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("status", confirmStatus);
        dispatch(blogPostRequest(postId, formData, onSucces, onError));
    };

    const onSucces = (message) => {
        onHide();
        toastHandler(message, "success");
        setLoading(false);
    };

    const onError = (message) => {
        toastHandler(message, "error");
        setLoading(false);
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="blog-confirmation-modal"
                centered
                className="blog-confirmation-modal"
                size="md"
                backdrop="static"
            >
                <Modal.Header style={{
                    borderBottom: "1px solid gray !important"
                }} bordered>
                    <Modal.Title>
                        Blog Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>{message}</span>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-outline-secondary px-3 border-fifth me-2"
                        onClick={() => {onHide();}}
                        disabled={loading}
                    >
                        CANCEL
                    </button>
                    <> </>
                    <button
                        type="button"
                        className="btn btn-primary text-white"
                        onClick={() => {confirmationHandler(postId, confirmStatus);}}
                        disabled={loading}
                    >
                        {loading ? <Spinner /> : "YES"}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
