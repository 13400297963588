import { createSlice } from "@reduxjs/toolkit";

const initialReviewState = {
  error: null,
  actionsLoading: null,
  listLoading: null,
  reviewsList: [],
  totalReviews: 0,
  averageRating: null,
  reviewsCounter: null,
  hunterQuestion: [],
  landowerQuestion: []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const reviewSlice = createSlice({
  name: "reviews",
  initialState: initialReviewState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    clearEntities: (state, action) => {
      state.reviewsList = [];
      state.totalReviews = 0;
      state.averageRating = null;
      state.reviewsCounter = null;
    },
    // reviews list fetched
    reviewsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.reviewsList = action.payload.entities;
      state.totalReviews = action.payload.totalRecords;
      state.averageRating = action.payload.averageRating;
      state.reviewsCounter = action.payload.reviewsCounter;
    },

    userReviewsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.reviewsList = action.payload.entities;
      state.totalReviews = action.payload.totalRecords;
    },

    ReviewQuestionsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.questionsList = action.payload.entities;
    },

    hunterQuestionsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.hunterQuestion = action.payload.entities;
    },

    landownerQuestionsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.landowerQuestion = action.payload.entities;
    },

    clearReviewQuestions: (state, action) => {
      state.questionsList = [];
      state.listLoading = false;
    },

    updatedReview: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
      state.isUpdated = true;
    },

    clearUpdatedReview: (state, action) => {
      state.actionsLoading = false;
      state.isUpdated = false;
    },
  },
});
