import React, { createContext, useCallback, useContext, useState } from "react";
import { checkQueryParamsEquality } from "utils/helpers";
import {
  initialFilter,
  initialReservationListFilter,
  initialVoucherListFilter,
  initialTrophyListFilter,
  initialReviewListFilter,
  initialTransactionListFilter,
  initialTrophyRoomListFilter,
  initialCustomerPortalTicketsListFilter,
  initialUserReviewFilter,
  initialBookingsFilter
} from "./DashboardUIHelper";
// Creating Context
const DashboardUIContext = createContext();

//export to use this context

export function useDashboardUIContext() {
  return useContext(DashboardUIContext);
}

export function DashboardUIProvider({ dashboardUIEvents, children }) {
  // INITIAL USER CONTEXT
  //query params for stands page api
  const [standQueryParams, setStandQueryParamsBase] = useState(initialFilter);

  const setStandQueryParams = useCallback((nextQueryParams) => {
    setStandQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  const [showCancelReservationModal, setShowCancelReservationModal] =
    useState(false);
  //query params for stands page api
  const [reservationQueryParams, setReservationQueryParamsBase] = useState(
    initialReservationListFilter
  );

  const setReservationQueryParams = useCallback((nextQueryParams) => {
    setReservationQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  //query params for stands page api
  const [voucherQueryParams, setVoucherQueryParamsBase] = useState(
    initialVoucherListFilter
  );

  const setVoucherQueryParams = useCallback((nextQueryParams) => {
    setVoucherQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  //query params for trophy page api
  const [trophyQueryParams, setTrophyQueryParamsBase] = useState(
    initialTrophyListFilter
  );

  const [userReviewQueryParams, setUserReviewQueryParamsBase] = useState(
    initialUserReviewFilter
  );

  const [bookingsQueryParams, setBookingsQueryParamsBase] = useState(
    initialBookingsFilter
  );


  const [reservationId, setReservationId] = useState(0);

  const handleCancelReservationModalClose = () =>
    setShowCancelReservationModal(false);
  const setTrophyQueryParams = useCallback((nextQueryParams) => {
    setTrophyQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  const cancelReservationTogglerfunction = () =>
    setCancelReservationToggler(true);
  //query params for review page api
  const [reviewQueryParams, setReviewQueryParamsBase] = useState(
    initialReviewListFilter
  );

  const handleLogoutConfirmationModalClose = () =>
    setShowLougoutConfirmationModal(false);

  const [showLogoutConfirmationModal, setShowLougoutConfirmationModal] =
    useState(false);

  const [calcelReservationToggler, setCancelReservationToggler] =
    useState(false);
  const setReviewQueryParams = useCallback((nextQueryParams) => {
    setReviewQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  //query params for review page api
  const [transactionsListQueryParams, setTransactionsListQueryParamsBase] =
    useState(initialTransactionListFilter);

  const setTransactionListQueryParams = useCallback((nextQueryParams) => {
    setTransactionsListQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  //  tropy room query params
  const [trophyRoomQueryParams, setTrophyRoomQueryParamsBase] = useState(
    initialTrophyRoomListFilter
  );

  const setTrophyRoomQueryParams = useCallback((nextQueryParams) => {
    setTrophyRoomQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  const setUserReviewQueryParams = useCallback((nextQueryParams) => {
    setUserReviewQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  const setBookingsQueryParams = useCallback((nextQueryParams) => {
    setBookingsQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);


  //query params for stands page api
  const [customerPortalTicketsListQueryParams, setCustomerPortalTicketsListQueryParamsBase] = useState(
    initialCustomerPortalTicketsListFilter
  );

  const setCustomerPortalTicketsListQueryParams = useCallback((nextQueryParams) => {
    setCustomerPortalTicketsListQueryParamsBase((prevQueryParams) => {
      return checkQueryParamsEquality(nextQueryParams, prevQueryParams);
    });
  }, []);

  // COMBVINING ALL THE STATES AND ASSOCIATED FUNCTIONS
  const value = {
    standQueryParams,
    setStandQueryParams,
    setStandQueryParamsBase,
    reservationQueryParams,
    setReservationQueryParams,
    setReservationQueryParamsBase,
    voucherQueryParams,
    setVoucherQueryParams,
    calcelReservationToggler,
    cancelReservationTogglerfunction,
    setVoucherQueryParamsBase,
    trophyQueryParams,
    setTrophyQueryParams,
    reservationId,
    setReservationId,
    setTrophyQueryParamsBase,
    handleCancelReservationModalClose,
    reviewQueryParams,
    setReviewQueryParams,
    setReviewQueryParamsBase,
    transactionsListQueryParams,
    handleLogoutConfirmationModalClose,
    showLogoutConfirmationModal,
    setShowLougoutConfirmationModal,
    setTransactionListQueryParams,
    showCancelReservationModal,
    setShowCancelReservationModal,
    trophyRoomQueryParams,
    setTrophyRoomQueryParams,
    customerPortalTicketsListQueryParams,
    setCustomerPortalTicketsListQueryParams,
    userReviewQueryParams,
    setUserReviewQueryParams,
    setUserReviewQueryParamsBase,
    bookingsQueryParams,
    setBookingsQueryParams,
    setBookingsQueryParamsBase,
    openStandDetailPage: dashboardUIEvents?.openStandDetailPage || null,
    openReservationCancelModal:
      dashboardUIEvents?.openReservationCancelModal || null,
  };

  // ULTIMATE PROIDER FOR CONTEXT
  return (
    <DashboardUIContext.Provider value={value}>
      {children}
    </DashboardUIContext.Provider>
  );
}
