import React from "react";
import EnjoyhuntImg from "./../../../../assets/images/enjoy-hunt.png";
import PlaceHolderImage from "./../../../../assets/images/enjoy-hunt-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function EnjoyHunting() {
    return (
        <section className="account-detail-guide bg-4">
            <div className="inner-container">
                <div className="row justify-content-between">
                    <div className="col-lg-5 col-md-6">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Enjoy Hunting!</h5>
                                <p>
                                Once you have checked in, you can proceed with your hunting activities. You will be able to watch your location live during your hunt, so you can easily verify you are in the correct hunting area. Follow the local hunting regulations, practice safe hunting practices, and respect the property and wildlife.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={EnjoyhuntImg}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={EnjoyhuntImg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
