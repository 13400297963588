import axios from "../../../../services/axiosInstance";

export function getReviews(queryParams, keyName) {
  return axios.get(`all-reviews/${keyName}`, { params: queryParams });
}

export function getUserReviews(id, queryParams) {
  return axios.get(`user-review/${id}`, { params: queryParams });
}

export function getReviewQuestions(type) {
  return axios.get(`review-questions?type=${type}`);
}

export function postHunterReview(data, reviewId) {
  return axios.post(`review/${reviewId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateReview(data, reviewId) {
  return axios.patch(`review/${reviewId}`, data, {
    headers: {
      "Accept": "multipart/form-data",
    },
  });
}