import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ConfirmationModal } from "components/UI/modals/confirmation-modal/confirmationModal";
import Notifications from "modules/landingPage/pages/notifications";
import * as actions from "../../../modules/auth/_redux/authActions";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import profileAvatar from "assets/images/default-avatar.png";
import logo from "assets/icons/logo.svg";
import "./style.scss";
import { handleJuniorHunterModal } from "modules/dashboard/_redux/dashboard/dashboardActions";


export default function Header({
  children,
  sticky = false,
  className = "",
  ...rest
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  let maintenance_alert = false;
  const current_date_time = new Date();
  const maintenance_end_date_time = new Date('12/13/2023 08:00:00 AM');

  if (current_date_time < maintenance_end_date_time) {
    maintenance_alert = true;
  }

  const ref = useRef();
  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        { threshold: [1] }
      );

    observer.observe(cachedRef);

    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  const auth = useSelector((state) => state.auth);
  const { user, actionsLoading } = auth;

  const navigateOnLogout = () => {
    history.push("/");
    setShowConfirmationModal(false);
  };

  const handleConfirmModalSubmit = (confirmed) => {
    if (confirmed) {
      dispatch(actions.logout(navigateOnLogout));
    } else {
      setShowConfirmationModal(false);
    }
  };

  const [fix, setFix] = useState(false);

  function setFixed() {
    if (window.scrollY >= 73) {
      setFix(true)
    } else {
      setFix(false)
    }
  }

  window.addEventListener("scroll", setFixed)
  return (
    <>
      <div className={fix ? 'white-header header-fixed' : 'white-header'}>
        <header
          className={
            className +
            (isSticky
              ? " isSticky"
              : "" || location.pathname !== "/" || user
                ? user?.is_junior ? "junior-hunter-header dark-header" : "dark-header"
                : "")
          }
          ref={ref}
          {...rest}
        >
          {
            user?.is_junior ?
              <div className="junior-hunter-header-inner">
                <span onClick={() => dispatch(handleJuniorHunterModal(true))}></span>
              </div>
              : null}
          <div className="container-fluid d-flex px-0">
            <Navbar
              expand="lg"
              expanded={expanded}
              bg="dark"
              variant="dark"
              className="w-100 px-3"
              collapseOnSelect
            >
              <Link className="header-logo-main" to="/">
                <img src={logo} alt="logo" className="header-logo" />
              </Link>
              <div className="mobile-notification-main">
                <div className="mobile-notification">
                  <Navbar.Toggle
                    aria-controls="navbarScroll"
                    className="border-0 px-1"
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                  />
                </div>
                <div className="d-flex pt-2">
                  {user ? (
                    <div className="header-notification">
                      <Notifications />
                    </div>
                  ) : null}
                  <Nav className="login-btn desktop-login">
                    {!actionsLoading ? (
                      user ? (
                        <div
                          className="  user-detail loged-in cursor-pointer"
                          onClick={() => {
                            history.push("/my-profile/my-stands");
                            setExpanded(false);
                          }}
                        >
                          <div>
                            <img src={user?.image || profileAvatar} alt=""
                              onError={(e) => (e.target.src = profileAvatar)} />
                          </div>
                          <div className="ms-2">
                            <p className="user-name">
                              {user?.email_verify ? user?.first_name : null}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <Link
                          to="/auth/login"
                          className="btn btn-primary text-nowrap text-white"
                          onClick={() => setExpanded(false)}
                        >
                          LOGIN
                        </Link>
                      )
                    ) : null}
                  </Nav>
                </div>
              </div>
              <Navbar.Collapse
                id="navbarScroll"
                className="justify-content-between "
                style={{ zIndex: "3" }}
              >
                <Nav className="mr-auto my-0 my-lg-0 w-100 justify-content-center">
                  <Nav.Link
                    className={location?.pathname === "/" ?
                      "nav-link px-xl-3 px-md-2 px-sm-4 active" :
                      "nav-link px-xl-3 px-md-2 px-sm-4"}
                    to="/"
                    exact
                    onClick={() => setExpanded(false)}
                    as={Link}
                  >
                    {" "}
                    HOME{" "}
                  </Nav.Link>
                  <Nav.Link
                    className={location?.pathname === "/landowner-page" ?
                      "nav-link px-xl-3 px-md-2 px-sm-4 active" :
                      "nav-link px-xl-3 px-md-2 px-sm-4"}
                    to="/landowner-page"
                    onClick={() => setExpanded(false)}
                    as={Link}
                    activeClassName="active--link"
                  >
                    FOR LANDOWNERS{" "}
                  </Nav.Link>
                  <Nav.Link
                    className={location?.pathname === "/hunter-page" ?
                      "nav-link px-xl-3 px-md-2 px-sm-4 active" :
                      "nav-link px-xl-3 px-md-2 px-sm-4"}
                    to="/hunter-page"
                    onClick={() => setExpanded(false)}
                    as={Link}
                    activeClassName="active--link"
                  >
                    FOR HUNTERS{" "}
                  </Nav.Link>
                  <Nav.Link
                    className={location?.pathname === "/about" ?
                      "nav-link px-xl-3 px-md-2 px-sm-4 active" :
                      "nav-link px-xl-3 px-md-2 px-sm-4"}
                    to="/about"
                    onClick={() => setExpanded(false)}
                    as={Link}
                    activeClassName="active--link"
                  >
                    ABOUT{" "}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </header>
        {maintenance_alert ? (
          <div className="header-below-strip">
            <p>iHunt will be offline for scheduled maintenance from December 12, 8 PM to December 13, 8 AM.</p>
          </div>
        ) : null}
        </div>
      {/* show confirmation message before sending api call */}
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleConfirmModalSubmit}
        title={"Logout Confirmation"}
        message={"Are you sure to Logout?"}
        yesString={"YES"}
        noString={"NO"}
        onlyYes={false}
      />
    </>
  );
}
