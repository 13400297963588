import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ConfirmModal from "components/UI/modals/confirmModal";
import { toggleFundrequestStatus, getReservationDetail, clearFundNotifications } from "modules/dashboard/_redux/reservations/reservationActions";
import Spinner from "components/UI/Spinner.js";
import { useParams } from "react-router-dom";
import { setAuthStatus } from "modules/landingPage/_redux/landingPageActions";


export default function PendingStatusModal({
    show,
    setShow,
    setShowPaymentModal,
    paymentSuccess,
    setPaymentSuccess }) {

    const [showDeclinedModal, setShowDeclinedModal] = useState(false);
    const [isPaymentSheetOpen, setIsPaymentSheetOpen] = useState(false);
    const dispatch = useDispatch()
    const param = useParams()

    const {
        actionsLoading,
        fundNotification,
        authStatus
    } = useSelector(
        (state) => ({
            actionsLoading: state.reservation.actionsLoading,
            fundNotification: state.reservation.fundNotification,
            authStatus: state.landingPage.authStatus
        }),
        shallowEqual
    );

    useEffect(() => {
        if (authStatus && paymentSuccess) {
          handlePayment();
        }
    }, [authStatus]);

    const details = {
        title: "",
        amount: "",
        descriptionTitle: "",
        description: "",
    }

    if (fundNotification?.requested_by == "hunter") {
        details.title = `${fundNotification?.reservation?.user?.first_name} ${fundNotification?.reservation?.user?.last_name} Is Requesting`
        details.amount = fundNotification?.requested_amount?.toFixed(1)
        details.descriptionTitle = "Hunter Notes"
        details.description = fundNotification?.description
    } else {
        details.title = `${fundNotification?.reservation?.stand_detail?.title}  Is Requesting`
        details.amount = fundNotification?.requested_amount?.toFixed(1)
        details.descriptionTitle = "Landowner Notes"
        details.description = fundNotification?.description
    }

    const handlePayment = () => {
        setIsPaymentSheetOpen(true);
        localStorage.setItem("amount", fundNotification?.requested_amount)
        const data = new FormData();
        data.append("status", "paid");
        dispatch(toggleFundrequestStatus(fundNotification?.id, data, onSuccess, onError))
    }

    const onSuccess = () => {
        setPaymentSuccess(false)
        setShow(false)
        dispatch(setAuthStatus(false));
        setShowPaymentModal(true);
    }

    const onError = () => {
        setShow(false)
    }

    const handleStatus = () => {
        setShow(false)
        setShowDeclinedModal(true)
    }


    const handleClose = () => {
        setShow(false)
        dispatch(clearFundNotifications())
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="transfer-model "
                centered
            > <Modal.Header closeButton />
                <Modal.Body>
                    <div className="d-flex justify-content-center flex-column text-center">
                        <h4 className="mb-0">{details?.title}</h4>
                        <h2 className="my-3">${details.amount}</h2>
                        <strong>{details?.descriptionTitle}</strong>
                        <p>{details?.description}</p>
                        <div className="d-flex justify-content-between">
                            <button
                                className="btn btn-primary text-white px-3 mr-5 w-auto flex-grow-1 me-3"
                                onClick={handlePayment}
                            >
                                {actionsLoading ? <Spinner /> : "PAY"}
                            </button>
                            <button
                                type="button"
                                className="btn px-3 d-flex w-auto flex-grow-1 me-0 justify-content-center"
                                style={{ border: "1px solid red", color: "red" }}
                                onClick={handleStatus}
                                disabled={isPaymentSheetOpen}
                            >
                                DECLINE
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ConfirmModal
                show={showDeclinedModal}
                setShow={setShowDeclinedModal}
                type={"declined"}
                getRequest={() => getReservationDetail(param?.id)}
                id={fundNotification?.id}
                from="pendingModal"
                openPendingModal={setShow}
                text={fundNotification?.requested_by == "landowner" ?
                    "Notes To Landowner" : "Notes To Hunter"}
            />
        </>
    );
}
