import React from "react";
import Pagination from "components/UI/pagination/Pagination";
import CustomSpinner from "components/layout/Loader/Spinner";
import NoRecordFound from "utils/noRecordFound";
import { useHistory } from "react-router-dom";


export default function TransactionHistoryTable({ transactions, records, isLoading, uiProps, dateRadio, standRadio, dateRange, selectedStandOptions }) {
  const history = useHistory();

  const handleTitleClick = (reservationId) => {
    if ((dateRadio === "selectDates" && dateRange[0] && dateRange[1]) || (standRadio === "selectStands" && selectedStandOptions.length > 0)) {
      localStorage.setItem("paymentFilters", JSON.stringify({
        standRadio,
        dateRadio,
        dateRange,
        selectedStandOptions
      }));
    }
    history.push(`/my-profile/reservation-details/${reservationId}`);
  };

  return (
    <div className="transcation-history mt-4">
      {transactions?.transactions?.length > 0 ? transactions?.transactions?.map((transaction, index) => (
        transaction.type !== "referral_reward" && transaction.type !== "transfer" ? (
          <div className="payment-history-v2" key={index}>
            <div className="payment-card">
              <div className="left">
                <span className="title" style={{ color: transaction?.transaction_color_code }}>{transaction?.title}</span>
                <span className="sub-title" onClick={() => handleTitleClick(transaction?.reservation_id)} style={{ cursor: "pointer" }}>{transaction?.message}</span>
              </div>
              <div className="right">
                  <span className="price d-flex justify-content-end" style={{ color: transaction?.transaction_color_code }}>${transaction?.amount}</span>
                  <span className="date">{transaction?.date}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="payment-history-v2" key={index}>
            <div className="payment-card">
              <div className="left">
                <span className="title" style={{ color: transaction?.transaction_color_code }}>{transaction?.title}</span>
                <span className="sub-title">{transaction?.message}</span>
              </div>
              <div className="right">
                  <span className="price d-flex justify-content-end" style={{ color: transaction?.transaction_color_code }}>${transaction?.amount}</span>
                  <span className="date">{transaction?.date}</span>
              </div>
            </div>
          </div>
        ))
      ) : !isLoading ? (
        <NoRecordFound message="No transaction found" />
      ) : (
        <CustomSpinner />
      )}
      {records > 0 ? (
        <Pagination
          totalRecords={records}
          uiProps={uiProps}
        />
      ) : null}
    </div>
  );
}
