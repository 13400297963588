import React from "react";
import "./style.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ContactOwnerImg from "./../../../../assets/images/contact-owner.png"


export default function ContactSection() {
    return (
        <section className="about-iHunt-section contact-section">
            <div className="inner-container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <h3 className="d-flex justify-content-center text-center pb-2">Contact Nevin, iHunt Account Manager We are here to help!</h3>
                    </div>
                    <div className="col-lg-6">
                        <div className="left">
                            <div className="img">
                                <img src={ContactOwnerImg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-lg-0 mt-4">
                        <div className="right">
                            <div className="left-inner d-flex justify-content-center flex-column align-items-center">
                                <Link className="btn btn-primary text-white text-uppercase" to="/contact">contact us</Link>
                                <ul>
                                    <li>Phone:<span >877-448-6848</span></li>
                                    <li>Email:<span className="cursor-pointer" onClick={() => window.location = `mailto:nevinw@ihunt.com?subject=&body=`}>nevinw@ihunt.com</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
