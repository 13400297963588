import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
    PaymentElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { Button, Row } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import toastHandler from "../toastHandler/toastHandler";


export default function PaymentModal({
    amount,
    showPaymentModal,
    setShowPaymentModal,
    setShowDescriptionModal,
    anyRequest,
    onFailed }) {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [paying, setPaying] = useState(false);

    const dispatch = useDispatch()
    const history = useHistory()

    const showAlert = () => {
        window.alert("Payment in Process. If you want to cancel this reservation. Go to My Reservation Section");
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setIsLoading(true);
        setPaying(true)
        window.addEventListener('popstate', showAlert);
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.origin}${history.location.pathname}`,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (e.g., payment
            // details incomplete)
            dispatch(onFailed())
            setErrorMessage(error.message);
            setIsLoading(false);
            setPaying(false)
            localStorage.getItem("amount")
            window.removeEventListener('popstate', showAlert);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            // show proccessing
            toastHandler("Fund Transfer Request Approved", "success")
            setIsLoading(false);
            setPaying(false)
            setShowDescriptionModal(false)
            setShowPaymentModal(false)
            localStorage.getItem("amount")
            window.removeEventListener('popstate', showAlert);
            dispatch(anyRequest())
        }

    };
    return (
        <Modal
            show={showPaymentModal}
            onHide={() => {
                if (!paying) {
                    setShowPaymentModal(false);
                    dispatch(onFailed())
                }
            }}
        >
            <form id="payment-form" onSubmit={handleSubmit} className="p-5">
                <PaymentElement />
                <Row className="p-2">
                    <Button type="submit" disabled={!stripe} className=" mt-2 text-white">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            `PAY $${amount || localStorage.getItem("amount")}`
                        )}
                    </Button>
                </Row>
                {/* Show error message to your customers */}
                {errorMessage && <div>{errorMessage}</div>}
            </form>
        </Modal>
    )
}