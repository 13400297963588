import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import baseInstance from "services/axiosInstance";
import toastHandler from "components/UI/toastHandler/toastHandler";
import "./style.scss";


export function ReviewBackQuestionsModal({
  show,
  onHide,
  questions,
  currentCommentIndex,
  type,
  updateReview,
  updateReviewID,
  setKey,
}) {
  const handleSubmit = async (values, { setFieldError }) => {
    setIsLoading(true);
    if (values?.comment === "") {
      setFieldError("Comment is a required Field", "comment");
    }
    if (!updateReview) {
      const response = await baseInstance.post(
        `review/${currentCommentIndex}`,
        {
          rating: getRating(),
          review: values?.comment,
          type: type,
        }
      );
      if (response?.status) {
        setKey("LandOwner");
        setKey(type);
        setIsLoading(false);
        toastHandler(response?.message, "success");
        onHide();
      } else {
        toastHandler("Something Went Wrong", "error");
        setIsLoading(false);
        onHide();
      }
    } else {
      const response = await baseInstance.patch(`review/${updateReviewID}`, {
        rating: getRating(),
        review: values?.comment,
        type: type,
      });
      if (response?.status) {
        setKey(type);
        toastHandler(response?.message, "success");
        onHide();
      } else {
        toastHandler("Something Went Wrong", "error");
        onHide();
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const question = {};

  const schemaObject = {}

  questions && questions.length > 0 && questions.map((question, index) => {
    schemaObject[`question${index + 1}`] = Yup.number().min(0).required()
  })

  questions && questions.length > 0 && questions.map((item, index) => {
    question[`question${index + 1}`] = -1
  })

  const validationSchema = Yup.object(schemaObject);

  const formik = useFormik({
    initialValues: {
      ...question,
      comment: ""
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    onHide();
    formik.resetForm();
  }

  const getRating = () => {
    let sum = 0;
    questions && questions?.map((item, index) => {
      sum += formik.values[`question${index + 1}`]
    })
    return sum;
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        className="challenge-popup "
        size="md"
      >
        <Modal.Header closeButton className="header-modal">
          <Modal.Title id="example-modal-sizes-title-lg" style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 20,
            borderBottom: 'none !important'
          }}>
            Review Back
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="conten-modal">
          <Form onSubmit={formik.handleSubmit}>
            {questions && (
              <div className="feedback-section  mt-0">
                <div className="row">
                  <div className="col-12">
                    {
                      questions && questions?.map((item, index) => {
                        return <div className="question">
                          <p>{item.question}</p>
                          <div className="d-flex flex-row align-items-center">
                            <div className="radio-holder d-flex flex-row align-items-center me-4">
                              <input
                                type="radio"
                                id={`Yes${index + 1}`}
                                name={`question${index + 1}`}
                                onChange={() =>
                                  formik.setFieldValue(
                                    `question${index + 1}`,
                                    item?.options[0]?.weightage
                                  )
                                }
                              />
                              <label for={`Yes${index + 1}`} className="fs-14 text-darkblack">
                                {item?.options[0].option}
                              </label>
                            </div>
                            <div className="radio-holder d-flex flex-row align-items-center me-4">
                              <input
                                type="radio"
                                id={`No${index + 1}`}
                                name={`question${index + 1}`}
                                onChange={() =>
                                  formik.setFieldValue(
                                    `question${index + 1}`,
                                    item?.options[1]?.weightage
                                  )
                                }
                              />
                              <label for={`No${index + 1}`} className="fs-14 text-darkblack">
                                {item?.options[1].option}
                              </label>
                            </div>
                          </div>
                        </div>
                      })}
                  </div>
                  <div className="col-12">
                    <p className="mt-4">
                      Write Something about the hunting experience.
                    </p>
                    <div className="type-feedback">
                      <textarea
                        placeholder="Type your comment..."
                        name="comment"
                        onChange={formik?.handleChange}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-end w-100  ">
                      <button className="btn btn-primary    rounded-3 text-white" disabled={!(formik.isValid && formik.dirty)}>
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          "SUBMIT"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className="footer-modal"></Modal.Footer>
      </Modal>
    </div>
  );
}
