import React, { useEffect, useState } from "react";
import "./style.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Timer from "assets/images/comment-timer.svg";
import avatar from "assets/images/avatar.png"
import { Link, useHistory, useParams } from "react-router-dom";
import baseInstance from "services/axiosInstance";
import { shallowEqual, useSelector } from "react-redux";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { ReviewBackQuestionsModal } from "../ReviewBackQuestions/ReviewBackQuestionsModal";
import { ChallengePop } from "../Challenge/challenge";
import Spinner from "components/UI/Spinner.js";
import NoRecordFound from "utils/noRecordFound";

export default function ReviewsReconsider() {
  const { id } = useParams();
  const [ratingQuestions, setRatingQuestions] = useState([]);
  const [viewAs, setViewAs] = useState("");
  const [challengeModal, setChallengeModal] = useState(false);
  const [challangeKey, setChallangeKey] = useState(-1);
  const [reservationDetails, setReservationDetails] = useState([]);
  const [currentCommentIndex, setCurrentCommentIndex] = useState(-1);
  const [updateReview, setUpdateReview] = useState(0);
  const [updateReviewID, setUpdateReviewID] = useState(0);
  const [ReviewBackModal, setReviewBackModal] = useState(false);
  const [isLoading, setIsloading] = useState(false)
  const history = useHistory()

  const { userID } = useSelector(
    (state) => ({
      userID: state.auth.user?.id,
    }),
    shallowEqual
  );

  useEffect(async () => {
    const response = await baseInstance.get(`review-questions?type=${viewAs}`);
    formik.resetForm();
    if (response?.status) setRatingQuestions(response?.data);
  }, [viewAs]);

  useEffect(async () => {
    if (!id) return;
    const response = await baseInstance.get(`reservation-review-detail/${id}`);
    formik.resetForm();
    if (response?.status) {
      setReservationDetails(response?.data);
      if (response?.data?.reservation?.user?.id === userID) {
        setViewAs("hunter");
      } else setViewAs("landowner");
    }
  }, [id, viewAs, setViewAs]);


  useEffect(async () => {
    const id = history?.location?.pathname.split("/")[2];
    if (!id) return;
    try {
      const response = await baseInstance.get(`reservation-review-detail/${id}`);
      formik.resetForm();
      if (response?.status) {
        setReservationDetails(response?.data);
        if (response?.data?.reservation?.user?.id === userID) {
          setViewAs("hunter");
        } else setViewAs("landowner");
      }
    } catch (err) {
      setReservationDetails([])
    }
  }, [history?.location?.pathname]);

  const handleSubmit = async (values, { setFieldError }) => {
    if (values?.comment === "") {
      setFieldError("Comment is a required Field", "comment");
    }
    setIsloading(true)
    const response = await baseInstance.post(`review/${id}`, {
      rating: getRating(),
      review: values?.comment,
      type: viewAs,
    });
    if (response?.status) {
      setViewAs("LandOwner");
      setViewAs(viewAs);
      toastHandler(response?.message, "success");
    } else {
      toastHandler("Something Went Wrong", "error");
    }
    setIsloading(false)
  };

  const question = {};

  const schemaObject = {}

  ratingQuestions && ratingQuestions.length > 0 && ratingQuestions.map((question, index) => {
    schemaObject[`question${index + 1}`] = Yup.number().min(0).required()
  })


  ratingQuestions && ratingQuestions.length > 0 && ratingQuestions.map((item, index) => {
    question[`question${index + 1}`] = -1
  })

  const validationSchema = Yup.object(schemaObject);

  //eslint-disable-next-line
  const formik = useFormik({
    initialValues: {
      ...question,
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const getRating = () => {
    let sum = 0;
    ratingQuestions && ratingQuestions?.map((item, index) => {
      sum += formik.values[`question${index + 1}`]
    })
    return sum;
  }

  //eslint-disable-next-line
  useEffect(() => {document.title = 'Reviews | iHunt'}, []);

  return (
    <>
      <div className="inner-container mt-5 mb-5">
        <div className="reviews-reconsider  row">
          <div className="pb-4">
            <h6 className="d-content-inner-title font-roboto mb-0 review-title">
              Reviews
            </h6>
          </div>
          <div>
            {reservationDetails && reservationDetails?.reviews?.length > 0 ? (
              <>
                <>
                  {reservationDetails?.reviews[0]?.type !== viewAs &&
                    !reservationDetails?.reviews[1] ? (
                    <>
                      <div className="comment-section">
                        <div className={`comment-header`}>
                          <p>
                            {
                              reservationDetails?.reservation?.stand_detail
                                ?.title
                            }
                          </p>
                          <Link
                            to={`/stand/${reservationDetails?.reservation?.stand_detail?.id}`}
                            className="view-details"
                          >
                            View details
                          </Link>
                        </div>

                        <div className="comment-body">
                          <div
                            className={`reviewer-profile ${reservationDetails?.reviews[0]?.challenged == 1 &&
                              reservationDetails?.reviews[1]?.published
                              ? "under-consideration-body"
                              : null
                              }`}
                          >
                            <div
                              className={`user-profile ${reservationDetails?.reviews[0]?.challenged ==
                                1 && reservationDetails?.reviews[1]?.published
                                ? "under-consideration-body"
                                : null
                                }`}
                            >
                              <img
                                src={
                                  reservationDetails?.reviews[0]?.user?.image || avatar
                                }
                                className={`${reservationDetails?.reviews[0]?.challenged ==
                                  1 &&
                                  reservationDetails?.reviews[1]?.published
                                  ? "under-consideration-body"
                                  : null
                                  }`}
                                alt=""
                              ></img>
                              <div
                                className={`name ${reservationDetails?.reviews[0]?.challenged ==
                                  1 &&
                                  reservationDetails?.reviews[1]?.published
                                  ? "under-consideration-body"
                                  : null
                                  }`}
                              >
                                {reservationDetails?.reviews[0]?.user
                                  ?.first_name +
                                  " " +
                                  reservationDetails?.reviews[0]?.user
                                    ?.last_name}
                              </div>
                            </div>
                            <div
                              className={`rating-section ${reservationDetails?.reviews[0]?.challenged == 1
                                ? "under-consideration-body"
                                : null
                                }`}
                            >
                              <i className="bi text-star fs-20 bi-star-fill  "></i>
                              <p className="rate m-0">
                                {reservationDetails?.reviews[0]?.rating}
                              </p>
                            </div>
                          </div>
                          <p className={reservationDetails?.reviews?.length == 1 ? `comment blur` : `coment`}>
                            {" "}
                            {reservationDetails?.reviews[0]?.review}
                          </p>
                          <span className={reservationDetails?.reviews?.length == 1 ? `comment-date blur` : `comment-date`}>
                            <img src={Timer} className="timer-icon" alt=""></img>{" "}
                            {reservationDetails?.reviews[0]?.created_at}
                          </span>
                          {reservationDetails?.reviews[1]?.type === "hunter" ? (
                            <div
                              className={`post-comment-section ${reservationDetails?.reviews[1]?.challenged ==
                                1 &&
                                !reservationDetails?.reviews[1]?.published
                                ? "under-consideration-header"
                                : null
                                }`}
                            >
                              <div className="user-profile">
                                <div className="d-flex flex-row align-items-center">
                                  <img
                                    src={
                                      reservationDetails?.reviews[1]?.user
                                        ?.image || avatar
                                    } alt=""
                                  ></img>
                                  <div className="name">
                                    {reservationDetails?.reviews[1]?.user
                                      ?.first_name +
                                      " " +
                                      reservationDetails?.reviews[1]?.user
                                        ?.last_name}
                                  </div>
                                </div>
                                <div className="rating-section">
                                  <i className="bi text-star fs-20 bi-star-fill  "></i>
                                  <p className="rate m-0">
                                    {reservationDetails?.reviews[1]?.rating}
                                  </p>
                                </div>
                              </div>

                              {reservationDetails?.reviews[1]?.review ? <p className="reply">
                                {reservationDetails?.reviews[1]?.review}
                              </p> : null}
                              <span className="comment-date">
                                <img src={Timer} className="timer-icon" alt=""></img>{" "}
                                {reservationDetails?.reviews[0]?.created_at}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <p className={reservationDetails?.reviews?.length == 1 ? "reveal-comment" : "reveal-comment-none"}>Review back to reveal comment</p>

                        {/* when only review is not published and challange === 2 */}

                        {reservationDetails?.reviews.length == 1 ? (
                          <>
                            <div className="d-flex justify-content-end w-100 pe-4">
                              {" "}
                              <button
                                className="btn btn-outline-primary post-btn rounded-3 text-primary"
                                onClick={() => {
                                  setReviewBackModal(true);
                                  setCurrentCommentIndex(
                                    reservationDetails?.reservation?.id
                                  );
                                  setViewAs(viewAs);
                                }}
                              >{
                                  (reservationDetails?.reviews?.length == 1 && reservationDetails?.reviews[0].type == "hunter") ?
                                    "REVIEW HUNTER" : "REVIEW LISTING"
                                }
                              </button>
                            </div>
                            {
                              !reservationDetails?.reviews[0]?.published ? <div className="expires-in">
                                <i className="bi info-icon bi-info me-2"></i>{" "}
                                <p>
                                  After  {
                                    reservationDetails?.reviews[0]
                                      ?.days_left_to_publish
                                  } days, review will be automatically approved.
                                </p>
                              </div> : null
                            }
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}

                  {reservationDetails?.reviews[0]?.type === viewAs &&
                    !reservationDetails?.reviews[1] ? (
                    <>
                      <div className="comment-section">
                        <div className={`comment-header`}>
                          <p>
                            {
                              reservationDetails?.reservation?.stand_detail
                                ?.title
                            }
                          </p>
                          <Link
                            to={`/stand/${reservationDetails?.reservation?.stand_detail?.id}`}
                            className="view-details"
                          >
                            View details
                          </Link>
                        </div>

                        <div className={`comment-body `}>
                          <div
                            className={`reviewer-profile ${reservationDetails?.reviews[0]?.challenged == 1 &&
                              reservationDetails?.reviews[1]?.published
                              ? "under-consideration-body"
                              : null
                              }`}
                          >
                            <div
                              className={`user-profile ${reservationDetails?.reviews[0]?.challenged ==
                                1 && reservationDetails?.reviews[1]?.published
                                ? "under-consideration-body"
                                : null
                                }`}
                            >
                              <img
                                src={
                                  reservationDetails?.reviews[0]?.user?.image || avatar
                                }
                                className={`${reservationDetails?.reviews[0]?.challenged ==
                                  1 &&
                                  reservationDetails?.reviews[1]?.published
                                  ? "under-consideration-body"
                                  : null
                                  }`}
                                alt=""
                              ></img>
                              <div
                                className={`name ${reservationDetails?.reviews[0]?.challenged ==
                                  1 &&
                                  reservationDetails?.reviews[1]?.published
                                  ? "under-consideration-body"
                                  : null
                                  }`}
                              >
                                {reservationDetails?.reviews[0]?.user
                                  ?.first_name +
                                  " " +
                                  reservationDetails?.reviews[0]?.user
                                    ?.last_name}
                              </div>
                            </div>
                            <div
                              className={`rating-section ${reservationDetails?.reviews[0]?.challenged == 1
                                ? "under-consideration-body"
                                : null
                                }`}
                            >
                              <i className="bi text-star fs-20 bi-star-fill  "></i>
                              <p className="rate m-0">
                                {reservationDetails?.reviews[0]?.rating}
                              </p>
                            </div>
                          </div>
                          <p className="comment">
                            {" "}
                            {reservationDetails?.reviews[0]?.review}
                          </p>
                          <span className="comment-date ">
                            <img src={Timer} className="timer-icon" alt=""></img>{" "}
                            {reservationDetails?.reviews[0]?.created_at}
                          </span>
                          {reservationDetails?.reviews[1]?.type == "hunter" ? (
                            <div
                              className={`post-comment-section ${reservationDetails?.reviews[1]?.challenged ==
                                1 &&
                                !reservationDetails?.reviews[1]?.published
                                ? "under-consideration-header"
                                : null
                                }`}
                            >
                              <div className="user-profile">
                                <div className="d-flex flex-row align-items-center">
                                  <img
                                    src={
                                      reservationDetails?.reviews[1]?.user
                                        ?.image || avatar
                                    } alt=""
                                  ></img>
                                  <div className="name">
                                    {reservationDetails?.reviews[1]?.user
                                      ?.first_name +
                                      " " +
                                      reservationDetails?.reviews[1]?.user
                                        ?.last_name}
                                  </div>
                                </div>
                                <div className="rating-section">
                                  <i className="bi text-star fs-20 bi-star-fill  "></i>
                                  <p className="rate m-0">
                                    {reservationDetails?.reviews[1]?.rating}
                                  </p>
                                </div>
                              </div>

                              {reservationDetails?.reviews[1]?.review ? <p className="reply">
                                {reservationDetails?.reviews[1]?.review}
                              </p> : null}
                              <span className="comment-date">
                                <img src={Timer} className="timer-icon" alt=""></img>{" "}
                                {reservationDetails?.reviews[0]?.created_at}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {reservationDetails?.reviews[0]?.type !== viewAs &&
                    reservationDetails?.reviews[1] ? (
                    <>
                      <div className="comment-section">
                        <div className={`comment-header`}>
                          <p>
                            {
                              reservationDetails?.reservation?.stand_detail
                                ?.title
                            }
                          </p>
                          <Link
                            to={`/stand/${reservationDetails?.reservation?.stand_detail?.id}`}
                            className="view-details"
                          >
                            View details
                          </Link>
                        </div>

                        <div className={`comment-body `}>
                          <div className={`reviewer-profile `}>
                            <div className={`user-profile `}>
                              <img
                                src={
                                  reservationDetails?.reviews[0]?.user?.image || avatar
                                } alt=""
                              ></img>
                              <div className={`name `}>
                                {reservationDetails?.reviews[0]?.user
                                  ?.first_name +
                                  " " +
                                  reservationDetails?.reviews[0]?.user
                                    ?.last_name}
                              </div>
                            </div>
                            <div
                              className={`rating-section ${reservationDetails?.reviews[0]?.challenged == 1
                                ? "under-consideration-body"
                                : null
                                }`}
                            >
                              <i className="bi text-star fs-20 bi-star-fill  "></i>
                              <p className="rate m-0">
                                {reservationDetails?.reviews[0]?.rating}
                              </p>
                            </div>
                          </div>
                          <p className="comment">
                            {" "}
                            {reservationDetails?.reviews[0]?.review}
                          </p>
                          <span className="comment-date ">
                            <img src={Timer} className="timer-icon" alt=""></img>{" "}
                            {reservationDetails?.reviews[0]?.created_at}
                          </span>
                          {reservationDetails?.reviews[1]?.type === viewAs ? (
                            <div className={`post-comment-section`}>
                              <div className="user-profile">
                                <div className="d-flex flex-row align-items-center">
                                  <img
                                    src={
                                      reservationDetails?.reviews[1]?.user
                                        ?.image || avatar
                                    } alt=""
                                  ></img>
                                  <div className="name">
                                    {reservationDetails?.reviews[1]?.user
                                      ?.first_name +
                                      " " +
                                      reservationDetails?.reviews[1]?.user
                                        ?.last_name}
                                  </div>
                                </div>
                                <div className="rating-section">
                                  <i className="bi text-star fs-20 bi-star-fill  "></i>
                                  <p className="rate m-0">
                                    {reservationDetails?.reviews[1]?.rating}
                                  </p>
                                </div>
                              </div>

                              {reservationDetails?.reviews[1]?.review ? <p className="reply">
                                {reservationDetails?.reviews[1]?.review}
                              </p> : null}
                              <span className="comment-date">
                                <img src={Timer} className="timer-icon" alt=""></img>{" "}
                                {reservationDetails?.reviews[0]?.created_at}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {reservationDetails?.reviews[1]?.type !== viewAs &&
                    reservationDetails?.reviews[1] ? (
                    <>
                      <div className="comment-section">
                        <div className={`comment-header`}>
                          <p>
                            {
                              reservationDetails?.reservation?.stand_detail
                                ?.title
                            }
                          </p>
                          <Link
                            to={`/stand/${reservationDetails?.reservation?.stand_detail?.id}`}
                            className="view-details"
                          >
                            View details
                          </Link>
                        </div>

                        <div className={`comment-body `}>
                          <div className={`reviewer-profile `}>
                            <div className={`user-profile `}>
                              <img
                                src={
                                  reservationDetails?.reviews[1]?.user?.image || avatar
                                } alt=""
                              ></img>
                              <div className={`name `}>
                                {reservationDetails?.reviews[1]?.user
                                  ?.first_name +
                                  " " +
                                  reservationDetails?.reviews[1]?.user
                                    ?.last_name}
                              </div>
                            </div>
                            <div className={`rating-section `}>
                              <i className="bi text-star fs-20 bi-star-fill  "></i>
                              <p className="rate m-0">
                                {reservationDetails?.reviews[1]?.rating}
                              </p>
                            </div>
                          </div>
                          <p className="comment">
                            {" "}
                            {reservationDetails?.reviews[1]?.review}
                          </p>
                          <span className="comment-date ">
                            <img src={Timer} className="timer-icon" alt=""></img>{" "}
                            {reservationDetails?.reviews[1]?.created_at}
                          </span>
                          {reservationDetails?.reviews[0]?.type === viewAs ? (
                            <div className={`post-comment-section`}>
                              <div className="user-profile">
                                <div className="d-flex flex-row align-items-center">
                                  <img
                                    src={
                                      reservationDetails?.reviews[0]?.user
                                        ?.image || avatar
                                    } alt=""
                                  ></img>
                                  <div className="name">
                                    {reservationDetails?.reviews[0]?.user
                                      ?.first_name +
                                      " " +
                                      reservationDetails?.reviews[0]?.user
                                        ?.last_name}
                                  </div>
                                </div>
                                <div className="rating-section">
                                  <i className="bi text-star fs-20 bi-star-fill  "></i>
                                  <p className="rate m-0">
                                    {reservationDetails?.reviews[0]?.rating}
                                  </p>
                                </div>
                              </div>

                              {reservationDetails?.reviews[0]?.review ? <p className="reply">
                                {reservationDetails?.reviews[0]?.review}
                              </p> : null}
                              <span className="comment-date">
                                <img src={Timer} className="timer-icon" alt=""></img>{" "}
                                {reservationDetails?.reviews[0]?.created_at}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              </>
            ) : !reservationDetails?.reviews &&
              !reservationDetails?.reservation ?
              <NoRecordFound /> : null}
            {reservationDetails?.reviews?.length === 0 && ratingQuestions ? (
              <div>
                <div className="feedback-comment-section">
                  <div className={`comment-header`}>
                    <p>
                      {" "}
                      {reservationDetails?.reservation?.stand_detail?.title}
                    </p>
                    <Link
                      to={`/stand/${reservationDetails?.reservation?.stand_detail?.id}`}
                      className="view-details"
                    >
                      View details
                    </Link>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="feedback-section review-reconsider-feedback-section ">
                    <div className="row">
                      <div className="col-md-6">
                        {
                          ratingQuestions && ratingQuestions?.map((item, index) => {
                            return (
                              <div className="question">
                                <p>{item.question}</p>
                                <div className="d-flex flex-row align-items-center">
                                  <div className="radio-holder d-flex flex-row align-items-center me-4">
                                    <input
                                      type="radio"
                                      id={`Yes${index + 1}`}
                                      name={`question${index + 1}`}
                                      value={formik.values[`question${index + 1}`]}
                                      onClick={() =>
                                        formik.setFieldValue(
                                          `question${index + 1}`,
                                          item?.options[0]
                                            ?.weightage
                                        )
                                      }
                                    />
                                    <label
                                      for={`Yes${index + 1}`}
                                      className={`fs-14 text-darkblack ${
                                        formik.values[`question${index + 1}`] !== undefined &&
                                        formik.values[`question${index + 1}`] !== null &&
                                        formik.values[`question${index + 1}`] !== "0"
                                          ? ""
                                          : "no-selection"
                                      }`}
                                    >
                                      {item?.options[0].option}
                                    </label>
                                  </div>
                                  <div className="radio-holder d-flex flex-row align-items-center me-4">
                                    <input
                                      type="radio"
                                      id={`No${index + 1}`}
                                      name={`question${index + 1}`}
                                      value={formik.values[`question${index + 1}`]}
                                      onClick={() =>
                                        formik.setFieldValue(
                                          `question${index + 1}`,
                                          item?.options[1]
                                            ?.weightage
                                        )
                                      }
                                    />
                                    <label
                                      for={`No${index + 1}`}
                                      className={`fs-14 text-darkblack ${
                                        formik.values[`question${index + 1}`] !== undefined &&
                                        formik.values[`question${index + 1}`] !== null &&
                                        formik.values[`question${index + 1}`] !== "0"
                                          ? ""
                                          : "no-selection"
                                      }`}
                                    >
                                      {item?.options[1].option}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className="col-md-6">
                        <p className="mt-4">
                          Write Something about the hunting experience.
                        </p>
                        <div className="type-feedback">
                          <textarea
                            placeholder="Type your comment..."
                            name="comment"
                            value={formik.values.comment}
                            onChange={formik?.handleChange}
                          ></textarea>
                        </div>
                        {formik?.errors?.comment &&
                          formik?.touched?.comment && (
                            <Form.Control.Feedback type="d-block invalid">
                              {formik.errors.comment}
                            </Form.Control.Feedback>
                          )}
                        <div className="d-flex justify-content-end w-100  ">
                          <button className="btn btn-primary  rounded-3 text-white" disabled={!(formik.isValid && formik.dirty)} type="submit">
                            {isLoading ? (
                              <Spinner />
                            ) : (
                              "SUBMIT"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <ChallengePop
        show={challengeModal}
        setKey={setViewAs}
        key={viewAs}
        challangeKey={challangeKey}
        onHide={() => {
          setChallengeModal(false);
        }}
      />
      <ReviewBackQuestionsModal
        show={ReviewBackModal}
        updateReview={updateReview}
        updateReviewID={updateReviewID}
        currentCommentIndex={currentCommentIndex}
        type={viewAs}
        setKey={setViewAs}
        questions={ratingQuestions}
        onHide={() => {
          setReviewBackModal(false);
        }}
      />
    </>
  );
}
