import {
  customerServicePortalSlice,
  callTypes,
} from "./customerServicePortalSlice";
import * as requestFromServer from "./customerServicePortalCRUD";

const { actions } = customerServicePortalSlice;

export const createTicket = (data, onSuccess, onError) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTicket(data)
    .then(() => {
      dispatch(actions.ticketCreated());
      onSuccess();
    })
    .catch((error) => {
      error.clientMessage = "Can't create ticket";
      if (error?.response?.data?.message) {
        onError(error?.response?.data?.errors);
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getCustomerServicesList = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCustomerServicesList()
    .then((response) => {
      const data = response.data;
      dispatch(
        actions.customerServicesListFetched({ customerServicesList: data })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't fetched customer services list";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getTickets = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTickets(queryParams)
    .then((response) => {
      const { data, total_records = 0 } = response;
      dispatch(actions.ticketsFetched({ data, total_records }));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetched customer services list";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getTicketDetails = (ticketId) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getTicketDetails(ticketId);
    if (response.status) {
      dispatch(
        actions.ticketDetailFetched({
          ticketDetail: response.data,
        })
      );
    }
  } catch (error) {
    dispatch(actions.catchError({ error }));
  }
};

export const addCommentToTicket =
  (ticketId, data, onSuccess, onError) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .addCommentToTicket(ticketId, data)
      .then((response) => {
        const comment = response.data;
        dispatch(
          actions.commentAddedToTicket({
            comment,
          })
        );
        onSuccess();
      })
      .catch((error) => {
        error.clientMessage = "Can't create ticket";
        if (error?.response?.data?.message) {
          onError(error?.response?.data?.errors);
        }
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

export const clearTicketList = () => (dispatch) => {
  dispatch(actions.clearTicketsList());
};