import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./style.scss"

export const PageNotFound = () => {

    const history = useHistory()

    return (
        <div className="page-not-found-container">
            <h1>Oops!</h1>
            <h3>404 - Page Not Found</h3>
            <p style={{ fontSize: '18px', color: '#777' }}>
                The page you are looking for might have been removed or is temporarily unavailable.
            </p>
            <button className="btn btn-primary text-white d-flex mx-auto .text-capitalize"
                onClick={() => history?.push("/")}
            >
                Go To HomePage
            </button>
        </div>
    );
};

export default PageNotFound;
