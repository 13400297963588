import React from "react";
import ChoiceHuntingSiteImg from "./../../../../assets/images/choice-hunting-site.png";
import PlaceHolderImage from "./../../../../assets/images/choice-hunting-site-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function chooseHuntingSite() {
    return (
        <section className="account-detail-guide bg-2">
            <div className="inner-container">
                <div className="row justify-content-between">
                    <div className="col-lg-5 col-md-6">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Choose Your Hunting Site</h5>
                                <p>
                                Once you have selected a hunting site, review the available dates and choose the days you want to go hunting. Some sites may have limited availability, so ensure the dates align with your plans.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={ChoiceHuntingSiteImg}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={ChoiceHuntingSiteImg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
