import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getWavierQuestions } from "modules/dashboard/_redux/wavier/wavierActions";
import { wavierSlice } from "modules/dashboard/_redux/wavier/wavierSlice";
import Wavier from "../../../../assets/images/wavier.jpg";
import { FloatingLabel, Form } from "react-bootstrap";
import "./style.scss";


const { actions } = wavierSlice;

export default function WavierForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { wavierQuestions, wavierFormData, user } = useSelector(
    (state) => ({
      wavierQuestions: state.wavier.wavierQuestions,
      wavierFormData: state.wavier.wavierFormData,
      user: state.auth.user
    }),
    shallowEqual
  );

  const token = localStorage.getItem("token")
  const formikInitialValues = {};
  const validation = {};

  if (wavierQuestions) {
    wavierQuestions.forEach((element, index) => {
      validation[`question${index + 1}`] = Yup.string().required();
      validation[`comment${index + 1}`] = Yup.string().when(`textbox${index + 1}`, {
        is: true,
        then: Yup
          .string()
          .required("Reason is required")
      });
      [1, 2, 3, 4].map((item, i) => {
        if (i == 0) {
          return formikInitialValues[`question${index + 1}`] = "";
        } if (i == 1) {
          return formikInitialValues[`textbox${index + 1}`] = false;
        } if (i == 2) {
          return formikInitialValues[`comment${index + 1}`] = "";
        } if (i == 3) {
          return formikInitialValues[`isApproved${index + 1}`] = 0;
        }
      });
    });
  }
  useEffect(() => {
    if (!token) {
      history.push("/auth/login")
    }
    dispatch(getWavierQuestions())
  }, [])

  if (user?.is_junior) {
    formikInitialValues["guardianName"] = ""
    validation["guardianName"] = Yup.string().required("Guardian Name")
  }

  const validationSchema = Yup.object(validation);
  const formik = useFormik({
    initialValues: wavierFormData || formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
    },
  });

  const handleSubmitButtonClick = () => {
    dispatch(actions.setWavierFormData(formik.values))
    history.push(`/wavier-form-agreement`);
  }
  const handleCancel = () => {
    history.goBack();
  }

  useEffect(() => {document.title = 'Wavier Form | iHunt'}, []);

  return (
    <div className="wavier-form">
      <div className="container-fluid wavier-form-container">
        <div className="side-img">
          <img src={Wavier} className="img-fluid" alt="" />
        </div>
        <div className="wavier-form-body">
          <div className="header">
            <p>Hunter Wavier Form</p>
          </div>
          <p className="subtitle">
            In order to continue your reservation process, you have to fill
            hunter wavier form.
          </p>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <div className="question-section">
              {user?.is_junior ? <FloatingLabel controlId="floatingInputGrid" label="Guardian Name">
                <Form.Control
                  type="text"
                  name="guardianName"
                  id="guardianName"
                  placeholder="Guardian Name"
                  value={formik?.values?.guardianName}
                  onChange={formik.handleChange}
                />
              </FloatingLabel> : null}
              {
                wavierQuestions && wavierQuestions.map((question, index) => {
                  return <>
                    <div className="question">
                      <label for="inputState " className="statement">
                        <span style={{ whiteSpace: "pre-line" }}> {question.question} </span>
                      </label>

                      <div className="d-flex flex-row align-items-center">
                        <button className={formik.values[`question${index + 1}`] == question.options[0].lable
                          ? "btn btn-outline-primary me-4" : "btn btn-outline-secondary me-4"}
                          onClick={() => {
                            formik.setFieldValue(
                              `question${index + 1}`,
                              question.options[0].lable
                            )
                            formik.setFieldValue(
                              `textbox${index + 1}`,
                              question.options[0].show_comment_box
                            )
                            formik.setFieldValue(
                              `isApproved${index + 1}`,
                              question.options[0].is_approved
                            )
                            formik.setFieldValue(
                              `comment${index + 1}`, ""
                            )
                          }
                          }
                        >{question?.options[0]?.lable}
                        </button>
                        <button className={formik.values[`question${index + 1}`] == question.options[1].lable ? "btn btn-outline-primary me-4" : "btn btn-outline-secondary me-4"}
                          onClick={() => {
                            formik.setFieldValue(
                              `question${index + 1}`,
                              question.options[1].lable
                            )
                            formik.setFieldValue(
                              `textbox${index + 1}`,
                              question.options[1].show_comment_box
                            )
                            formik.setFieldValue(
                              `isApproved${index + 1}`,
                              question.options[1].is_approved
                            )
                          }}
                        >{question.options[1].lable}
                        </button>
                      </div>
                      {
                        formik.values[`textbox${index + 1}`] && (
                          <div className="no-section">
                            <span>
                              {`If your answer is ${formik.values[`question${index + 1}`]} then please write down the reason`}
                            </span>
                            <div className="reason-statement">
                              <textarea placeholder="Write Down From here"
                                value={formik.values[`comment${index + 1}`]}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    `comment${index + 1}`,
                                    e.target.value
                                  )}
                              ></textarea>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </>
                })
              }
            </div>
            <div className="d-flex flex-row align-items-center mt-4 mb-4 ">
              <button className="btn btn-outline-secondary me-4 cancel" onClick={handleCancel} type="button">
                Cancel
              </button>
              <button
                className={!(formik.isValid && formik.dirty) && !wavierFormData || !formik.isValid ? "btn btn-primary text-white next disabled" : "btn btn-primary text-white next"} 
                type="button"
                onClick={handleSubmitButtonClick}
                disabled={!(formik.isValid && formik.dirty) && !wavierFormData || !formik.isValid}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
