import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import FilterIcon from "assets/images/equalizerLine.svg";
import { useDashboardUIContext } from "modules/dashboard/pages/DashboardUIContext";
import { getCustomerServicesList } from "modules/dashboard/_redux/customer-service-portal/customerServicePortalActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ticketStatuses } from "modules/dashboard/pages/DashboardUIHelper";
import SlickSlider from "react-slick";
import { FaPlus } from "react-icons/fa";


export const Filter = ({ onCreateNewTicket }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.customerPortalTicketsListQueryParams,
      setQueryParams:
        dashboardPageUIContext.setCustomerPortalTicketsListQueryParams,
    };
  }, [dashboardPageUIContext]);

  const [status, setStatus] = useState(dashboardPageUIProps.queryParams.status || "");

  const onChangeStatus = (status) => {
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      status,
    });
  };

  const onChangeCategory = (category) => {
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      category,
    });
  };

  const { customerServicesList } = useSelector(
    (state) => ({
      customerServicesList: state.customerServicePortal.customerServicesList,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCustomerServicesList());
  }, []);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <span
        className="bi bi-chevron-right slick-arrow arrow-right"
        onClick={onClick}
      ></span>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <span
        className="bi bi-chevron-left slick-arrow arrow-left"
        onClick={onClick}
      ></span>
    );
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    infinite: false,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="pb-3 ticket-section-header">
        <div>
          <h6 className="d-content-inner-title font-roboto mb-0">Get Help</h6>
          <p>We are here to help please get in touch with us</p>
        </div>
        <button
          type="button"
          onClick={onCreateNewTicket}
          className="btn btn-primary rounded-3 text-white new-ticket-btn "
        >
          <FaPlus className="me-2" size={15} />
          CREATE NEW TICKET
        </button>
      </div>

      <div className="ticket-section1">
        <ul className="ticket-section-list">
          <SlickSlider {...settings}>
            <li>
              <button
                onClick={() => {
                  onChangeCategory("");
                }}
                className={`btn rounded-3 text-darkblack new-ticket-btn  slider-items fade-in item ${dashboardPageUIProps.queryParams.category === ""
                  ? "active-btn"
                  : ""
                  }`}
              >
                All
              </button>
            </li>

            {customerServicesList.length
              ? customerServicesList.map((service, index) => (
                <li key={index}>
                  <button
                    data-toggle="tooltip"
                    data-placement="top"
                    title={service.title}
                    onClick={() => {
                      onChangeCategory(service.id);
                    }}
                    className={`btn rounded-3 text-darkblack new-ticket-btn  slider-items fade-in item ${dashboardPageUIProps.queryParams.category === service.id
                      ? "active-btn"
                      : ""
                      }`}
                  >
                    {service.title}
                  </button>
                </li>
              ))
              : null}
          </SlickSlider>
        </ul>

        <div className="ticket-section-filter">
          <Dropdown show={showDropdown} autoClose={true}>
            <Dropdown.Toggle
              className="btn btn-default dropdown-toggle"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <img src={FilterIcon} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {ticketStatuses.map((ticketStatus, index) => (
                <div className="form-check form-check-inline" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="category"
                    value={ticketStatus.value}
                    id={`radio_${index}`}
                    checked={status === ticketStatus.value}
                    onChange={(e) => {
                      const statusValue = e.target.value;
                      setStatus(statusValue);
                      onChangeStatus(statusValue);
                      setShowDropdown(false);
                    }}
                  />
                  <label className="form-check-label" for={`radio_${index}`}>
                    {ticketStatus.title}
                  </label>
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
